"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { Fragment, useEffect, useState } from "react";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { FileListState } from "@/types/thread";
import Image from "next/image";
import { MemberImage, MemberName } from "@/components/member";
import { Text } from "@/components/ui/text";
import moment from "moment";
import { UserImage, UserName } from "@/components/user";
import { SliderPagination } from "@/components/ui/slider-pagination";
import { useHotkeys } from "@mantine/hooks";

export const ImageModal = () => {
  const { isOpen, type, data, onClose } = useModal();
  const { images, message, msgProfile: profile } = data;

  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  const isModalOpen = isOpen && type === "imgPreview";

  useEffect(() => {
    if (!api) return;

    if (profile?.index) api.scrollTo(profile.index, true);

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [profile, api]);

  useHotkeys([
    ["ArrowLeft", () => api?.scrollPrev()],
    ["ArrowRight", () => api?.scrollNext()]
  ]);

  const handleClose = () => onClose();

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="p-4 overflow-visible" style={{ width: "calc(100% - 80px)" }}>
        <DialogHeader>
          <DialogTitle>
            {profile ? (
              <Fragment>
                {profile.type === "channel" && profile.memberId ? (
                  <div className="flex items-start gap-2">
                    <div className="my-auto">
                      <MemberImage memberId={profile.memberId} size={32} />
                    </div>
                    <div className="flex flex-col">
                      <Text variant="body_two" as="div">
                        <MemberName memberId={profile.memberId} />
                      </Text>
                      <Text variant="caption_one" as="div">
                        {moment(message?.createdAt).format("DD MMM yyyy, hh:mm A")}
                      </Text>
                    </div>
                  </div>
                ) : null}

                {profile.type === "conversation" && profile.userId ? (
                  <div className="flex items-start gap-2">
                    <div className="my-auto">
                      <UserImage userId={profile.userId} size={32} />
                    </div>
                    <div className="flex flex-col">
                      <Text variant="body_two" as="div">
                        <UserName userId={profile.userId} />
                      </Text>
                      <Text variant="caption_one" as="div">
                        {moment(message?.createdAt).format("DD MMM yyyy, hh:mm A")}
                      </Text>
                    </div>
                  </div>
                ) : null}
              </Fragment>
            ) : null}
          </DialogTitle>
        </DialogHeader>

        <Carousel className="w-full" setApi={setApi}>
          <CarouselContent>
            {(images || []).map((img: FileListState, index) => (
              <CarouselItem key={index}>
                <div
                  className="relative flex items-center justify-center p-3"
                  style={{
                    height: "calc(100dvh - 200px)"
                  }}
                >
                  <Image
                    fill
                    src={img.url}
                    alt={img.fileName || ""}
                    className="object-contain !relative !max-w-full-50px !max-h-full !h-auto !w-auto z-50"
                  />

                  <Image
                    fill
                    src={img.url}
                    alt={img.fileName || ""}
                    className="object-cover absolute top-0 right-0 left-0 bottom-0 blur-2xl opacity-90"
                  />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>

        <SliderPagination
          current={current}
          total={count}
          onNextClick={() => api?.scrollNext()}
          onPreviousClick={() => api?.scrollPrev()}
        />
      </DialogContent>
    </Dialog>
  );
};
