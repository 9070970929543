"use client";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { env } from "@/env";

export default function DataDog() {
  useEffect(() => {
    if (env.NEXT_PUBLIC_DATADOG_ENV !== "development") {
      datadogRum.init({
        applicationId: `${env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}`,
        clientToken: `${env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}`,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: `${env.NEXT_PUBLIC_DATADOG_DD_SITE}`,
        service: "community-application",
        env: `${env.NEXT_PUBLIC_DATADOG_ENV}`,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
      });
    }
  }, []);

  return <></>;
}
