import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { Text } from "@/components/ui/text";
import { useModal } from "@/stores/use-modal-store";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import {
  CANCEL_REGISTRATION_EMAIL_SUBJECT,
  CANCEL_REGISTRATION_EMAILS,
  DEFAULT_CANCELLATION_REASONS
} from "@key.ai/constants";
import Quill from "quill";
import dynamic from "next/dynamic";
import { Checkbox } from "@/components/ui/checkbox";
import eventClientApi from "@/api-client/event-client";
import Divider from "@/components/ui/divider";
import { toast } from "sonner";
import { useEventStore } from "@/stores/use-event-store";

const QlBaseEditor = dynamic(() => import("@/components/ql-editor"), {
  ssr: false
});

const cancelReasonMapper: Record<string, string> = {
  generalDecline: "General Decline",
  criteriaNotMet: "Criteria Not Met",
  capacityLimitation: "Capacity Limitation",
  insufficientInfo: "Insufficient Information",
  custom: "Custom"
};

const CancelGuestRegistration = () => {
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [customReason, setCustomReason] = useState<string>("");
  const [view, setView] = useState<string>("reason");
  const [quill, setQuill] = useState<Quill | null>(null);
  const [preview, setPreview] = useState<any>(null);
  const [subject, setSubject] = useState<string>("");
  const [bodyHtml, setBodyHtml] = useState<any>("");
  const [includeEmail, setIncludeEmail] = useState<boolean>(true);
  const [eventCreatorEmail, setEventCreatorEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { isOpen, type, data, onClose } = useModal();

  const isModalOpen = isOpen && type === "cancelRegistration";

  const { eventData } = useEventStore();

  const currentUser = data.user;

  const fetchEventCreatorEmail = async () => {
    try {
      const response = await eventClientApi
        .get(`api/v2/events/${eventData.id}/creator`)
        .json<any>();
      const creatorEmail = response?.data?.creator?.User?.email;
      if (creatorEmail) return creatorEmail;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCreatorEmail = async () => {
    const creatorEmail = await fetchEventCreatorEmail();
    if (!creatorEmail) {
      console.error("Failed to fetch creator email.");
      return;
    }
    setEventCreatorEmail(creatorEmail);
  };

  useEffect(() => {
    if (eventData?.id) fetchCreatorEmail();
  }, [eventData?.id]);

  useEffect(() => {
    if (quill && bodyHtml && view === "template") {
      quill.clipboard.dangerouslyPasteHTML(bodyHtml);
      quill.enable();
    }
    if (quill && preview && view === "preview") {
      quill.setContents(preview);
      quill.disable();
    }
  }, [bodyHtml, quill, view, preview]);

  const handleCancelRegistration = async () => {
    try {
      setLoading(true);
      await eventClientApi
        .patch(`api/v2/events/${eventData.id}/event-participations/${data?.participationId}`, {
          json: {
            userId: data?.participationId,
            status: "not_going",
            type: "cancel_registration",
            reason: selectedReason,
            cancel_body_html: btoa(encodeURIComponent(bodyHtml || "") || ""),
            cancel_body: bodyHtml,
            cancel_subject: subject
          }
        })
        .json<any>();
      toast.success("User declined successfully");

      data?.callback?.();

      setView("reason");
      onClose();
    } catch (error) {
      console.log(error);
      toast.success(error?.message || "Something went wrong while declining user");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (view === "reason") {
      const htmlData: any = {
        eventName: eventData.name,
        customReason: customReason
      };
      const body = CANCEL_REGISTRATION_EMAILS[selectedReason]?.replace(
        /{{\s*([^{}\s]+)\s*}}/g,
        (match: any, key: any) => {
          return key in htmlData ? htmlData[key] : match;
        }
      );

      const emailSubject = CANCEL_REGISTRATION_EMAIL_SUBJECT?.replace(
        /{{\s*([^{}\s]+)\s*}}/g,
        (match: any, key: any) => {
          return key in htmlData ? htmlData[key] : match;
        }
      );
      const additionalLine = `<div>If you have any inquiries, please feel free to contact me at ${eventCreatorEmail}.</div>`;
      const data = body + additionalLine;
      setSubject(emailSubject?.toString());
      setBodyHtml(data);
      return setView("template");
    }
    if (view === "template") {
      if (quill) setPreview(quill.getContents());
      return setView("preview");
    }
    if (view === "preview") {
      handleCancelRegistration();
    }
  };

  const handleEmailCheckbox = async (checked: boolean) => {
    setIncludeEmail(checked);
    localStorage.setItem("includeEmail", checked.toString());
    const additionalLine = `<div>If you have any inquiries, please feel free to contact me at ${eventCreatorEmail}.</div>`;
    if (checked) {
      setBodyHtml(bodyHtml + additionalLine);
    } else {
      const divRegex = /<div>[^<]*<\/div>/;
      const pRegex = /(<p>.*?<\/p>).*?(<p>.*?<\/p>)/;

      let updatedBodyHtml = bodyHtml.replace(divRegex, "");
      updatedBodyHtml = updatedBodyHtml.replace(pRegex, "$1");
      setBodyHtml(updatedBodyHtml);
    }
  };

  const reasonView = () => {
    return (
      <>
        <Text variant={"body_two"}>Select Reason:</Text>
        <div className="w-full">
          <RadioGroup onValueChange={(e: any) => setSelectedReason(e)}>
            {DEFAULT_CANCELLATION_REASONS?.map((_reason, index) => {
              return (
                <div className="flex items-start gap-m my-m" key={index}>
                  <RadioGroupItem
                    value={_reason?.id}
                    checked={_reason?.id === selectedReason}
                    className="mt-xs"
                    size="medium"
                  />
                  <div>
                    <Text>{_reason.label}</Text>
                    <Text variant={"caption_one"}>{_reason.description}</Text>
                  </div>
                </div>
              );
            })}

            <div className="flex items-center gap-m mt-m w-full">
              <RadioGroupItem value="custom" checked={selectedReason === "custom"} size="medium" />
              <Input
                onChange={(e) => setCustomReason(e.target.value)}
                value={customReason}
                className="w-full"
                disabled={selectedReason !== "custom"}
                placeholder="Specify other reason"
                onFocus={(e) => setSelectedReason("custom")}
              />
            </div>
          </RadioGroup>
          <div className="flex items-center"></div>
        </div>
      </>
    );
  };

  const templateView = () => {
    return (
      <div>
        <div className="text-zinc-600 text-lg mb-4 font-medium">
          {cancelReasonMapper[selectedReason]}
        </div>
        <div>
          <Input
            onChange={(e) => setSubject(e?.target?.value)}
            variant="default"
            placeholder="Subject"
            value={subject}
            maxLength={90}
          />
        </div>
        <div className=" border-1 border-zinc-300 rounded-small mt-m p-m">
          <QlBaseEditor
            placeholder="Start typing..."
            showFormating={true}
            setQuill={setQuill}
            containerClass="h-[150px]"
          />
        </div>
        <div className="flex gap-s items-center">
          <Checkbox
            className="my-2"
            defaultChecked={false}
            checked={includeEmail}
            onCheckedChange={(checked: boolean) => handleEmailCheckbox(checked)}
          />
          <Text>Share your email for follow-up inquiries</Text>
        </div>
        <Divider />
        <Text variant={"caption_one"}>Sending to {currentUser?.email}</Text>
      </div>
    );
  };

  const previewView = () => {
    return (
      <>
        <div>
          <Input value={subject} disabled={true} placeholder="Subject" />
        </div>
        <div className="max-h-[380px] mt-4 rounded-lg overflow-auto">
          <QlBaseEditor setQuill={setQuill} showFormating={false} containerClass="h-[150px]" />
        </div>
      </>
    );
  };

  const cancelRegistrationView = (
    view: string
  ): ReturnType<typeof reasonView> | ReturnType<typeof templateView> => {
    const views: Record<string, ReturnType<typeof reasonView> | ReturnType<typeof templateView>> = {
      reason: reasonView(),
      template: templateView(),
      preview: previewView()
    };

    return views[view];
  };

  const handleBack = () => {
    if (view === "reason") {
      onClose();
      setSelectedReason("");
      setBodyHtml("");
      return setView("reason");
    }
    if (view === "template") {
      return setView("reason");
    }
    if (view === "preview") {
      return setView("template");
    }
  };

  const cancelButtonText = (type: string): string | undefined => {
    const obj: Record<string, string> = {
      reason: "Close",
      template: "Back",
      preview: "Edit"
    };

    return obj[type];
  };

  const submitButtonText = (type: string) => {
    const obj: Record<string, string> = {
      reason: "Proceed",
      template: "Done",
      preview: "Confirm Send"
    };
    if (obj[type]) {
      return obj[type];
    }
  };

  const isDisabled = () => {
    if (view === "reason") {
      return !selectedReason || (selectedReason === "custom" && customReason === "");
    }
    if (view === "template") {
      const isFirstPTagEmpty = /^<p\s*[^>]*>\s*<\/p>/.test(bodyHtml);
      const isSubjectEmpty = !subject?.trim();
      return isFirstPTagEmpty || isSubjectEmpty;
    }
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogContent className="w-[650px] " isShowCloseBtn={false}>
        <DialogDescription>
          <Text variant={"subtitle_one"}>Cancel Registration</Text>
        </DialogDescription>
        {cancelRegistrationView(view)}
        <div className="flex justify-center gap-m mt-m items-center">
          <Button onClick={() => handleBack()} variant={"secondary"}>
            {cancelButtonText(view)}
          </Button>
          <Button onClick={() => handleSubmit()} loading={loading} disabled={isDisabled()}>
            {submitButtonText(view)}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CancelGuestRegistration;
