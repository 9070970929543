import { useQuery } from "@tanstack/react-query";
import { useParams } from "next/navigation";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { Conversation } from "@/types/entities";
import { getQueryKey } from "@key.ai/constants";
import { IMember } from "@/types/cusom-types";
import { useSession } from "next-auth/react";
import { ignoreDomains } from "@/lib/url";

interface MemberQueryProps {
  memberId: string;
}

export const fetchMember = async (serverId: string, memberId: string) => {
  const { data: member } = await clientApi
    .get(`api/v1/${serverId}/members/${memberId}`)
    .json<ApiResponse<{ member: IMember; conversation: Conversation }>>();

  return { ...member.member, conversation: member.conversation };
};

export const useMemberQuery = ({ memberId }: MemberQueryProps) => {
  const { domain } = useParams<{ domain: string }>();
  const { status } = useSession();
  const queryKey = getQueryKey("MEMBER_INFO", { memberId, serverId: domain });

  return useQuery<IMember & { conversation: Conversation | null }>({
    queryKey: [queryKey],
    queryFn: () => fetchMember(domain, memberId),
    refetchInterval: false,
    staleTime: Infinity,
    enabled: !!domain && status === "authenticated" && !ignoreDomains.includes(domain),
    retry: false
  });
};
