"use client";

import { useParams } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import clientApi from "@/api-client/client";
import { useFormik } from "formik";
import MemberDropdown from "@/components/member-dropdown";
import { Fragment } from "react";
import * as yup from "yup";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/text";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import Box from "@/components/ui/box";
import { cleanObject } from "@/lib/helper";
import { useDevice } from "@/hooks/use-device";

const Schema = yup.object({
  members: yup.array().min(1, "Please select members.").required("This field is required.")
});

export const ConversationAddMemberModal = () => {
  const { isOpen, type, data, onClose } = useModal();
  const { channel, conversation } = data;

  const isModalOpen = (isOpen &&
    type &&
    ["channelAddMember", "conversationAddMember"].includes(type)) as boolean;

  const { isMobile, isTablet } = useDevice();

  const params = useParams<{ domain: string }>();

  const formik = useFormik({
    initialValues: {
      members: []
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await clientApi.post(`api/v1/conversations/add-members`, {
          json: {
            conversationId: conversation?.id,
            users: values.members
          },
          searchParams: cleanObject({ serverId: params.domain })
        });
        onClose();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const onChange = (values: string[]) => {
    formik.setFieldValue("members", values);
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4 overflow-visible">
        <DialogHeader>
          <DialogTitle>
            Add member to {channel?.name ? `#${channel?.name}` : "this conversation"}
          </DialogTitle>
        </DialogHeader>

        {conversation ? (
          <Fragment>
            <div className="relative z-[999]">
              <Box className="px-0 py-0 rounded-xs">
                <MemberDropdown
                  conversation={conversation}
                  className="field-border"
                  onChange={onChange}
                  dropDownType={channel ? "member" : "user"}
                  maxMenuHeight={isMobile || isTablet ? 150 : undefined}
                />
              </Box>

              {formik.errors.members && formik.touched.members ? (
                <Text as="span" className="text-red-500 text-xs mt-1 mx-l">
                  {formik.errors.members}
                </Text>
              ) : null}
            </div>

            <div className="flex items-center">
              <Button
                disabled={formik.isSubmitting}
                loading={formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
                size="sm"
                loaderProps={{ size: "xs" }}
                type="button"
              >
                Add Member
              </Button>
            </div>
          </Fragment>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
