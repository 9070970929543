import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  queryDeleteMessage,
  queryUpdateMessage,
  queryUpdatePinned,
  queryUpsertMessage
} from "@/lib/conversation";
import { getQueryKey } from "@key.ai/constants";
import { PublishActionType } from "@key.ai/enum";
import { collection, onSnapshot, query, where, limitToLast, orderBy } from "firebase/firestore";
import { collections } from "@key.ai/firestore";
import { db } from "@/firebase/db";
import moment from "moment";

type ChatSocketProps = {
  conversationId: string;
};

export const useChatSocket = ({ conversationId }: ChatSocketProps) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const q = query(
      collection(db, collections.messages),
      where("conversationId", "==", conversationId),
      orderBy("createdAt", "asc"),
      limitToLast(1)
    );

    // LISTEN FOR REAL-TIME UPDATES
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      for (const change of snapshot.docChanges()) {
        if (change.type === "added") {
          const msg = change.doc.data();
          const queryKey = getQueryKey("CHAT_MESSAGES", { conversationId });

          const message = {
            ...msg,
            id: msg.messageId,
            createdAt: moment(msg.createdAt).toDate(),
            updatedAt: moment(msg.updatedAt).toDate()
          };

          console.log("MESSAGE", message);

          switch (msg.actionType) {
            case PublishActionType.CREATE:
              queryUpsertMessage(queryClient, queryKey, message, true);
              break;

            case PublishActionType.UPDATE:
              queryUpdateMessage(queryClient, queryKey, message as any);
              break;

            case PublishActionType.CHAT_DELETE_MESSAGE:
              queryDeleteMessage(queryClient, queryKey, message as any);
              break;

            default:
              break;
          }
        }
      }
    });

    // CLEANUP SUBSCRIPTION ON UNMOUNT
    return () => unsubscribe();
  }, [queryClient, conversationId]);
};

export const usePinnedSocket = ({ conversationId }: { conversationId: string }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const q = query(
      collection(db, collections.messages),
      where("conversationId", "==", conversationId),
      where("actionType", "==", PublishActionType.UPDATE_PINNED),
      orderBy("docId"),
      limitToLast(1)
    );

    // LISTEN FOR REAL-TIME UPDATES
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      for (const change of snapshot.docChanges()) {
        if (change.type === "added") {
          const doc = change.doc.data();
          switch (doc.actionType) {
            case PublishActionType.UPDATE_PINNED:
              queryUpdatePinned(queryClient, doc as any);
              break;
          }
        }
      }
    });

    // CLEANUP SUBSCRIPTION ON UNMOUNT
    return () => unsubscribe();
  }, [queryClient, conversationId]);
};
