"use client";

import clientApi from "@/api-client/client";
import * as yup from "yup";
import { useParams } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@key.ai/constants";
import { toast } from "sonner";

const formSchema = yup.object({
  name: yup
    .string()
    .required("Category name is required.")
    .max(50, "Category name cannot exceed 50 characters.")
    .test(
      "is-not-only-spaces",
      "Category name cannot be only spaces.",
      (value) => value.trim().length > 0
    )
});

export const EditChannelCategoryModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const params = useParams<{ domain: string }>();
  const queryClient = useQueryClient();
  const { channelCategory } = data;

  const isModalOpen = isOpen && type === "editChannelCategory";

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await clientApi.patch(`api/v1/${params.domain}/categories/${channelCategory?.id}`, {
          json: values
        });
        const queryKey = getQueryKey("CATEGORIES", { serverId: params.domain });
        await queryClient.refetchQueries({ queryKey: [queryKey] });
        resetForm();
        onClose();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (channelCategory && isOpen) {
      formik.setFieldValue("name", channelCategory.name);
    }
  }, [channelCategory, isOpen]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4">
        <DialogHeader>
          <DialogTitle>Edit Category</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <Input
            placeholder="Category name"
            error={(formik.touched.name && formik.errors.name) as boolean}
            errorMessage={formik.errors.name}
            errorOutline={(formik.touched.name && formik.errors.name) as boolean}
            label="Name"
            variant="medium"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
          />

          <Button
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            type="submit"
            loaderProps={{ size: "xs" }}
          >
            Update
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
