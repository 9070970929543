"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";

export const VideoModal = () => {
  const { isOpen, type, onClose } = useModal();

  const isModalOpen = isOpen && type === "videoPreview";

  const handleClose = () => onClose();

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[650px] p-4 max-h-[calc(100dvh - 90px)]">
        <DialogHeader>
          <DialogTitle>Getting started with Key.ai</DialogTitle>
        </DialogHeader>

        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/0KjY8jxyjKc?si=cxQbRtgCxuSDuYZN"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </DialogContent>
    </Dialog>
  );
};
