import { initializeApp } from "firebase/app";
import { env } from "@/env";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: env.NEXT_PUBLIC_FIREBASE_APIKEY,

  // ADDED FOR CUSTOM DOMAIN ON FIREBASE AUTH
  // authDomain: env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  authDomain: env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
