import { InfiniteData, QueryClient } from "@tanstack/react-query";
import { Channel, Conversation } from "@/types/entities";
import find from "lodash/find";
import remove from "lodash/remove";
import findIndex from "lodash/findIndex";
import compact from "lodash/compact";
import { useSidebar as _store } from "@/stores/use-sidebar-store";
import { getQueryKey } from "@key.ai/constants";
import { ConversationType } from "@key.ai/enum";
import { IChannel } from "@/types/cusom-types";
import { cloneDeep } from "lodash";

export const queryAddMemberChannel = (
  queryClient: QueryClient,
  queryKey: string,
  _channel: Channel
) => {
  queryClient.setQueryData(compact([queryKey, _channel.categoryId]), (channels: Channel[]) => {
    if (!channels) return channels;

    const _channels = cloneDeep(channels);

    const channel = find(_channels, { id: _channel.id });
    if (!channel) _channels.push(_channel);

    return _channels;
  });
};

export const queryAddMemberToConversation = async (
  queryClient: QueryClient,
  queryKey: string,
  conversation: Conversation
) => {
  if (conversation.type === ConversationType.CHANNEL) return;

  await queryClient.refetchQueries({
    queryKey: [getQueryKey("PERSONAL_CONVERSATIONS")]
  });
  // queryClient.setQueryData(
  //   [queryKey],
  //   (oldData: InfiniteData<ApiResponsePagination<Conversation[]>>) => {
  //     if (!oldData || !oldData.pages || oldData.pages.length === 0) {
  //       return { pages: [{ items: [conversation] }] };
  //     }

  //     let hasConversation = false;
  //     find(oldData.pages, (page) => {
  //       const _conversation = find(page.items, { id: conversation.id });
  //       hasConversation = !!_conversation;
  //     });

  //     if (hasConversation) return oldData;
  //     const newData = [...oldData.pages];
  //     const index = oldData.pages.length - 1;
  //     newData[0] = {
  //       ...newData[0],
  //       items: [conversation, ...newData[index].items],
  //     };

  //     return { ...oldData, pages: newData };
  //   }
  // );
};

export const queryRemoveMemberFromConversation = async (
  queryClient: QueryClient,
  conversationId: string
) => {
  await queryClient.refetchQueries({
    queryKey: [getQueryKey("PERSONAL_CONVERSATIONS")]
  });

  queryClient.removeQueries({
    queryKey: [getQueryKey("GLOBAL_MEMBERS", { conversationId })],
    exact: false
  });

  const store = _store.getState();
  if (
    (store.type === "conversationAllMembers" || store.type === "conversationMembers") &&
    store.data.conversation?.id === conversationId
  ) {
    store.onClose();
  }

  if (window.location.pathname === `/chat/${conversationId}`) {
    window.location.href = `/`;
  }
};

export const queryRemoveMemberChannel = (queryClient: QueryClient, channel: Channel) => {
  const queryKey = getQueryKey("CHANNELS", { serverId: channel.server.slug });
  queryClient.setQueryData(compact([queryKey, channel.categoryId]), (channels: Channel[]) => {
    if (!channels) return channels;

    const _channels = cloneDeep(channels);

    remove(_channels, { id: channel.id });

    const store = _store.getState();

    if (store.type === "channelMembers" && store.data.channel?.id === channel.id) {
      store.onClose();
    }

    queryClient.removeQueries({
      queryKey: [
        getQueryKey("CONVERSATION_MEMBERS", {
          conversationId: channel.conversation.id
        })
      ],
      exact: true
    });

    return _channels;
  });

  if (window.location.pathname === `/channels/${channel.slug}`) {
    window.location.href = `/`;
  }
};

export const queryUpdateChannel = (
  queryClient: QueryClient,
  channel: Channel,
  serverId: string
) => {
  const queryKey = getQueryKey("CHANNELS", { serverId });
  queryClient.setQueryData(compact([queryKey, channel.categoryId]), (channels: Channel[]) => {
    if (!channels) return channels;

    const _channels = [...channels];
    const index = findIndex(_channels, { id: channel.id });
    _channels[index] = channel;

    return [..._channels];
  });

  queryClient.setQueryData([queryKey], (channels: Channel[]) => {
    if (!channels) return channels;

    const _channels = [...channels];
    const index = findIndex(_channels, { id: channel.id });
    _channels[index] = channel;

    return [..._channels];
  });

  // FOR UPDATE IF CHANNEL DATA BASED ON SLUG
  queryClient.setQueryData(
    [getQueryKey("CHANNEL", { serverId, channelId: channel.slug })],
    (_channel: Channel) => {
      if (!_channel) return _channel;
      return Object.assign({}, _channel);
    }
  );

  // FOR UPDATE IF CHANNEL DATA BASED ON CHANNEL ID
  queryClient.setQueryData(
    [getQueryKey("CHANNEL", { serverId, channelId: channel.id })],
    (_channel: Channel) => {
      if (!_channel) return _channel;
      return Object.assign({}, channel);
    }
  );

  // if (
  //   window.location.pathname ===
  //   `/s/${channel.server.slug}/channels/${channel.slug}`
  // ) {
  //   Router.push(`/s/${channel.server.slug}`);
  // }
};

export const queryUpdateChannelMembers = (queryClient: QueryClient, channel: Channel) => {
  const queryKey = getQueryKey("GLOBAL_MEMBERS", {
    conversationId: channel.conversation.id
  });
  // const queryKey = getQueryKey("CONVERSATION_MEMBERS", {
  //   conversationId: channel.conversation.id,
  // });

  // await queryClient.refetchQueries({
  //   queryKey: [getQueryKey("UNREADS", { userId: channel.us })]
  // });
  queryClient.refetchQueries({ queryKey: [queryKey] });
};

export const queryUpdateConversationMembers = (
  queryClient: QueryClient,
  conversationId: string
) => {
  queryClient.refetchQueries({
    queryKey: [getQueryKey("GLOBAL_MEMBERS", { conversationId })],
    exact: false
  });
  // queryClient.refetchQueries({
  //   queryKey: [getQueryKey("PERSONAL_CONVERSATIONS")],
  //   exact: true,
  // });
};

export const queryUpdateChannelUnread = (
  queryClient: QueryClient,
  queryKey: string,
  channel: Channel
) => {
  queryClient.setQueryData([queryKey, channel.categoryId], (channels: IChannel[]) => {
    if (!channels) return channels;

    const index = findIndex(channels, { id: channel.id });
    if ((index >= 0 && channels[index].unreads) || 0 > 0) {
      const _channel = channels[index];
      channels[index] = Object.assign({}, _channel, { unreads: 0 });
    }
    return channels;
  });
};
