"use client";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import React, { ReactNode, useEffect } from "react";
import { useAuth } from "@/stores/use-auth-store";
import { usePostHog } from "posthog-js/react";
import { datadogRum } from "@datadog/browser-rum";

interface Props {
  children: ReactNode;
  session?: Session | null;
}

function AuthSessionProvider({ children, session }: Props) {
  const { setToken, setUserId } = useAuth();

  const posthog = usePostHog();

  useEffect(() => {
    if (session?.accessToken) setToken(session.accessToken);
    // if (session?.auth) setAuth(session.auth);
    if (session?.user.id) setUserId(session.user.id);
    if (session?.user?.id && session?.user?.email) {
      datadogRum.setUser({
        id: session.user.id,
        name: `${session?.user?.firstName} ${session?.user?.lastName}`,
        email: session.user.email
      });
      posthog.identify(session?.user.id, {
        email: session?.user?.email
      });
    }
  }, [setToken, setUserId, session, posthog]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setIsLoading(true);
  //       if (!session) return;
  //       const token = await clientApi.get("api/v1/auth/firebase/token").json<ApiResponse<string>>();
  //       setFbToken(token.data);
  //       const auth = getAuth();
  //       await setPersistence(auth, browserSessionPersistence);
  //       await signInWithCustomToken(auth, token.data);
  //     } catch (error) {
  //       console.log("ERROR", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   })();
  // }, [session]);

  return (
    <SessionProvider session={session} refetchOnWindowFocus={false}>
      {children}
    </SessionProvider>
  );
}

export default AuthSessionProvider;
