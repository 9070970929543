"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { Button } from "@/components/ui/button";
import { Fragment, useMemo, useState } from "react";
import { Dropzone } from "@/components/ui/dropzone";
import { MIME_TYPES } from "@/types/mime-types";
import { useAvatarQuery } from "@/hooks/use-avatar-query";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { Skeleton } from "@/components/ui/skeleton";
import { chunk } from "lodash";
import Divider from "@/components/ui/divider";
import { ArrowLeft2, ArrowRight2, GalleryAdd } from "iconsax-react";
import { AvatarType } from "@/types";
import Image from "next/image";
import { useDevice } from "@/hooks/use-device";
import { cn } from "@/lib/utils";
import { maxFiveMb } from "@/config/max-size";
import { Text } from "@/components/ui/text";

export const AvatarModal = () => {
  const { isOpen, onClose, type, data, onOpen } = useModal();
  const { callback, avatarType } = data;

  const handleImg = async (file: File | null) => {
    if (!file) return;
    onOpen("imageCrop", { img: file, callback });
    // await formik.setFieldValue("imageUrl", URL.createObjectURL(file));
    // await formik.setFieldValue("imageBlob", file);
    // await formik.setFieldValue("imageLiveUrl", null);
    // close();
  };

  const { isMobile } = useDevice();

  const avatar = useMemo(() => avatarType || AvatarType.USER, [avatarType]);

  const { data: avatars, status } = useAvatarQuery({ type: avatarType || AvatarType.USER });
  const [api, setApi] = useState<CarouselApi>();

  const isModalOpen = isOpen && type === "avatar";

  const handleClose = () => onClose();

  const setAvatar = (url: string) => {
    callback?.(url);
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[420px]">
        <DialogHeader>
          <DialogTitle>
            {avatar === AvatarType.COMMUNITY ? "Community Logo" : "Profile photo"}
          </DialogTitle>
        </DialogHeader>

        {status === "pending" ? (
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-1">
            {Array.from({ length: 12 }).map((_, index) => (
              <div className="p-1 flex justify-center" key={index}>
                <Skeleton className="h-[70px] w-[70px] rounded-full" />
              </div>
            ))}
          </div>
        ) : (
          <Fragment>
            {avatars?.length ? (
              <div className="relative overflow-hidden px-5 -mx-5">
                <Carousel className="w-full overflow-hidden" setApi={setApi}>
                  <CarouselContent>
                    {chunk(avatars || [], isMobile ? 8 : 12).map((_avatars, index) => (
                      <CarouselItem key={index}>
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-1 gap-y-4">
                          {_avatars.map((_avatar) => (
                            <button
                              className="flex justify-center bg-transparent"
                              key={_avatar.id}
                              onClick={() => setAvatar(_avatar.url)}
                            >
                              <Image
                                src={_avatar.url}
                                width={70}
                                height={70}
                                alt=""
                                className={cn(
                                  "object-contain",
                                  avatar === AvatarType.USER ? "rounded-full" : "rounded-8"
                                )}
                              />
                            </button>
                          ))}
                        </div>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                </Carousel>

                <button
                  className="top-0 -left-0 bottom-0 absolute w-[24px]"
                  onClick={() => api?.scrollPrev()}
                >
                  <ArrowLeft2 />
                </button>

                <button
                  className="top-0 -right-0 bottom-0 absolute w-[24px]"
                  onClick={() => api?.scrollNext()}
                >
                  <ArrowRight2 />
                </button>
              </div>
            ) : null}
          </Fragment>
        )}

        <Divider />

        <div className="flex justify-center items-center flex-col gap-2">
          <Dropzone
            accept={{
              [MIME_TYPES.png]: [],
              [MIME_TYPES.gif]: [],
              [MIME_TYPES.jpeg]: [],
              [MIME_TYPES.svg]: [],
              [MIME_TYPES.webp]: [],
              [MIME_TYPES.avif]: [],
              [MIME_TYPES.heic]: []
            }}
            onDrop={(files) => handleImg(files[0])}
            multiple={false}
            className="inline-block"
            maxSize={maxFiveMb}
          >
            <Button className="ms-3 border-dashed border-1 px-7 border-black " variant="text">
              <GalleryAdd size={16} className="mr-[3px]"></GalleryAdd>
              Upload {avatarType === AvatarType.COMMUNITY ? "Community Logo" : "Profile Picture"}
            </Button>
          </Dropzone>

          {avatar === AvatarType.COMMUNITY ? (
            <div className="text-center">
              <Text variant="body_one" className="text-color-secondary">
                Stand out with a unique logo for your community. You can always change or customize
                it later!
              </Text>
              <Text className="text-color-secondary" variant="body_one">
                Recommended size: 400x400 (1:1).
              </Text>
            </div>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};
