import { QueryClient } from "@tanstack/react-query";
import { Conversation } from "@/types/entities";
import { useSidebar as _store } from "@/stores/use-sidebar-store";
import { getQueryKey } from "@key.ai/constants";
import { ICommunityUser } from "@/types/cusom-types";

// export const queryUpdateMember = (
//   queryClient: QueryClient,
//   { serverId, memberId }: { serverId: string; memberId: string }
// ) => {
//   queryClient.refetchQueries({
//     queryKey: [getQueryKey("MEMBER_INFO", { serverId, memberId })],
//   });

//   const current = queryClient.getQueryData<
//     Member & { conversation: Conversation | null }
//   >([getQueryKey("MEMBER_INFO", { serverId, memberId: "me" })]);

//   if (current && current.id === memberId) {
//     queryClient.refetchQueries({
//       queryKey: [getQueryKey("MEMBER_INFO", { serverId, memberId: "me" })],
//     });
//   }
// };

export const queryUpdateUserStatus = (
  queryClient: QueryClient,
  { isOnline, userId }: { isOnline: boolean; userId: string }
) => {
  const key = getQueryKey("USER_INFO", { userId });
  queryClient.setQueryData([key], (user: ICommunityUser) => {
    if (!user) return user;
    return Object.assign({}, user, { isOnline });
  });

  const me = getQueryKey("USER_INFO", { userId: "me" });
  const current = queryClient.getQueryData<ICommunityUser & { conversation: Conversation | null }>([
    me
  ]);

  if (current && current.id === userId) {
    queryClient.setQueryData([me], (user: ICommunityUser) => {
      if (!user) return user;
      return Object.assign({}, user, { isOnline });
    });
  }
};
