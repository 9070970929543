import authClientApi from "@/api-client/auth-client";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import Divider from "@/components/ui/divider";
import { Text } from "@/components/ui/text";
import { useModal } from "@/stores/use-modal-store";
import { useSession } from "next-auth/react";
import React, { useState } from "react";

const StripeConnect = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { data: session }: any = useSession();

  const [stripeAccount, setStripeAccount] = useState<any>();

  const isModalOpen = isOpen && type === "stripeConnect";

  const createStripeLink = async () => {
    try {
      const url = window.location.href;
      const response = await authClientApi
        .post(`api/v1/auth/create-account-link/${session?.user?.id}`, {
          json: {
            refresh_url: url,
            return_url: url
          }
        })
        .json<any>();
      window.open(response.data.data.url, "_self");
      setStripeAccount(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const createStripeAccount = async () => {
    try {
      const response = await authClientApi
        .post(`api/v1/auth/connected-account/${session?.user?.id}`, {
          json: {}
        })
        .json<any>();
      if (response.success && response.statusCode === 200) {
        createStripeLink();
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleSubmit = () => {
    if (!data?.stripeData) {
      return createStripeAccount();
    }

    if (!data?.stripeData?.details_submitted) {
      createStripeLink();
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-full w-[400px]">
        <DialogDescription className="text-zinc-600 text-m font-normal flex justify-between items-center">
          <Text variant={"subtitle_one_strong"}>Enable Payment</Text>
        </DialogDescription>
        <Divider />
        <div>
          Once you link a Stripe account, you’ll receive daily payouts to your linked bank account.
        </div>
        <div className="flex justify-between -z-50 mt-m items-center">
          <Button className="w-full" onClick={() => handleSubmit()}>
            Connect with Stripe Account
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StripeConnect;
