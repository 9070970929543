"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { Text } from "@/components/ui/text";
import * as yup from "yup";
import { FormikProps, useFormik } from "formik";
import { toast } from "sonner";
import OTPInput from "@/components/ui/otp-input";
import { Input } from "@/components/ui/input";
import { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import Countdown, { CountdownApi, zeroPad } from "react-countdown";
import { Button } from "@/components/ui/button";
import Divider from "@/components/ui/divider";
import authClientApi from "@/api-client/auth-client";
import { ApiResponse } from "@/types/api";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Textarea } from "@/components/ui/textarea";
import { useSession } from "next-auth/react";
import { AuthRoutes } from "@/lib/url";

const Schema = yup.object({
  otp: yup.string().trim().required("This field is required.").min(6, "Please enter valid OTP.")
});

type DeleteAccountForm = {
  token: string;
  otp: string;
  deleteToken: string;
  reason: string;
  ownReason: string;
};

const options = [
  "No longer using the platform",
  "Privacy concerns",
  "Security Concerns",
  "Duplicate Accounts",
  "Other"
];

export const DeleteAccountModal = () => {
  const { isOpen, type, onClose, data } = useModal();
  const [step, setStep] = useState(1);

  const isModalOpen = isOpen && type === "deleteAccount";

  const formik = useFormik<DeleteAccountForm>({
    initialValues: {
      token: "",
      otp: "",
      deleteToken: "",
      reason: "",
      ownReason: ""
    },
    validationSchema: Schema,

    onSubmit: async (values, { setSubmitting, setFieldValue }) => {
      try {
        if (step === 1) {
          const { message, data } = await authClientApi
            .post(`api/v1/auth/delete-account-verification`, {
              json: values
            })
            .json<ApiResponse<{ token: string }>>();
          await setFieldValue("deleteToken", data.token);
          toast.success(message);
          setStep(2);
        }

        if (step === 2) {
          const payload = {
            token: values.deleteToken,
            deletionReason: values.reason
          };
          if (values.reason === "Other") {
            payload.deletionReason = `${values.reason}: ${values.ownReason}`;
          }

          const { message } = await authClientApi
            .patch(`api/v1/auth/delete-account`, {
              json: payload
            })
            .json<ApiResponse<{ token: string }>>();
          toast.success(message);
          onClose();
          window.location.href = AuthRoutes.LOGOUT;
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (isModalOpen) {
      formik.setFieldValue("token", data.token);
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, data]);

  const handleClose = () => {
    onClose();
    setStep(0);
    formik.resetForm();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      {step === 1 ? <StepOne formik={formik} /> : null}
      {step === 2 ? <StepTwo formik={formik} /> : null}
    </Dialog>
  );
};

const StepTwo = ({ formik }: { formik: FormikProps<DeleteAccountForm> }) => {
  return (
    <DialogContent className="max-w-full-50px w-[500px] p-2xl max-h-[calc(100dvh - 90px)]">
      <DialogHeader>
        <DialogTitle>Reason</DialogTitle>
      </DialogHeader>

      <Divider />

      <div className="flex flex-col gap-2xl">
        <RadioGroup
          className="flex flex-col gap-4"
          onValueChange={(value) => formik.setFieldValue("reason", value)}
        >
          {options.map((option, index) => (
            <div className="flex items-center gap-4" key={index}>
              <RadioGroupItem
                value={option}
                checked={formik.values.reason === option}
                size="medium"
                className="flex-shrink-0"
              />
              <div className="flex flex-col w-full flex-shrink-1">
                {option === "Other" ? (
                  <Fragment>
                    <Textarea
                      name="ownReason"
                      placeholder="Specify other reason"
                      onChange={formik.handleChange}
                      value={formik.values.ownReason}
                      textareaClassName="field-border shadow-none"
                      error={(formik.touched.ownReason && formik.errors.ownReason) as boolean}
                      errorMessage={formik.errors.ownReason}
                      errorOutline={
                        (formik.touched.ownReason && formik.errors.ownReason) as boolean
                      }
                      readOnly={formik.values.reason !== "Other"}
                    />
                  </Fragment>
                ) : (
                  <Fragment>{option}</Fragment>
                )}
              </div>
            </div>
          ))}
        </RadioGroup>
      </div>

      <div className="flex justify-center">
        <Button
          type="button"
          onClick={() => formik.handleSubmit()}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          loaderProps={{ size: "xs" }}
          className="px-10"
        >
          Submit
        </Button>
      </div>
    </DialogContent>
  );
};

const StepOne = ({ formik }: { formik: FormikProps<DeleteAccountForm> }) => {
  const { data: session } = useSession();

  const [date, setDate] = useState(moment().add(1, "minute").toDate());
  const ref = useRef<CountdownApi | null>();

  const setRef = (countdown: Countdown | null): void => {
    if (countdown) {
      ref.current = countdown.getApi();
      ref.current?.start();
    }
  };

  const resendOtp = async () => {
    try {
      formik.setSubmitting(true);
      const { message, data } = await authClientApi
        .post(`api/v1/auth/send-otp/${session?.user.id}`, {
          json: {}
        })
        .json<ApiResponse<{ token: string }>>();
      formik.setFieldValue("token", data.token);
      toast.success(message);
      setDate(moment().add(1, "minute").toDate());
      ref.current?.start();
    } catch (error) {
      toast.error(error.message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const onMount = () => ref.current?.start();

  return (
    <DialogContent className="max-w-full-50px w-[500px] p-2xl max-h-[calc(100dvh - 90px)]">
      <DialogHeader>
        <DialogTitle>Enter Verification Code</DialogTitle>
      </DialogHeader>

      <Divider />

      <div className="flex flex-col gap-2xl">
        <Text variant="body_one" className="text-color-secondary">
          We’re sorry to see you go!
        </Text>
        <div className="flex items-center gap-1">
          <Text variant="body_one" className="text-color-secondary">
            We’ve sent a code to
          </Text>
          <Text variant="body_one" as="b">
            {session?.user.email}
          </Text>
        </div>

        <div className="flex flex-col gap-1">
          <OTPInput
            onChange={(value) => formik.setFieldValue("otp", value)}
            renderInput={(props) => (
              <Input
                {...props}
                inputClassName="text-center hide-arrow font-semibold"
                className=""
                fullWidth
              />
            )}
            numInputs={6}
            value={formik.values.otp}
            inputType="number"
            shouldAutoFocus
          />
          {formik.touched.otp && formik.errors.otp ? (
            <Text as="span" className="text-red-500 text-xs mt-1">
              {formik.errors.otp}
            </Text>
          ) : null}
        </div>
        <div className="flex items-center">
          <Countdown
            date={date}
            ref={setRef}
            autoStart={false}
            onMount={onMount}
            renderer={({ minutes, seconds, completed }) => {
              return (
                <div className="w-full flex items-center justify-center">
                  {completed ? (
                    <Fragment>
                      <Text as="span" variant="caption_one" className="me-2">
                        Didn’t receive an OTP?
                      </Text>

                      <Text
                        as="button"
                        variant="body_one_strong"
                        className="text-brand-600 bg-transparent border-none"
                        type="button"
                        disabled={formik.isSubmitting}
                        onClick={resendOtp}
                      >
                        Resend
                      </Text>
                    </Fragment>
                  ) : (
                    <div className="flex items-center me-2">
                      <Text variant="caption_one">Please wait</Text>
                      <Text variant="caption_one" className="text-brand-600 font-semibold mx-1">
                        {zeroPad(minutes)}:{zeroPad(seconds)}
                      </Text>
                      <Text variant="caption_one">seconds to try again.</Text>
                    </div>
                  )}
                </div>
              );
            }}
          />
        </div>

        <div className="flex justify-center">
          <Button
            type="button"
            onClick={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            loaderProps={{ size: "xs" }}
            className="px-10"
          >
            Verify
          </Button>
        </div>

        <div className="flex justify-center items-center gap-1">
          <Text variant="body_two" as="span" className="text-color-secondary">
            Need help?
          </Text>
          <Text as="a" variant="body_two" className="text-brand-600" href="mailto:support@key.ai">
            Contact our support team
          </Text>
        </div>
      </div>
    </DialogContent>
  );
};
