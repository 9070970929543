import PlaceSearchBoxForModal from "@/components/app/common/placesSearchForModal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useGeoLocationQuery } from "@/hooks/use-geo-location";
import { convertLocationToObject } from "@/lib/utils";
import { useLocation } from "@/stores/use-location-store";
import { useModal } from "@/stores/use-modal-store";
import { Location } from "@/types/cusom-types";
import { useSession } from "next-auth/react";
import React, { useEffect, useRef, useState } from "react";

const LocationModal = () => {
  const { isOpen, onClose, type } = useModal();
  const { setLocation, location } = useLocation();
  const { data: session }: any = useSession();

  const isModalOpen = isOpen && type === "location";

  const [miles, setMiles] = useState<number | string>(location?.miles ?? "");
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [isPlacesLoaded, setIsPlacesLoaded] = useState<boolean>(false);

  const placesInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedLocation(location);
  }, [location?.latLng.lat, location?.latLng.lng]);

  useEffect(() => {
    if (placesInputRef.current) {
      placesInputRef.current.focus();
    }
    return () => {
      setIsPlacesLoaded(false);
    };
  }, [isModalOpen, isPlacesLoaded, placesInputRef?.current, location]);

  const handleLocation = (location: any, description?: string) => {
    setSelectedLocation({ ...location, description });
  };

  const submit = () => {
    setLocation({
      ...selectedLocation,
      miles: miles
    });
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-full-50px w-[500px]">
        <DialogDescription className="text-primary text-2xl font-extrabold ">
          Add Location
        </DialogDescription>
        <PlaceSearchBoxForModal
          onSelectPlace={handleLocation}
          description={selectedLocation?.description || ""}
          selectedLocation={selectedLocation}
          showMap={true}
          searchOptions={{ types: ["locality"] }}
          inputRef={placesInputRef}
          onLoad={() => setIsPlacesLoaded(true)}
        />
        <Input
          variant="medium-s"
          type="number"
          className="rounded-lg mt-s w-full px-xl"
          inputClassName="w-full"
          placeholder="Miles"
          value={miles}
          onChange={(e: any) => setMiles(e.target.value)}
        />
        <div className="flex justify-between -z-50 mt-m items-center">
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            disabled={
              (!selectedLocation?.locality && !selectedLocation?.latLng?.lat) || !Number(miles)
            }
            onClick={() => submit()}
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LocationModal;
