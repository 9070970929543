import { create } from "zustand";

export interface LinkModalData {
  link?: {
    text: string;
    value?: string;
  };
  callback?: (args?: any) => void;
}

interface LinkModalStore {
  data: LinkModalData;
  isOpen: boolean;
  onOpen: (data?: LinkModalData) => void;
  onClose: () => void;
}

export const useLinkModal = create<LinkModalStore>((set) => ({
  data: {},
  isOpen: false,
  onOpen: (data = {}) => set({ data, isOpen: true }),
  onClose: () => set({ isOpen: false })
}));
