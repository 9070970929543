"use client";

import React from "react";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import clsx from "clsx";

const Collapsible = CollapsiblePrimitive.Root;
const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;
const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;

interface CollapsibleComponentProps {
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  selected?: boolean;
  triggerText: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  contentClasses?: string;
  triggerClasses?: string;
  className?: string;
  direction?: "up" | "down";
}

const CollapsibleComponent: React.FC<CollapsibleComponentProps> = ({
  isOpen = false,
  onToggle,
  selected = false,
  triggerText,
  children,
  disabled,
  contentClasses,
  triggerClasses,
  className,
  direction = "down"
}) => {
  return (
    <Collapsible open={isOpen} onOpenChange={onToggle} disabled={disabled} className={className}>
      <CollapsibleTrigger
        className={clsx(
          "font-medium text-left w-full rounded-sm",
          {
            // "bg-color-contrast ": selected,
            "bg-color-contrast": isOpen,
            "bg-contain text-primary": !selected
          },
          triggerClasses
        )}
      >
        {triggerText}
      </CollapsibleTrigger>
      <CollapsibleContent
        className={clsx(
          "bg-contain border-solid border-1 border-container-border shadow-subtle-sm rounded-sm  m-m px-l py-2",
          {
            "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95":
              true,
            "data-[side=bottom]:slide-in-from-top-2": direction === "down",
            "data-[side=top]:slide-in-from-bottom-2": direction === "up"
          },
          `backdrop-blur-20 ${contentClasses}`
        )}
      >
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
};

export { CollapsibleComponent };
