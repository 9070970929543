import SubmitEvent from "@/components/app/faderated-calendar/submit-event";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import React from "react";

const SubmitEventToCalendar = () => {
  const { isOpen, onClose, type, data } = useModal();

  const { calendarId, alias, calendarDetails } = data;

  const isModalOpen = isOpen && type === "addEventToCalendar";

  return (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className="p-3xl w-full max-w-[600px] min-w-[300px] max-h-[90vh] overflow-y-auto">
        <DialogTitle className="leading-[22px] text-[22px]">Submit Event</DialogTitle>
        <SubmitEvent
          calendarId={calendarId}
          alias={alias}
          calendarDetails={calendarDetails}
          closePopup={() => onClose()}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SubmitEventToCalendar;
