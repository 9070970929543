import eventClientApi from "@/api-client/event-client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import Avatar from "@/components/ui/avatar";
import Box from "@/components/ui/box";
import { Checkbox } from "@/components/ui/checkbox";
import { CollapsibleComponent } from "@/components/ui/collapse";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import Multiline from "@/components/ui/multiline";
import SectionHeader from "@/components/ui/section-header";
import { Skeleton } from "@/components/ui/skeleton";
import { Text } from "@/components/ui/text";
import useInView from "@/hooks/use-in-view";
import { cleanObject } from "@/lib/helper";
import { eventImageSizeMapper, getCurrentEpoc, getUserInitials } from "@/lib/utils";
import { inviteEmail } from "@/types/cusom-types";
import { ArrowDown2 } from "iconsax-react";
import _ from "lodash";
import { useSession } from "next-auth/react";
import React, { Fragment, useEffect, useState } from "react";

const prepareGetParticipantsURL = (data: {
  eventId: string;
  roles?: string;
  sortBy?: string[];
  status?: string;
  searchText?: string;
  details?: boolean;
  limit?: number;
  page?: number;
  searchParam?: string;
  searchValue?: string;
  checkin_epoc?: boolean;
}) => {
  return `api/v2/events/${data?.eventId}/event-participations?roles=${data.roles || ""}&statuses=${data.status || ""}&name=${encodeURIComponent(data.searchText || "")}&sort=${
    data.sortBy && data.sortBy.length ? data.sortBy.map((item) => `${item}:ASC`).join(",") : ""
  }${data.details === false ? "&details=false" : ""}${
    data.searchParam && data.searchValue
      ? "&searchParam=" + data.searchParam + "&searchValue=" + data.searchValue
      : ""
  }${typeof data.limit === "number" ? "&limit=" + data.limit : ""}${
    typeof data.page === "number" ? "&page=" + data.page : ""
  }${data.checkin_epoc === true ? "&checkin_epoc=true" : ""}`;
};

const PastEventGuest = ({ setEmails, role, emails }: any) => {
  const [events, setEvents] = useState<any>([]);

  const [opened, setOpened] = useState<string>("");

  const [selected, setSelected] = useState<string>("");

  const [userLoading, setUserLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<any>(true);
  const [users, setUsers] = useState<any>([]);

  const [page, setPage] = useState<any>({});
  const [eventPage, setEventPage] = useState<number>(1);
  const { data: session }: any = useSession();

  const [ref, isInView] = useInView({
    threshold: 1.0
  });

  const [eventRef, eventIsInView] = useInView({
    threshold: 1.0
  });

  const fetchPastEvents = async () => {
    try {
      const { data } = await eventClientApi
        .get(`api/v1/users/${session?.user?.id}/events`, {
          searchParams: cleanObject({
            page: eventPage,
            limit: 10,
            role: "creator,host",
            status: "published",
            date_operator: "lt",
            current_epoc: getCurrentEpoc()
          })
        })
        .json<any>();
      if (!data?.length) return;
      setEventPage((prevState: number) => prevState + 1);
      setEvents((prevState: any) => [...prevState, ...data]);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventIsInView) {
      fetchPastEvents();
    }
  }, [eventIsInView, eventPage]);

  const handleSelectGuest = async (id: string, toggle: boolean) => {
    if (!toggle) {
      setUsers((prevState: any) => {
        if (!prevState[id]) return prevState;
        return {
          ...prevState,
          [id]: prevState[id].map((user: any) => ({ ...user, selected: false }))
        };
      });
      return setEmails(emails.filter((email: any) => email.eventId !== id));
    }
    const apiUrl = prepareGetParticipantsURL({
      eventId: id || "",
      roles: "guest,featured_guest,host",
      limit: 50,
      page: 1
    });

    try {
      const { data } = await eventClientApi.get(apiUrl).json<any>();
      const emailsData: inviteEmail[] = _.flatten(Object.values(data)).map((user: any) => {
        return {
          ...user?.User,
          selected: false
        };
      });
      const invite = emailsData?.map((d: any) => ({
        email: d.email,
        role: role,
        selected: true,
        eventId: id
      }));
      setEmails((prevState: any) => [...prevState, ...invite]);
      setUsers((prevState: any) => {
        if (!prevState[id]) return prevState;
        return {
          ...prevState,
          [id]: prevState[id].map((user: any) => ({ ...user, selected: toggle }))
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleSelectAllGuests = async (toggle: boolean) => {
    const allEventIds = events?.map((event: any) => event?.Event?.id);
    const promises = allEventIds.map((id: string) => handleSelectGuest(id, toggle));
    try {
      await Promise.all(promises);
      console.log("All selections updated successfully.");
    } catch (error) {
      console.error("Error updating selections: ", error);
    }
  };

  const handleFetchPastEventGuests = async () => {
    if (opened === selected) {
      return setOpened("");
    }

    const apiUrl = prepareGetParticipantsURL({
      eventId: selected || "",
      roles: "guest,featured_guest,host",
      limit: 7,
      page: page[selected] || 1
    });

    try {
      setUserLoading(true);

      const { data } = await eventClientApi.get(apiUrl).json<any>();
      const emailsData: inviteEmail[] = _.flatten(Object.values(data)).map((user: any) => {
        return {
          ...user?.User,
          selected: false,
          role: role
        };
      });

      if (!emailsData.length) return;

      setPage((prevState: any) => ({
        ...prevState,
        [selected]: (prevState?.[selected] ?? 1) + 1
      }));
      setUsers((prevState: any) => ({
        ...prevState,
        [selected]: prevState[selected] ? [...emailsData, ...prevState[selected]] : [...emailsData]
      }));

      setOpened(selected);
    } catch (error) {
      console.log(error);
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (isInView && selected) {
      handleFetchPastEventGuests();
    }
  }, [isInView]);

  const updateSelectedField = (_user: any, event_id: string) => {
    setEmails((prevEmails: any) =>
      prevEmails.map((email: any) =>
        email.email === _user.email ? { ...email, selected: !email.selected } : email
      )
    );

    setUsers((prevState: any) => {
      if (prevState[event_id]) {
        return {
          ...prevState,
          [event_id]: prevState[event_id].map((user: any) =>
            user.email === _user.email ? { ...user, selected: !user.selected } : user
          )
        };
      }
      return prevState;
    });
  };

  return (
    <div>
      <Box className=" mt-xl">
        <div className="w-full">
          <Input
            variant="medium-s"
            className="rounded-xs w-full px-xl"
            inputClassName="w-full "
            placeholder="Search Contacts of Events"
          />
        </div>
        <SectionHeader
          containerClassName="mt-s px-s"
          titleText={
            <Text variant={"caption_one_strong"} className="text-color-secondary">
              Events
            </Text>
          }
          action={
            <div className="flex gap-m items-center">
              <Text variant={"caption_one"}>Select All</Text>
              <Checkbox
                className="text-color-tertiary"
                size={"small"}
                onCheckedChange={handleSelectAllGuests}
              />
            </div>
          }
        />

        <div className="max-h-[47vh] overflow-y-auto">
          {loading ? (
            <div className="mx-auto">
              <Loader />
            </div>
          ) : events?.length ? (
            <Accordion
              type="single"
              className="w-full flex flex-col gap-s-nudge h-full shadow-none"
              collapsible
            >
              {events?.map((_event: any, index: number) => {
                const { Event: event } = _event;
                return (
                  <Fragment>
                    <AccordionItem
                      value={event?.id}
                      className="shadow-none"
                      onClick={() => setSelected(event?.id)}
                    >
                      <AccordionTrigger className=" px-[12px] justify-start">
                        <Multiline
                          className="bg-transparent !px-0 !mx-0"
                          icon={
                            <div
                              className="flex gap-m items-center"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Checkbox
                                variant="rounded"
                                onCheckedChange={(e: boolean) => handleSelectGuest(event?.id, e)}
                              />
                              <Avatar
                                imgSrc={eventImageSizeMapper(event?.image_map)}
                                size={56}
                                activeRing={false}
                                variant="image"
                                present={false}
                                presence={false}
                                presenceState="blocked"
                              />
                            </div>
                          }
                          titleText={event?.name}
                          text1={`${_event?.participantsCount} People`}
                          titleTextSize={"medium"}
                          text1Size={"small"}
                        />
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="px-xl">
                          {users?.[event?.id]?.length ? (
                            <div>
                              {users?.[event?.id]?.map((user: any, index: number) => {
                                if (!user) return;
                                const initals = getUserInitials(user);

                                return (
                                  <Multiline
                                    key={index}
                                    icon={
                                      <div className="flex gap-m items-center">
                                        {/* <Checkbox
                                variant="rounded"
                                onCheckedChange={() => updateSelectedField(user, event?.id)}
                                checked={user?.selected}
                              /> */}
                                        <Avatar
                                          text={initals}
                                          imgSrc={user?.profile_photo}
                                          size={56}
                                          activeRing={true}
                                          variant={user?.profile_photo ? "image" : "initials"}
                                          present={false}
                                          presence={false}
                                        />
                                      </div>
                                    }
                                    text1={user?.email}
                                    titleTextSize={"medium"}
                                    text1Size={"small"}
                                  />
                                );
                              })}
                            </div>
                          ) : userLoading ? (
                            <div className="w-full">
                              <Loader className="mx-auto" />
                            </div>
                          ) : null}
                        </div>

                        <div ref={ref} />
                      </AccordionContent>
                    </AccordionItem>
                  </Fragment>
                );
              })}
            </Accordion>
          ) : (
            <Box className="bg-transparent text-center">No past events found.</Box>
          )}
          <div ref={eventRef}></div>
        </div>
      </Box>
    </div>
  );
};

export default PastEventGuest;
