import { initializeFirestore } from "firebase/firestore";
import { env } from "@/env";
import app from "./firebase";

// export const db = env.NEXT_PUBLIC_FIREBASE_DATABASE_ID
//   ? getFirestore(app, env.NEXT_PUBLIC_FIREBASE_DATABASE_ID)
//   : getFirestore(app);

export const db = initializeFirestore(
  app,
  {
    experimentalAutoDetectLongPolling: true,
    experimentalLongPollingOptions: { timeoutSeconds: 25 }
  },
  env.NEXT_PUBLIC_FIREBASE_DATABASE_ID
);
