"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useLinkModal } from "@/stores/use-link-store";
import { useFormik } from "formik";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useCallback, useEffect } from "react";
import { isValidUrl } from "@/lib/url";

const Schema = yup.object({
  text: yup.string().trim(),
  link: yup
    .string()
    .required("This field is required.")
    .test("is-url-valid", "Please enter valid URL.", (value) => isValidUrl(value))
});

export const QlLinkModal = () => {
  const { isOpen, onClose, data } = useLinkModal();
  const { link, callback } = data;

  const formik = useFormik({
    initialValues: {
      text: "",
      link: ""
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      values.link = values.link.startsWith("http") ? values.link : `https://${values.link}`;
      callback?.({ ...values, addSpace: !link?.text });
      resetForm();
      onClose();
    }
  });

  useEffect(() => {
    if (link && isOpen) {
      formik.setFieldValue("text", link.text);
      formik.setFieldValue("link", link.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, isOpen]);

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const removeLink = useCallback(() => {
    callback?.({ text: formik.values.text });
    handleClose();
  }, [formik.values, callback, handleClose]);

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4 max-h-[calc(100dvh - 90px)]">
        <DialogHeader className="sticky">
          <DialogTitle>Add Link</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="mb-5 w-full">
            <Input
              placeholder="Text"
              name="text"
              label="Text"
              onChange={formik.handleChange}
              value={formik.values.text}
              error={(formik.touched.text && formik.errors.text) as boolean}
              errorMessage={formik.errors.text}
              errorOutline={(formik.touched.text && formik.errors.text) as boolean}
            />
          </div>

          <div className="mb-5 w-full">
            <Input
              label="Link"
              placeholder="Link"
              name="link"
              onChange={formik.handleChange}
              value={formik.values.link}
              error={(formik.touched.link && formik.errors.link) as boolean}
              errorMessage={formik.errors.link}
              errorOutline={(formik.touched.link && formik.errors.link) as boolean}
            />
          </div>

          <div className="flex items-start gap-2">
            <Button disabled={formik.isSubmitting} type="submit">
              Add
            </Button>
            {link?.value ? (
              <Button disabled={formik.isSubmitting} onClick={removeLink} type="button">
                Remove
              </Button>
            ) : null}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
