import { Skeleton } from "./ui/skeleton";

export default function EditorLoader() {
  return (
    <div className="p-4 rounded-md flex flex-col gap-4">
      <Skeleton className="w-[60%] h-[10px] rounded-md" />
      <Skeleton className="w-[70%] h-[10px] rounded-md" />
      <Skeleton className="w-[100%] h-[10px] rounded-md" />
      <Skeleton className="w-[60%] h-[10px] rounded-md" />
    </div>
  );
}
