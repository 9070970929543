"use client";

import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
  PropTypes
} from "react-places-autocomplete";
import { extractAddressComponents, extractFullAddress } from "@/lib/utils";
import { env } from "@/env";
import MapPreview from "../recurrance/common/map-preview";
import { Text } from "@/components/ui/text";
import useGoogleMaps from "@/hooks/use-google-maps";
import { Input } from "@/components/ui/input";
import { X } from "lucide-react";

interface PlaceSearchBoxProps {
  onSelectPlace: (location: IGeoLocation, description: string) => void;
  selectedLocation?: IGeoLocation;
  description: string;
  setLocationData?: any;
  showMap?: boolean;
  placeholderText?: string;
  disabled?: boolean;
  searchOptions?: PropTypes["searchOptions"];
  inputRef?: React.RefObject<HTMLInputElement>;
  onLoad?: () => void;
}

export interface IGeoLocation {
  sublocality: string; // jubliee hils
  locality: string; // Hyd
  administrative_area_level_3: string; // Rangareddy
  administrative_area_level_2: string; //
  administrative_area_level_1: string; // Telangana
  country: string; // IN
  latLng: ILatLng;
  placeId: string;
  location_label?: string;
}

export interface ILatLng {
  lat: number;
  lng: number;
}

declare global {
  interface Window {
    initMap: () => void; // This line ensures TypeScript knows about `window.initMap`;
    googleLoadingScript: boolean;
  }
}

const PlaceSearchBoxForModal: React.FC<PlaceSearchBoxProps> = ({
  onSelectPlace,
  selectedLocation,
  description,
  showMap = true,
  placeholderText,
  setLocationData,
  disabled = false,
  searchOptions = {},
  inputRef,
  onLoad = () => {}
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(description || "");
  const [selectedLatLng, setSelectedLatLng] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const isScriptLoaded = useGoogleMaps();

  const handleSelect = async (description: string, placeId: string) => {
    try {
      const place = await geocodeByPlaceId(placeId);
      const latLng = await getLatLng(place[0]);
      setSelectedLatLng(latLng);
      const location = extractAddressComponents(place[0]?.address_components);
      const fullAdress = extractFullAddress(place[0], description);
      location.latLng = latLng;
      location.placeId = placeId;
      onSelectPlace(location, place[0]?.formatted_address);
      setSearchQuery(place[0]?.formatted_address);
    } catch (error) {
      console.error("Error selecting place: ", error);
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setSelectedLatLng({ lat: 0, lng: 0 });
      onSelectPlace(
        {
          sublocality: "",
          locality: "",
          administrative_area_level_3: "",
          administrative_area_level_2: "",
          administrative_area_level_1: "",
          country: "",
          latLng: {
            lat: 0,
            lng: 0
          },
          placeId: "",
          location_label: ""
        },
        ""
      );
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selectedLocation) {
      setSelectedLatLng(selectedLocation.latLng);
      setSearchQuery(description);
      setLabelText(selectedLocation?.location_label || "");
    }
    // this is the selectedLocation which user selects from dropdown
  }, [selectedLocation]);

  const [labelText, setLabelText] = useState<string>("");

  return (
    <div className="w-full">
      {isScriptLoaded ? (
        <PlacesAutocomplete
          value={searchQuery}
          onChange={(value: string) => setSearchQuery(value)}
          onSelect={(value: string, placeId: string) => handleSelect(value, placeId)}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
            <div className="w-full relative">
              <Input
                {...getInputProps({
                  placeholder: placeholderText ? placeholderText : "Search for places",
                  className:
                    "w-full rounded-lg field-border bg-container  border-none text-sm font-normal text-black focus:outline-none focus:ring-0  !placeholder:placeholder-color-tertiary !placeholder:font-normal"
                })}
                disabled={disabled}
                ref={inputRef}
                rightSectionClassName="py-none px-none pr-m-nudge"
                rightSection={
                  description && (
                    <X
                      size={20}
                      onClick={() => {
                        setSearchQuery("");
                      }}
                    ></X>
                  )
                }
              />
              <div className="absolute z-50 mt-0 bg-white backdrop-blur-30 rounded-lg w-full shadow-blur-sm">
                {loading && (
                  <div className="w-full text-center">
                    <Text className="mx-auto">Loading...</Text>
                  </div>
                )}
                {suggestions.map((suggestion: any, index: number) => (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    key={index}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200 z-50"
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        "Loading..."
      )}

      <div className="mt-m">
        {showMap && selectedLocation && (
          <MapPreview isScriptLoaded={isScriptLoaded} selectedLocation={selectedLocation} />
        )}
      </div>
    </div>
  );
};

export default PlaceSearchBoxForModal;
