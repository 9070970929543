import { useInfiniteQuery } from "@tanstack/react-query";
import { ApiResponse } from "@/types/api";
import { getQueryKey } from "@key.ai/constants";
import { cleanObject } from "@/lib/helper";
import clientApi from "@/api-client/client";

export const useUnsplashQuery = ({ query, type }: { query: string; type: string }) => {
  const queryKey = getQueryKey("UNSPLASH");

  const fetchUnsplashImages = async ({ pageParam = 1 }: { pageParam: unknown }) => {
    const { data } = await clientApi
      .get(`api/v1/unsplash`, {
        searchParams: {
          query: query,
          type: type,
          page: pageParam as string,
          limit: 20
        }
      })
      .json<ApiResponse<any>>();

    return { data, pageParam };
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading, refetch } =
    useInfiniteQuery<any>({
      queryKey: [queryKey, query, type],
      queryFn: fetchUnsplashImages,
      getNextPageParam: (lastPage: any) => {
        return lastPage?.pageParam + 1;
      },
      initialPageParam: 1,
      refetchOnMount: true,
      refetchInterval: false,
      refetchOnWindowFocus: true
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isLoading,
    refetch
  };
};
