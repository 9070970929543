"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { useFormik } from "formik";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { isValidUrl } from "@/lib/url";

const Schema = yup.object({
  text: yup
    .string()
    .required("This field is required.")
    .trim()
    .max(50, "Max 50 characters allowed."),
  value: yup
    .string()
    .required("This field is required.")
    .test("is-url-valid", "Please enter valid URL.", (value) => isValidUrl(value))
});

export const CTALinkModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { link, callback } = data;

  const isModalOpen = isOpen && type === "ctaAction";

  const formik = useFormik({
    initialValues: {
      text: "",
      value: ""
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      values.value = values.value.startsWith("http") ? values.value : `https://${values.value}`;
      callback?.(values);
      resetForm();
      onClose();
    }
  });

  useEffect(() => {
    if (link && isOpen) {
      formik.setFieldValue("text", link.text || "");
      formik.setFieldValue("value", link.value || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, isOpen]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[650px] p-4 max-h-[calc(100dvh - 90px)]">
        <DialogHeader className="sticky">
          <DialogTitle>External Links</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4 flex flex-col gap-2xl">
          <div className="w-full">
            <Input
              placeholder="Title"
              name="text"
              label="Title"
              onChange={formik.handleChange}
              value={formik.values.text}
              error={(formik.touched.text && formik.errors.text) as boolean}
              errorMessage={formik.errors.text}
              errorOutline={(formik.touched.text && formik.errors.text) as boolean}
            />
          </div>

          <div className="w-full">
            <Input
              label="URL"
              placeholder="URL"
              name="value"
              onChange={formik.handleChange}
              value={formik.values.value}
              error={(formik.touched.value && formik.errors.value) as boolean}
              errorMessage={formik.errors.value}
              errorOutline={(formik.touched.value && formik.errors.value) as boolean}
            />
          </div>

          <Button disabled={formik.isSubmitting} type="submit" className="mx-auto">
            Add
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
