import { create } from "zustand";

export interface ImageCropModalData {
  img?: File;
  for?: "eventBanner" | "profile" | "homePage" | "calendarBanner" | "homeBasicSection";
  callback?: (args?: any) => void;
}

interface ImageCropModal {
  data: ImageCropModalData;
  isOpen: boolean;
  onOpen: (data?: ImageCropModalData) => void;
  onClose: () => void;
}

export const useImageCropModal = create<ImageCropModal>((set) => ({
  data: {},
  isOpen: false,
  onOpen: (data = {}) => set({ data, isOpen: true }),
  onClose: () => set({ isOpen: false })
}));
