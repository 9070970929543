import { FileIcon, defaultStyles } from "react-file-icon";
import mime from "mime-types";
import { useMemo } from "react";

function FileIconComponent({ type }: { type: string }) {
  const extension = useMemo(() => mime.extension(type), [type]);

  const styles = useMemo(() => {
    const _defaultStyles: any = defaultStyles;
    return extension ? _defaultStyles[extension] : {};
  }, [extension]);

  return <FileIcon extension={extension || ""} {...styles} />;
}

export default FileIconComponent;
