"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { Button } from "@/components/ui/button";
import {
  CSSProperties,
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  CropperRef,
  CircleStencil,
  RectangleStencil,
  CropperFade,
  FixedCropper,
  ImageRestriction,
  FixedCropperRef,
  CropperState
} from "react-advanced-cropper";
import { v4 } from "uuid";
import IconContainer from "@/components/ui/icon-container";
import { IconFlipHorizontal, IconFlipVertical } from "@tabler/icons-react";
import { ArrowRotateLeft, ArrowRotateRight, Gallery } from "iconsax-react";
import { cn } from "@/lib/utils";
import { useRanger, Ranger } from "@tanstack/react-ranger";
import { getAbsoluteZoom, getZoomFactor } from "advanced-cropper/extensions/absolute-zoom";
import { convertHeicToJpeg } from "@/lib/image";
import { useImageCropModal } from "@/stores/use-image-crop-store";

const ImageCropModalV2 = () => {
  const { isOpen, onClose, data } = useImageCropModal();
  const { callback, img } = data;

  const cropperFor = data.for ? data.for : "profile";

  const isModalOpen = isOpen;

  const [image, setImage] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);

  const ref = useRef<FixedCropperRef>(null);

  const handleClose = useCallback(() => {
    setIsLoading(false);
    isLoadingRef.current = false;
    onClose();
    if (image) URL.revokeObjectURL(image);
    setImage(null);
  }, [image, onClose]);

  useEffect(() => {
    if (isOpen && img) {
      if (img.type === "image/heic") {
        setIsLoading(true);
        convertHeicToJpeg(img)
          .then((blob) => {
            if (blob) setImage(URL.createObjectURL(blob));
            else handleClose();
          })
          .catch(() => handleClose())
          .finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
        setImage(URL.createObjectURL(img));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, img]);

  const selectImg = useCallback(() => {
    if (!ref.current || !isModalOpen || isLoadingRef.current) return;
    isLoadingRef.current = true;
    setIsLoading(true);
    ref.current.getCanvas()?.toBlob((blob) => {
      if (!blob) return;
      const file = new File([blob], v4(), { type: "image/png" });
      callback?.(file);
      handleClose();
    }, "image/png");
  }, [callback, isModalOpen, handleClose]);

  const cropComp = useMemo(
    () => ({
      profile: {
        aspectRatio: 1 / 1,
        comp: RectangleStencil
      },
      eventBanner: {
        aspectRatio: 4 / 3,
        comp: RectangleStencil
      },
      homePage: {
        aspectRatio: 2.67 / 1,
        comp: RectangleStencil
      },
      homeBasicSection: {
        aspectRatio: 2 / 1,
        comp: RectangleStencil
      },
      calendarBanner: {
        aspectRatio: 4 / 3,
        comp: RectangleStencil
      }
    }),
    []
  );

  const stencilSize = useCallback(
    ({ boundary }: CropperState) => ({
      width: Math.max(boundary.height, boundary.width) - 48,
      height: Math.max(boundary.height, boundary.width) - 48
    }),
    []
  );

  const defaultSize = useCallback(
    ({ imageSize }: CropperState) => ({
      width: Math.max(imageSize.height, imageSize.width),
      height: Math.max(imageSize.height, imageSize.width)
    }),
    []
  );

  const sizeAlgorithm = useCallback(
    (boundary: HTMLElement) => ({ width: boundary.clientWidth, height: boundary.clientHeight }),
    []
  );

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className=" lg:w-[50%] w-[80%] p-4"
        style={{
          height: "calc(100dvh - 90px)"
        }}
      >
        <DialogHeader className="sticky">
          <DialogTitle>Crop Image</DialogTitle>
        </DialogHeader>

        {/* max-h-[450px] */}
        <div className="max-w-full overflow-hidden">
          <FixedCropper
            ref={ref}
            src={image}
            stencilComponent={cropComp[cropperFor]?.comp ?? CircleStencil}
            stencilProps={{
              aspectRatio: cropComp[cropperFor]?.aspectRatio ?? 1 / 1,
              // grid: true,
              handlers: false,
              // lines: false,
              // movable: true,
              // resizable: false,
              // scalable: true

              movable: false,
              scalable: false
              // lines: {},
              // handlers: {}
            }}
            // transformImage={{ adjustStencil: false }}
            // minWidth={250}
            // minHeight={250}
            // imageRestriction={ImageRestriction.fillArea}
            className="cropper rounded-sm !bg-white w-full overflow-hidden"
            wrapperComponent={CustomWrapper}
            stencilSize={stencilSize}
            defaultSize={defaultSize}
            imageRestriction={ImageRestriction.stencil}
            transitions
            boundaryProps={{ sizeAlgorithm }}
          />
        </div>

        <div className="flex justify-center gap-2">
          <Button
            type="button"
            onClick={handleClose}
            disabled={isLoading}
            variant="secondary"
            className="w-32"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={selectImg}
            disabled={isLoading}
            loading={isLoading}
            className="w-32"
            loaderProps={{ size: "xs" }}
          >
            Apply
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface Props {
  cropper: CropperRef;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const CustomWrapper: FC<Props> = ({ cropper, children, className }) => {
  const rangerRef = useRef<HTMLDivElement>(null);

  const state = cropper.getState();
  const settings = cropper.getSettings();
  const zoom = getAbsoluteZoom(state, settings);

  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    values: [zoom],
    min: 0,
    max: 1,
    stepSize: 0.1,
    onDrag: (instance: Ranger<HTMLDivElement>) => onZoom(instance.sortedValues[0])
  });

  const onZoom = (value: number, transitions?: boolean) => {
    cropper.zoomImage(getZoomFactor(state, settings, value), { transitions: !!transitions });
  };

  const flip = (horizontal: boolean, vertical: boolean) => {
    cropper?.flipImage(horizontal, vertical);
  };

  const rotate = (angle: number) => {
    cropper?.rotateImage(angle);
  };

  return (
    <Fragment>
      <CropperFade
        className={cn("custom-wrapper !max-h-[550px]", className)}
        visible={cropper.isLoaded()}
      >
        {children}
      </CropperFade>

      <div className="p-5 flex flex-col">
        <div className="flex flex-wrap justify-center gap-2">
          <IconContainer
            onClick={() => flip(true, false)}
            variant="group"
            size="medium"
            type="button"
            className="rounded-sm shadow-none"
            icon={<IconFlipVertical size={15} />}
          />
          <IconContainer
            onClick={() => flip(false, true)}
            variant="group"
            size="medium"
            type="button"
            className="rounded-sm shadow-none"
            icon={<IconFlipHorizontal size={15} />}
          />
          <IconContainer
            onClick={() => rotate(90)}
            size="medium"
            type="button"
            className="rounded-sm shadow-none"
            icon={<ArrowRotateRight size={15} />}
          />
          <IconContainer
            onClick={() => rotate(-90)}
            size="medium"
            type="button"
            className="rounded-sm shadow-none"
            icon={<ArrowRotateLeft size={15} />}
          />
        </div>

        <div className="flex items-center gap-4 mt-4">
          <Gallery className="w-4 h-4" />

          <div
            ref={rangerRef}
            className="bg-black bg-opacity-50 relative select-none h-1 rounded-8 w-full"
          >
            {rangerInstance.getSteps().map(({ left, width }, i) => (
              <Segment key={i} index={i} left={left} width={width} />
            ))}
            {rangerInstance
              .handles()
              .map(({ value, onKeyDownHandler, onMouseDownHandler, onTouchStart, isActive }, i) => (
                <button
                  key={i}
                  onKeyDown={onKeyDownHandler}
                  onMouseDown={onMouseDownHandler}
                  onTouchStart={onTouchStart}
                  role="slider"
                  aria-valuemin={rangerInstance.options.min}
                  aria-valuemax={rangerInstance.options.max}
                  aria-valuenow={value}
                  className="absolute top-1/2 w-6 h-6 outline-none rounded-full bg-brand-600 -translate-x-1/2 -translate-y-1/2"
                  style={{
                    left: `${rangerInstance.getPercentageForValue(value)}%`,
                    zIndex: isActive ? "1" : "0"
                  }}
                />
              ))}
          </div>
          <Gallery className="w-6 h-6" />
        </div>
      </div>
    </Fragment>
  );
};

const Segment = ({ index, left, width }: { index: number; left: number; width: number }) => {
  return (
    <div
      className={cn("absolute h-full rounded-8", index === 0 ? "bg-brand-600" : "")}
      style={{ left: `${left}%`, width: `${width}%` }}
    />
  );
};

export default ImageCropModalV2;
