"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useParams } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import clientApi from "@/api-client/client";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ApiResponse } from "@/types/api";
import { Fragment, useEffect } from "react";
import { Textarea } from "@/components/ui/textarea";
import IconContainer from "@/components/ui/icon-container";
import { Trash } from "iconsax-react";
import Divider from "@/components/ui/divider";
import { get } from "lodash";
import { toast } from "sonner";

const Schema = yup.object({
  faqs: yup.array().of(
    yup.object().shape({
      title: yup.string().required("This field is required."),
      description: yup.string().required("This field is required.")
    })
  )
});

interface FaqFormInterface {
  faqs: {
    title: string;
    description: string;
  }[];
}

const defaultFaq: any = {
  title: "",
  description: ""
};

export const UpdateFaqsModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const params = useParams<{ domain: string }>();
  const { faqs, callback } = data;

  const isModalOpen = isOpen && type === "updateFaqs";

  const formik = useFormik<FaqFormInterface>({
    initialValues: {
      faqs: []
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { message } = await clientApi
          .post(`api/v1/${params.domain}/faqs`, { json: values })
          .json<ApiResponse>();

        toast.success(message);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (faqs && isOpen) formik.setFieldValue("faqs", faqs);
  }, [faqs, isOpen]);

  const handleClose = () => {
    formik.resetForm();
    callback?.(formik.values.faqs);
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4 max-h-[90%]">
        <DialogHeader className="sticky">
          <DialogTitle>Accordian Section</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <FormikProvider value={formik}>
            <FieldArray
              name="faqs"
              render={(helpers) => {
                return (
                  <Fragment>
                    <div className="flex items-center">
                      <Button
                        className="ms-auto"
                        type="button"
                        onClick={() => helpers.unshift(defaultFaq)}
                      >
                        Add FAQ
                      </Button>
                    </div>

                    {formik.values.faqs.map((faq, index) => {
                      const error = get(formik.errors, `faqs[${index}].title`);
                      const touched = get(formik.touched, `faqs[${index}].title`);

                      const _error = get(formik.errors, `faqs[${index}].description`);
                      const _touched = get(formik.touched, `faqs[${index}].description`);

                      return (
                        <Fragment key={index}>
                          <div className="mt-3">
                            <div className="flex items-start">
                              <div className="me-3 w-full">
                                <Input
                                  placeholder="Title"
                                  fullWidth
                                  onChange={formik.handleChange}
                                  value={faq.title}
                                  name={`faqs[${index}].title`}
                                  error={(touched && error) as boolean}
                                  errorMessage={error}
                                  errorOutline={(touched && error) as boolean}
                                />

                                <Textarea
                                  placeholder="Description"
                                  className="w-full mt-3"
                                  onChange={formik.handleChange}
                                  value={faq.description}
                                  name={`faqs[${index}].description`}
                                  error={(_touched && _error) as boolean}
                                  errorMessage={_error}
                                  errorOutline={(_touched && _error) as boolean}
                                />
                              </div>

                              <IconContainer onClick={() => helpers.remove(index)}>
                                <Trash size={20} />
                              </IconContainer>
                            </div>
                          </div>
                          {index !== formik.values.faqs.length - 1 ? <Divider /> : null}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              }}
            />
          </FormikProvider>

          <Button
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            type="submit"
            loaderProps={{ size: "xs" }}
          >
            Save
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
