import { Location as OriginalLocation } from "@/types/cusom-types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface Location extends OriginalLocation {
  miles: number | string;
}

export interface LocationStore {
  location: Location | null;
  setLocation: (location: Location) => void;
  removeLocation: () => void;
}

export const useLocation = create(
  persist<any>(
    (set) => ({
      location: null,
      setLocation: (location: Location) => set({ location }),
      removeLocation: () => set({ location: null })
    }),
    // {
    //   name: "location-storage", // unique name for the item in storage
    //   getStorage: () => localStorage // (optional) by default the 'localStorage' is used
    // }
    {
      name: "key.location", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage) // (optional) by default, 'localStorage' is used
    }
  )
);
