import React, { useCallback, useState } from "react";
import { useCSVReader } from "react-papaparse";
import Box from "@/components/ui/box";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import Multiline from "@/components/ui/multiline";
import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import { CloseCircle, DocumentCode, DocumentDownload, SearchNormal1 } from "iconsax-react";
import Avatar from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import IconContainer from "@/components/ui/icon-container";
import { EMAIL_REGEX } from "@key.ai/constants";
import { toast } from "sonner";

const CSVUpload = ({ emails, setEmails, role }: any) => {
  const { CSVReader } = useCSVReader();

  const sampleFile = useCallback(() => {
    const data = [["Email"], ["user1@example.com"], ["user2@example.com"]];
    const csvContent = data.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "bulk_user_invite_sample.csv";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }, []);

  const handleReadData = ({ data }: any) => {
    if ((data[0][0] || "").toLowerCase().includes("email")) {
      const emails = data
        .map((col: string[]) => col[0])
        .filter((email: string) => EMAIL_REGEX.test(email.trim()))
        .map((email: string) => {
          return { email: email.trim(), selected: true, role: role };
        });
      if (emails.length) {
        setEmails(emails);
        toast.success("CSV Imported");
      } else {
        toast.error("Please upload a file containing valid email addresses.");
      }
    } else {
      toast.error("Please upload a valid file, refer to the sample file for guidance.");
    }
  };

  return (
    <div>
      <Box className=" mt-xl">
        <CSVReader
          onUploadAccepted={(results: any) => {
            handleReadData(results);
          }}
        >
          {({ getRootProps, acceptedFile }: any) => (
            <div {...getRootProps()}>
              <Multiline
                icon={<IconContainer size="default" as="div" icon={<DocumentCode />} />}
                titleText={<Text variant={"body_two"}>Import CSV File</Text>}
                text1={"Drop file or click here to choose"}
                titleTextSize={"medium"}
                text1Size={"small"}
                className="bg-temp-container rounded-medium px-m"
              />
            </div>
          )}
        </CSVReader>

        <div className="flex gap-s items-center justify-center mt-m" onClick={sampleFile}>
          <DocumentDownload size="16" />
          <Text className="text-brand-600" variant={"body_two"}>
            Download Sample CSV
          </Text>
        </div>
      </Box>
    </div>
  );
};

export default CSVUpload;
