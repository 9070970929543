import { useMemo, useState } from "react";
import { Conversation } from "@/types/entities";
import { useMembersQuery } from "@/hooks/use-members-query";
import { MemberImage, MemberName } from "@/components/member";
import { useParams } from "next/navigation";
import Select, {
  ControlProps,
  MenuListProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OnChangeValue,
  OptionProps,
  PlaceholderProps,
  ValueContainerProps,
  components
} from "react-select";
import { Badge } from "./ui/badge";
import { cn } from "@/lib/utils";
import { CloseCircle } from "iconsax-react";
import { Text } from "./ui/text";
import { CheckIcon } from "lucide-react";
import { UserImage, UserName } from "./user";
import { useUsersQuery } from "@/hooks/use-users-query";

type MemberOption = {
  id: string;
  value: string;
  label: string;
  userId: string;
  inConversation?: boolean;
};

export default function MemberDropdown({
  conversation,
  className,
  onChange,
  dropDownType,
  defaultMenuIsOpen,
  maxMenuHeight
}: {
  conversation?: Conversation;
  className?: string;
  onChange: (values: string[]) => void;
  dropDownType?: "user" | "member";
  defaultMenuIsOpen?: boolean;
  maxMenuHeight?: number | undefined;
}) {
  if (dropDownType === "user")
    return (
      <UsersList
        conversation={conversation}
        className={className}
        onChange={onChange}
        defaultMenuIsOpen={defaultMenuIsOpen}
        maxMenuHeight={maxMenuHeight}
      />
    );

  return (
    <MembersList
      conversation={conversation}
      className={className}
      onChange={onChange}
      defaultMenuIsOpen={defaultMenuIsOpen}
      maxMenuHeight={maxMenuHeight}
    />
  );
}

const MembersList = ({
  conversation,
  className,
  onChange,
  defaultMenuIsOpen,
  maxMenuHeight
}: {
  conversation?: Conversation;
  className?: string;
  onChange: (values: string[]) => void;
  defaultMenuIsOpen?: boolean;
  maxMenuHeight?: number | undefined;
}) => {
  const { domain } = useParams<{ domain: string }>();

  const [value, setValue] = useState<OnChangeValue<MemberOption, true>>([]);

  const [search, setSearch] = useState("");

  const { data, isFetchingNextPage, isFetching } = useMembersQuery({
    search,
    conversationId: conversation?.id,
    serverId: domain
  });

  const onSelectChange = (values: OnChangeValue<MemberOption, true>) => {
    setValue(values);
    onChange(values.map((v) => v.userId));
  };

  const options = useMemo(() => {
    if (data?.pages[0].items)
      return data?.pages[0].items.map((m) => ({
        id: m.id,
        value: m.id,
        userId: m.userId,
        label: `${m.firstName} ${m.lastName} ${m?.email ?? ""} @${m.firstName} @${m.lastName} @${m.firstName} ${m.lastName}`,
        inConversation: m.inConversation
      }));
    return [];
  }, [data]);

  const isLoading = useMemo(
    () => isFetchingNextPage || isFetching,
    [isFetchingNextPage, isFetching]
  );

  return (
    <Select
      isMulti
      unstyled
      // styles={{
      //   control: (base) => ({ ...base, padding: "0 20px" })
      // }}
      // menuIsOpen
      defaultMenuIsOpen={defaultMenuIsOpen}
      className={cn(" rounded-lg", className)}
      components={{
        DropdownIndicator: () => null,
        Option: SelectOption,
        Menu: CustomMenu,
        MultiValueContainer: MultiValueContainer,
        ValueContainer: ValueContainer,
        MultiValue: MultiValue,
        MultiValueRemove: MultiValueRemove,
        MenuList: MenuList,
        Placeholder: Placeholder,
        Control: Control
      }}
      isClearable={false}
      value={value}
      onChange={onSelectChange}
      isOptionDisabled={(option) => {
        if (option && option.inConversation) return true;
        return false;
      }}
      placeholder="@somebody"
      options={options}
      inputValue={search}
      isLoading={isLoading}
      hideSelectedOptions={false}
      onInputChange={setSearch}
      noOptionsMessage={() =>
        "No members found. Invite more friends to join our awesome community!"
      }
      autoFocus
      maxMenuHeight={maxMenuHeight}
    />
  );
};

const UsersList = ({
  conversation,
  className,
  onChange,
  defaultMenuIsOpen,
  maxMenuHeight
}: {
  conversation?: Conversation;
  className?: string;
  onChange: (values: string[]) => void;
  defaultMenuIsOpen?: boolean;
  maxMenuHeight?: number | undefined;
}) => {
  const { domain } = useParams<{ domain: string }>();

  const [value, setValue] = useState<OnChangeValue<MemberOption, true>>([]);

  const [search, setSearch] = useState("");

  const { data, isFetchingNextPage, isFetching } = useUsersQuery({
    search,
    conversationId: conversation?.id,
    serverId: domain
  });

  const onSelectChange = (values: OnChangeValue<MemberOption, true>) => {
    setValue(values);
    onChange(values.map((v) => v.userId));
  };

  const options = useMemo(() => {
    if (data?.pages[0].items)
      return data?.pages[0].items.map((m) => ({
        id: m.userId,
        value: m.userId,
        userId: m.userId,
        label: `${m.firstName} ${m.lastName} ${m?.email ?? ""} @${m.firstName} @${m.lastName} @${m.firstName} ${m.lastName}`,
        inConversation: m.inConversation
      }));
    return [];
  }, [data]);

  const isLoading = useMemo(
    () => isFetchingNextPage || isFetching,
    [isFetchingNextPage, isFetching]
  );

  return (
    <Select
      isMulti
      unstyled
      // styles={{
      //   control: (base) => ({ ...base, padding: "0 20px" })
      // }}
      // menuIsOpen
      defaultMenuIsOpen={defaultMenuIsOpen}
      className={cn(" rounded-lg", className)}
      components={{
        DropdownIndicator: () => null,
        Option: SelectOptionUser,
        Menu: CustomMenu,
        MultiValueContainer: MultiValueContainer,
        ValueContainer: ValueContainer,
        MultiValue: MultiValueUser,
        MultiValueRemove: MultiValueRemove,
        MenuList: MenuList,
        Placeholder: Placeholder,
        Control: Control
      }}
      isClearable={false}
      value={value}
      onChange={onSelectChange}
      isOptionDisabled={(option) => {
        if (option && option.inConversation) return true;
        return false;
      }}
      placeholder="@somebody"
      options={options}
      inputValue={search}
      isLoading={isLoading}
      hideSelectedOptions={false}
      onInputChange={setSearch}
      noOptionsMessage={() => "No users found."}
      autoFocus={true}
      maxMenuHeight={maxMenuHeight}
    />
  );
};

const Control = ({ ...props }: ControlProps<MemberOption>) => {
  return <components.Control {...props} className="px-2 py-0 !min-h-[2.375rem]" />;
};

const MultiValueContainer = ({ children, ...props }: MultiValueGenericProps<MemberOption>) => {
  return (
    <components.MultiValueContainer {...props}>
      <Badge className={cn("px-1 !bg-brand-50")}>{children}</Badge>
    </components.MultiValueContainer>
  );
};

const MultiValueRemove = ({ children, ...props }: MultiValueRemoveProps<MemberOption>) => {
  return (
    <components.MultiValueRemove {...props}>
      <div className="ps-1 pe-[0.5px]">
        <CloseCircle size={15} className="text-black" />
      </div>
    </components.MultiValueRemove>
  );
};

const MultiValue = ({ children, data, ...props }: MultiValueProps<MemberOption>) => {
  return (
    <components.MultiValue {...props} data={data}>
      <div className="flex items-center gap-2 text-black">
        <MemberImage memberId={data.id} size={24} />
        <div className="flex items-center gap-2 text-black">
          <MemberName memberId={data.id} />
        </div>
      </div>
    </components.MultiValue>
  );
};

const MultiValueUser = ({ children, data, ...props }: MultiValueProps<MemberOption>) => {
  return (
    <components.MultiValue {...props} data={data}>
      <div className="flex items-center gap-2 text-black">
        <UserImage userId={data.id} size={24} />
        <div className="flex items-center gap-2 text-black">
          <UserName userId={data.id} />
        </div>
      </div>
    </components.MultiValue>
  );
};

const SelectOption = ({ children, data, ...props }: OptionProps<MemberOption>) => {
  return (
    <components.Option data={data} {...props}>
      <div
        className={cn(
          "flex items-center gap-2 w-full cursor-pointer",
          props.isDisabled && "opacity-50 cursor-not-allowed"
        )}
      >
        {props.isSelected ? <CheckIcon size={15} /> : null}
        <MemberImage memberId={data.id} size={32} />
        <div className="flex items-center gap-2 w-full">
          <Text variant="caption_one">
            <MemberName memberId={data.id} />
          </Text>
          {data.inConversation ? (
            <Text variant="caption_one" className="ms-auto text-gray-600">
              Already in this channel
            </Text>
          ) : null}
        </div>
      </div>
    </components.Option>
  );
};

const SelectOptionUser = ({ children, data, ...props }: OptionProps<MemberOption>) => {
  return (
    <components.Option data={data} {...props}>
      <div
        className={cn(
          "flex items-center gap-2 w-full cursor-pointer",
          props.isDisabled && "opacity-50 cursor-not-allowed"
        )}
      >
        {props.isSelected ? <CheckIcon size={15} /> : null}
        <UserImage userId={data.id} size={32} />
        <div className="flex items-center gap-2 w-full">
          <Text variant="caption_one">
            <UserName userId={data.id} />
          </Text>
          {data.inConversation ? (
            <Text variant="caption_one" className="ms-auto text-gray-600">
              Already in this conversation
            </Text>
          ) : null}
        </div>
      </div>
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<MemberOption>) => {
  return (
    <components.ValueContainer {...props} className="gap-1">
      {children}
    </components.ValueContainer>
  );
};

const CustomMenu = ({ children, ...props }: MenuProps<MemberOption>) => {
  return (
    <components.Menu {...props} className="bg-white backdrop-blur-20 p-2 rounded-xs shadow-blur-sm">
      {children}
    </components.Menu>
  );
};

const Placeholder = ({ children, ...props }: PlaceholderProps<MemberOption>) => {
  return (
    <components.Placeholder {...props} className="text-sm">
      {children}
    </components.Placeholder>
  );
};

const MenuList = ({ children, ...props }: MenuListProps<MemberOption>) => {
  return (
    <components.MenuList {...props} className="flex flex-col gap-2 z-[9999]">
      {children}
    </components.MenuList>
  );
};
