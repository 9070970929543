"use client";

import { Fragment, useEffect, useState } from "react";

// import { EditServerModal } from "@/components/modals/edit-server-modal";
import { InviteModal } from "@/modals/invite-modal";
import { CreateChannelModal } from "@/modals/create-channel-modal";
// import { EditChannelModal } from "@/components/modals/edit-channel-modal";
import { CreateChannelCategoryModal } from "@/modals/create-channel-category-modal";
import { EditChannelCategoryModal } from "@/modals/edit-channel-category-modal";
// import { CreateThreadModal } from "@/components/modals/create-thread-modal";
// import { EditThreadModal } from "@/components/modals/edit-thread-modal";
// import { EditCommentModal } from "@/components/modals/edit-comment-modal";
// import { IntroductionModal } from "@/components/modals/introduction-modal";
// import { ChannelInfoModal } from "@/components/modals/channel-info-modal";
// import { ChannelDescriptionModal } from "@/components/modals/channel-description-modal";

import { ConfirmationModal } from "@/modals/confirmation-modal";
// import { EditProfileModal } from "@/components/modals/edit-profile-modal";
import { EditMessageModal } from "@/modals/edit-message-modal";
import { UpdateFaqsModal } from "@/modals/update-faq-modal";
import { ConversationAddMemberModal } from "@/modals/conversation-add-member-modal";
import { GuestInviteModal } from "@/modals/invite-guest-modal";
import { ImageModal } from "@/modals/img-modal";
import LocationModal from "@/modals/location-modal";
import { QlLinkModal } from "@/modals/ql-link-modal";
import { EditThreadModal } from "@/modals/edit-thread-modal";
import { RejectMemberModal } from "@/modals/reject-member-modal";

import { AvatarModal } from "@/modals/avatar-modal";
import { ToggleListModal } from "@/modals/toggle-list-modal";
import { PhotoGalleryModal } from "@/modals/photo-gallery-modal";
import { ExternalLinkModal } from "@/modals/external-link-modal";
import { CTALinkModal } from "@/modals/cta-link-modal";
import { CardShowcaseModal } from "@/modals/card-showcase-modal";
import { ShareModal } from "@/modals/share-modal";
import StripeConnect from "@/modals/stripe-connect-modal";
import StripePaymentModal from "@/modals/stripe-payment-modal";
import { GenerateWithAiModal } from "@/modals/generate-with-ai-modal";
import EventPasscodeModal from "@/modals/event-passcode-modal";
import { VideoModal } from "@/modals/video-modal";
import dynamic from "next/dynamic";
import CancelGuestRegistration from "@/modals/cancel-registration-modal";
import { DeleteAccountModal } from "@/modals/delete-account-modal";
import SubmitEventToCalendar from "@/modals/submit-event-to-calendar";
import { LoaderModal } from "@/modals/loader-modal";
import { useModal } from "@/stores/use-modal-store";
import { ImageSelectorModal } from "@/modals/image-selector-modal";
import ImageCropModalV2 from "@/modals/image-crop-modal-v2";
import { useImageCropModal } from "@/stores/use-image-crop-store";

const ImageCropModal = dynamic(() => import("@/modals/image-crop-modal"), { ssr: false });

export const ModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false);

  const { type } = useModal();
  const { isOpen: imageCropOpen } = useImageCropModal();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <InviteModal />
      <LoaderModal />
      {/* 
      <EditServerModal />
      
      <EditChannelModal />
      
      
      <CreateThreadModal />
      <EditThreadModal />
      <EditCommentModal />
      <IntroductionModal />
      <ChannelInfoModal />
      <ChannelDescriptionModal />
      
      <EditProfileModal />
      
      
      <GuidelineModal /> */}
      <ImageSelectorModal />
      <GenerateWithAiModal />
      <ToggleListModal />
      <PhotoGalleryModal />
      <ExternalLinkModal />
      <CTALinkModal />
      <CardShowcaseModal />
      <ShareModal />

      <AvatarModal />
      <ImageCropModal />
      <RejectMemberModal />
      <QlLinkModal />
      <GuestInviteModal />
      {type === "location" ? <LocationModal /> : null}
      <EditMessageModal />
      <ConfirmationModal />
      <ImageModal />
      <ConversationAddMemberModal />
      <UpdateFaqsModal />
      <EditChannelCategoryModal />
      <CreateChannelCategoryModal />
      <CreateChannelModal />
      <EditThreadModal />
      <StripeConnect />
      <StripePaymentModal />
      <EventPasscodeModal />
      <CancelGuestRegistration />
      {imageCropOpen ? <ImageCropModalV2 /> : null}
      <SubmitEventToCalendar />

      <VideoModal />
      <DeleteAccountModal />
    </Fragment>
  );
};
