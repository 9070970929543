import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@/types/api";
import authClientApi from "@/api-client/auth-client";
import { Avatar } from "@/types/auth/avatar.entity";
import { AvatarType } from "@/types";
import { getQueryKey } from "@key.ai/constants";

export const useAvatarQuery = ({ type }: { type: AvatarType }) => {
  const fetchAvatars = async () => {
    const { data } = await authClientApi
      .get(`api/v1/auth/avatars`, {
        searchParams: { type }
      })
      .json<ApiResponse<Avatar[]>>();
    return data;
  };

  return useQuery<Avatar[]>({
    queryKey: [getQueryKey("AVATARS"), type],
    queryFn: fetchAvatars,
    staleTime: Infinity
  });
};
