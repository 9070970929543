import { useQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { useParams } from "next/navigation";
import { InviteCode } from "@/types/entities";
import { getQueryKey } from "@key.ai/constants";
import { useSession } from "next-auth/react";

export const useInviteCodeQuery = () => {
  const { data: session } = useSession();
  const { domain } = useParams<{ domain: string }>();
  const queryKey = getQueryKey("INVITE_CODE", { serverId: domain });

  const fetchInviteCode = async () => {
    const { data } = await clientApi
      .get(`api/v1/${domain}/invite-code`)
      .json<ApiResponse<InviteCode>>();
    return data;
  };

  return useQuery<InviteCode>({
    queryKey: [queryKey],
    queryFn: fetchInviteCode,
    enabled: !!domain && !!session
  });
};
