import { InfiniteData, QueryClient } from "@tanstack/react-query";
import { Pinned } from "@/types/entities";
import { ApiResponsePagination, ReactionRes } from "@/types/api";
import { useMessage as msgStore } from "@/stores/use-message-store";
import { ConversationType, ReactionType } from "@key.ai/enum";
import { getQueryKey } from "@key.ai/constants";
import { IConversation, IMessage } from "@/types/cusom-types";
import { User } from "@/types/user.entity";
import { ChatType } from "@/types";

interface QueryReactionPayload {
  reactions: ReactionRes;
  id: string;
  type: ReactionType;
}

export const queryUpsertMessage = (
  queryClient: QueryClient,
  queryKey: string,
  payload: any,
  upsert: boolean
) => {
  const user = queryClient.getQueryData<User>([getQueryKey("USER_INFO", { userId: "me" })]);

  const isOwn = user && user.id === payload.userId;

  queryClient.setQueryData(
    [queryKey],
    (oldData: InfiniteData<ApiResponsePagination<IMessage[]>>) => {
      if (!oldData) {
        // const conversations = queryClient.getQueryData<
        //   InfiniteData<ApiPaginationWithTotal<Conversation[]>>
        // >([getQueryKey("PERSONAL_CONVERSATIONS")]);
        // const _conversations = conversations?.pages.flatMap(
        //   (page) => page.items
        // );
        // if (
        //   _conversations &&
        //   !_conversations.find((c) => c.id === payload.conversationId)
        // ) {
        //   queryClient.refetchQueries({
        //     queryKey: [getQueryKey("PERSONAL_CONVERSATIONS")],
        //   });
        // }
        return oldData;
      }

      let hasMessge = false;

      payload.isRead = isOwn;

      const newData = oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) => {
          if (item.clientMessageId === payload.clientMessageId) {
            hasMessge = true;
            return { ...item, ...payload };
          }
          return item;
        })
      }));

      if (!hasMessge && upsert) {
        const index = oldData.pages.length - 1;
        newData[index] = {
          ...newData[index],
          items: [...newData[index].items, payload]
        };
      }

      return { ...oldData, pages: newData };
    }
  );

  const store = msgStore.getState();
  if (upsert && store.active !== payload.conversationId && !isOwn) {
    store.setUnreadCounts(
      payload.conversationType === ConversationType.CHANNEL
        ? ChatType.Channels
        : ChatType.Conversations,
      payload.conversationId
    );

    store.setMessages(payload.conversationId);
  }

  store.setUpdate(payload.conversationId);
};

export const queryDeleteMessage = (
  queryClient: QueryClient,
  queryKey: string,
  message: IMessage
) => {
  // UPDATE ALL CACHED DATA
  queryClient.setQueriesData(
    { queryKey: [queryKey] },
    (oldData: InfiniteData<ApiResponsePagination<IMessage[]>> | undefined) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        pages: oldData.pages.map((page) => {
          const items = page.items.filter(
            ({ clientMessageId }) => clientMessageId !== message.clientMessageId
          );
          return { ...page, items };
        })
      };
    }
  );

  // queryClient.setQueryData(
  //   [queryKey],
  //   (oldData: InfiniteData<ApiResponsePagination<Message[]>>) => {
  //     if (!oldData || !oldData.pages || oldData.pages.length === 0)
  //       return oldData;
  //     return {
  //       ...oldData,
  //       pages: oldData.pages.map((page) => {
  //         const items = page.items.filter(
  //           ({ clientMessageId }) => clientMessageId !== message.clientMessageId
  //         );
  //         return { ...page, items };
  //       }),
  //     };
  //   }
  // );

  // REMOVE MESSAGE IN CACHED DATA
  queryClient.setQueryData(
    [getQueryKey("PINNED", { conversationId: message.conversationId })],
    (oldData: InfiniteData<ApiResponsePagination<Pinned[]>>) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        pages: oldData.pages.map((page) => {
          const items = page.items.filter(({ messageId }) => messageId !== message.id);
          return { ...page, items };
        })
      };
    }
  );
};

export const queryReaction = (
  queryClient: QueryClient,
  { id, reactions, type }: QueryReactionPayload,
  conversationId?: string
) => {
  queryClient.setQueryData([getQueryKey("REACTIONS", { id, type })], (oldData: ReactionRes[]) => {
    if (!oldData) return oldData;
    return reactions;
  });
  if (conversationId) {
    const store = msgStore.getState();
    store.setUpdate(conversationId);
  }
};

export const queryUpdateMessage = (
  queryClient: QueryClient,
  queryKey: string,
  message: IMessage
) => {
  queryClient.setQueriesData(
    { queryKey: [queryKey] },
    (messages: InfiniteData<ApiResponsePagination<IMessage[]>> | undefined) => {
      if (!messages) return messages;
      return {
        ...messages,
        pages: messages.pages.map((page) => ({
          ...page,
          items: page.items.map((item) =>
            item.id === message.id ? { ...message, isRead: item.isRead } : item
          )
        }))
      };
    }
  );

  // UPDATE SINGLE MSG IF AVAILABLE
  queryClient.setQueryData(
    [getQueryKey("CHAT_MESSAGE", { id: message.id })],
    (oldData: IMessage) => {
      if (!oldData) return oldData;
      return message;
    }
  );
};
interface PinnedPayload {
  pin: Pinned;
  isPinned: boolean;
}
export const queryUpdatePinned = (queryClient: QueryClient, { pin }: PinnedPayload) => {
  queryClient.refetchQueries({
    queryKey: [getQueryKey("PINNED", { conversationId: pin.conversationId })]
  });
};

// export const queryUpdateReadStatus = (
//   queryClient: QueryClient,
//   queryKey: string,
//   pIndex: number,
//   mIndex: number
// ) => {
//   queryClient.setQueryData(
//     [queryKey],
//     (oldData: InfiniteData<ApiResponsePagination<IMessage[]>>) => {
//       if (!oldData) return oldData;
//       const msg = oldData?.pages[pIndex]?.items[mIndex];
//       if (msg && (typeof msg.isRead === "undefined" || msg.isRead === false))
//         oldData.pages[pIndex].items[mIndex] = { ...msg, isRead: true };

//       return oldData;
//     }
//   );
// };

export const queryUpdateConversationRead = (
  queryClient: QueryClient,
  queryKey: string,
  conversation: IConversation
) => {
  queryClient.setQueryData(
    [queryKey],
    (conversations: InfiniteData<ApiResponsePagination<IConversation[]>> | undefined) => {
      if (!conversations) return conversations;
      return {
        ...conversations,
        pages: conversations.pages.map((page) => ({
          ...page,
          items: page.items.map((item) =>
            item.id === conversation.id && (item.unreads || 0) > 0 ? { ...item, unreads: 0 } : item
          )
        }))
      };
    }
  );
};

export const queryUpdateConversationMsgRead = (queryClient: QueryClient, queryKey: string) => {
  queryClient.setQueryData(
    [queryKey],
    (messages: InfiniteData<ApiResponsePagination<IMessage[]>> | undefined) => {
      if (!messages) return messages;
      const data = {
        ...messages,
        pages: messages.pages.map((page) => ({
          ...page,
          items: page.items.map((item) => {
            if (typeof item.isRead === "undefined" || item.isRead === false) {
              return { ...item, isRead: true };
            }
            return item;
          })
        }))
      };
      return data;
    }
  );
};
