import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse, ApiResponsePagination } from "@/types/api";

import { getQueryKey } from "@key.ai/constants";
import { cleanObject } from "@/lib/helper";
import { Comment } from "@/types/entities";
import eventClientApi from "@/api-client/event-client";
import { getSession } from "next-auth/react";

export const useCalendarEvents = ({
  calendarId = "",
  tag = "",
  current_epoc
}: {
  calendarId: string;
  tag: string;
  current_epoc: number;
}) => {
  const queryKey = getQueryKey("CALENDAR_EVENTS", {
    calendarId: calendarId
  });

  const fetchCalendars = async ({ pageParam = 1 }: { pageParam: unknown }) => {
    const session = await getSession();
    const { data } = await eventClientApi
      .get(`api/v2/calendar${session?.accessToken ? "" : "/proxy"}/${calendarId}/calendar_events`, {
        searchParams: cleanObject({
          page: pageParam as string,
          limit: 10,
          sort: "start_epoc:ASC",
          current_epoc: current_epoc,
          tag: tag
        })
      })
      .json<ApiResponse<any>>();

    return { ...data, pageParam };
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading, refetch } =
    useInfiniteQuery<ApiResponsePagination<any>>({
      queryKey: [queryKey, calendarId, tag],
      queryFn: fetchCalendars,
      getNextPageParam: (lastPage: any) => {
        return lastPage?.pageParam + 1;
      },
      initialPageParam: 1,
      placeholderData: keepPreviousData,
      refetchOnMount: true,
      refetchInterval: false,
      refetchOnWindowFocus: true
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isLoading,
    refetch
  };
};
