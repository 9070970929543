import { create } from "zustand";

export interface ParticipantsInviteStore {
  participants: [];
  setParticipants: (participants: any) => void;
  showPaticipantsBar: boolean;
  setShowPaticipantsBar: (showPaticipantsBar: boolean) => void;
}

export const usePaticipantsInvite = create<ParticipantsInviteStore>((set) => ({
  participants: [],
  setParticipants: (participants: any) => set({ participants }),
  showPaticipantsBar: true,
  setShowPaticipantsBar: (showPaticipantsBar: any) => set({ showPaticipantsBar })
}));
