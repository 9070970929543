import React, { forwardRef } from "react";
import Tippy, { TippyProps } from "@tippyjs/react/headless";

type TooltipProps = {
  children: React.ReactElement;
} & Partial<TippyProps>;

export const DatepickerTooltip = forwardRef<HTMLElement, TooltipProps>(
  ({ children, ...props }, ref) => {
    return (
      <Tippy
        theme="key"
        // className="!bg-white shadow-blur-subtle-sm"
        {...props}
      >
        {React.cloneElement(children, { ref })}
      </Tippy>
    );
  }
);

DatepickerTooltip.displayName = "DatepickerTooltip";
