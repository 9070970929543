"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collections = void 0;
exports.collections = {
    messages: "messages",
    attachments: "attachments",
    conversationMembers: "conversation_members",
    comments: "comments",
    conversations: "conversations",
    members: "members",
    threads: "threads",
    reactions: "reactions",
    channels: "channels",
    notifications: "notifications"
};
