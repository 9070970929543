import React, { useEffect, useMemo, useState } from "react";
import { TickSquare, Ticket, UserSquare } from "iconsax-react";
import eventClientApi from "@/api-client/event-client";
import {
  generateTimeOptions,
  getStartTimeOpions,
  getendTimeOpions,
  prepareGetProxyAliasURL
} from "@/lib/utils";
import { Input } from "@/components/ui/input";
import SectionHeader from "@/components/ui/section-header";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/text";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "sonner";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import dayjs from "dayjs";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { useTimezoneSelect } from "react-timezone-select";
import { CAL_ROLES, getQueryKey, sampleTicket } from "@key.ai/constants";
import { useRouter } from "next/navigation";
import PlaceSearchBoxForModal from "../common/placesSearchForModal";
import { useModal } from "@/stores/use-modal-store";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import useGoogleMaps from "@/hooks/use-google-maps";
import { useCalendarEvents } from "@/hooks/use-calendar-event-query";

interface NewEventViewProps {
  onBack: () => void;
  closePopup?: () => void;
  calendarId?: string;
  alias?: string;
  calendarDetails?: any;
}

const NewEventView: React.FC<NewEventViewProps> = ({
  onBack,
  calendarId,
  alias,
  calendarDetails
}) => {
  const { isOpen, onClose, type, data } = useModal();

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    start_epoc: Yup.number().required("Start epoch is required"),
    end_epoc: Yup.number().required("End epoch is required")
  });

  const [sDateModal, setSDateModal] = React.useState(false);
  const { parseTimezone } = useTimezoneSelect({});

  const router = useRouter();
  const [startTimeOptions, setStartTimeOptions] = useState(
    generateTimeOptions(0 * 60, 24 * 60 - 15, 30)
  );
  const [endTimeOptions, setEndTimeOptions] = useState(
    generateTimeOptions(0 * 60, 24 * 60 - 15, 30)
  );

  const [initalValues, setInitialValues] = useState<any>({
    eventName: "",
    eventDescription: "",
    startTime: "",
    endTime: "",
    start_epoc: "",
    end_epoc: "",
    startDate: new Date(),
    endDate: new Date(),
    time_zone: parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone).value,
    attend_type: "",
    attend_type_details: {
      virtual_address: "",
      in_person_address: ""
    },
    location: {
      sublocality: "",
      locality: "",
      administrative_area_level_3: "",
      administrative_area_level_2: "",
      administrative_area_level_1: "",
      country: "",
      latLng: {
        lat: 0,
        lng: 0
      },
      placeId: "",
      description: ""
    },
    is_all_day: false,
    privateEvent: false,
    tbdLocation: false,
    tbdDate: false,
    require_approval: false,
    alias: "",
    monetary_type_details: [],
    currentTicket: sampleTicket,
    isAliasAvailable: false,
    calendar_id: "",
    image_map: {
      "500*250": "https://dev-images.bprnt.com/events/bae2fd47-1908-412e-a167-71fa0f167370.jpg"
    }
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      start_epoc: 1622854230,
      end_epoc: 1622854230,
      startDate: new Date(),
      endDate: new Date(),
      time_zone: "Asia/Calcutta",
      host: {},
      type: "internal",
      event_id: "bb32d9c1-8e69-47fe-a736-fdf14e8052ff",
      event_url: "",
      image_map: {
        "500*250": "https://dev-images.bprnt.com/event_banners/design1.jpeg"
      },
      location: {
        sublocality: "",
        locality: "",
        administrative_area_level_3: "",
        administrative_area_level_2: "",
        administrative_area_level_1: "",
        country: "",
        latLng: {
          lat: 0,
          lng: 0
        },
        placeId: "",
        description: ""
      },
      attend_type_details: {
        virtual_address: "",
        in_person_address: ""
      },
      startTime: "",
      endTime: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      const midnightStart = values.start_epoc;
      const targetTimeStart = values.end_epoc;
      // const unixTimestamp = targetTimeStart.unix();

      // const targetTimeEnd = targetTimeStart.add(1, "hour");
      // const unixTimestampEnd = targetTimeEnd.unix();
      // const data = eventData;

      localStorage.setItem(
        "eventData",
        JSON.stringify({
          ...initalValues,
          name: values.name,
          monetary_type_details: [],
          startDate: values.startDate,
          endDate: values.startDate,
          end_epoc: values.end_epoc,
          start_epoc: values.start_epoc,
          startTime: values.startTime,
          endTime: values.endTime,
          calendar_id: calendarId,
          submitEventToCalendar: true,
          alias: alias,
          isCalAdmin:
            calendarDetails?.subscribed?.role === CAL_ROLES.ADMIN &&
            !calendarDetails?.subscribed?.is_deleted
        })
      );

      router.push("/events/create");
      onClose();
      try {
      } catch (error) {
        toast.error(error?.message);
      }
    }
  });

  useEffect(() => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      formik.values.startDate,
      30,
      formik.values.startTime
    );
    setStartTimeOptions(startTimeOptions);
  }, []);

  const handleEnd = (startDate: any, endDate: any, startTime: any) => {
    let endTimeMinutes = parseInt(startTime) + 60;
    // logic for handling edge case of 11 and 11.30 PM time
    if (endTimeMinutes >= 24 * 60) {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + 1);
      formik.setFieldValue("endDate", newEndDate);
      // endTimeMinutes -= 24 * 60; // This adjusts the endTime to wrap around to the next day
      const { endTime, endTimeOptions } = getendTimeOpions(
        startDate,
        newEndDate,
        startTime,
        formik.values.endTime,
        30
      );
      setEndTimeOptions(endTimeOptions);
      formik.setFieldValue("endTime", endTime);
      // const { endDate } = formik.values;
      const midnightEnd = dayjs(newEndDate).startOf("day");
      const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
      const unixTimestampEnd = targetTimeEnd.unix();
      formik.setFieldValue("end_epoc", unixTimestampEnd);
      return;
    }
    const { endTime, endTimeOptions } = getendTimeOpions(
      startDate,
      endDate,
      startTime,
      formik.values.endTime,
      30
    );
    setEndTimeOptions(endTimeOptions);
    formik.setFieldValue("endTime", endTime);
    // const { endDate } = formik.values;
    const midnightEnd = dayjs(endDate).startOf("day");
    const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
    const unixTimestampEnd = targetTimeEnd.unix();
    formik.setFieldValue("end_epoc", unixTimestampEnd);
  };

  const handleEnd1 = (startDate: any, endDate: any, startTime: any, eTimeValue: any) => {
    let endTimeMinutes = parseInt(startTime) + 60;
    // logic for handling edge case of 11 and 11.30 PM time
    if (endTimeMinutes >= 24 * 60) {
      const newEndDate = new Date(formik.values.startDate);
      newEndDate.setDate(formik.values.startDate.getDate() + 1);
      formik.setFieldValue("endDate", newEndDate);
      // endTimeMinutes -= 24 * 60; // This adjusts the endTime to wrap around to the next day
      const { endTime, endTimeOptions } = getendTimeOpions(
        startDate,
        endDate,
        startTime,
        formik.values.endTime,
        30
      );
      setEndTimeOptions(endTimeOptions);
      formik.setFieldValue("endTime", eTimeValue);
      // const { endDate } = formik.values;
      const midnightEnd = dayjs(newEndDate).startOf("day");
      const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
      const unixTimestampEnd = targetTimeEnd.unix();
      formik.setFieldValue("end_epoc", unixTimestampEnd);
      return;
    }
    const { endTime, endTimeOptions } = getendTimeOpions(
      startDate,
      endDate,
      startTime,
      eTimeValue,
      30
    );
    setEndTimeOptions(endTimeOptions);
    formik.setFieldValue("endTime", endTime);
    // const { endDate } = formik.values;
    const midnightEnd = dayjs(endDate).startOf("day");
    const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
    const unixTimestampEnd = targetTimeEnd.unix();
    formik.setFieldValue("end_epoc", unixTimestampEnd);
  };

  const handleEnd2 = (startDate: any, endDate: any, startTime: any, eTimeValue: any) => {
    let endTimeMinutes = parseInt(startTime) + 60;
    // logic for handling edge case of 11 and 11.30 PM time
    const { endTime, endTimeOptions } = getendTimeOpions(
      startDate,
      endDate,
      startTime,
      eTimeValue,
      30
    );
    setEndTimeOptions(endTimeOptions);
    formik.setFieldValue("endTime", eTimeValue);
    // const { endDate } = formik.values;
    const midnightEnd = dayjs(endDate).startOf("day");
    const targetTimeEnd = midnightEnd.add(Number(eTimeValue), "minute");
    const unixTimestampEnd = targetTimeEnd.unix();
    formik.setFieldValue("end_epoc", unixTimestampEnd);
  };

  const handleStart = (startDate: any, sTimeValue: any) => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      startDate,
      30,
      sTimeValue ? sTimeValue : formik.values.startTime
    );
    setStartTimeOptions(startTimeOptions);
    formik.setFieldValue("startTime", startTime);
    // formik.setFieldValue("startTime", startTime);
    // const { startDate } = formik.values;
    const midnightStart = dayjs(startDate).startOf("day");
    const targetTimeStart = midnightStart.add(Number(startTime), "minute");
    const unixTimestampStart = targetTimeStart.unix();
    formik.setFieldValue("start_epoc", unixTimestampStart);
  };
  const handleStart1 = (startDate: any, sTimeValue: any) => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      startDate,
      30,
      sTimeValue ? sTimeValue : formik.values.startTime
    );
    setStartTimeOptions(startTimeOptions);
    formik.setFieldValue("startTime", sTimeValue);
    // const { startDate } = formik.values;
    const midnightStart = dayjs(startDate).startOf("day");
    const targetTimeStart = midnightStart.add(Number(sTimeValue), "minute");
    const unixTimestampStart = targetTimeStart.unix();
    formik.setFieldValue("start_epoc", unixTimestampStart);
    handleEnd1(formik.values.startDate, formik.values.endDate, sTimeValue, formik.values.endTime);
  };

  useEffect(() => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      formik.values.startDate,
      30,
      formik.values.startTime
    );

    const { endTime, endTimeOptions } = getendTimeOpions(
      formik.values.startDate,
      formik.values.endDate,
      formik.values.startTime,
      formik.values.endTime,
      30
    );

    handleStart(new Date(), "");
    handleEnd(new Date(), new Date(), startTime);
  }, []);

  return (
    <div className=" rounded-lg">
      <SectionHeader titleText={"Event Name"} className="text-sm"></SectionHeader>
      <Input
        name="name"
        className="text-sm"
        placeholder="Enter Event name"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={Boolean(formik.errors.name && formik.touched.name)}
        errorOutline={Boolean(formik.errors.name && formik.touched.name)}
        errorMessage={formik.errors.name}
      ></Input>

      <div className="flex justify-between ">
        <div className="w-full">
          <SectionHeader titleText={"Start Date"} className="text-sm"></SectionHeader>
          <Popover open={sDateModal} onOpenChange={setSDateModal}>
            <PopoverTrigger className="w-full">
              <Input
                value={dayjs(formik.values.startDate).format("ddd, MMM DD, YYYY")}
                fullWidth
                className="text-sm"
              ></Input>
            </PopoverTrigger>
            <PopoverContent className="w-fit">
              <Calendar
                mode="single"
                name="startDate"
                selected={formik.values.startDate}
                onSelect={(e: string) => {
                  if (!e) return;
                  formik.setFieldValue("startDate", e);
                  // if (dayjs(formik.values.endDate).isBefore(dayjs(e))) {
                  //   formik.setFieldValue("endDate", e);
                  // }
                  if (dayjs(formik.values.endDate).isBefore(dayjs(e))) {
                    formik.setFieldValue("endDate", e);
                    handleStart(e, formik.values.startTime);
                    handleEnd(e, e, formik.values.startTime);
                  } else {
                    handleStart(e, formik.values.startTime);
                    handleEnd(e, formik.values.endDate, formik.values.startTime);
                  }
                  setSDateModal(false);
                }}
                disableDay={new Date()}
              />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div className="flex justify-between ">
        <div className="w-full mr-m-nudge">
          <SectionHeader titleText={"Start Time"} className="text-sm"></SectionHeader>
          <Select
            value={formik?.values?.startTime?.toString()}
            onValueChange={(e) => {
              handleStart1(formik.values.startDate, e);
              // formik.setFieldValue("startTime", e);
              // formik.setFieldValue("start_epoc", dayjs(e).unix());
            }}
          >
            <SelectTrigger showChevrolate={false} className="w-full">
              <SelectValue placeholder="Select Time" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {startTimeOptions &&
                  startTimeOptions.length > 0 &&
                  startTimeOptions.map((data) => (
                    <SelectItem value={data.value} key={data.value}>
                      {data.label}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="w-full">
          <SectionHeader titleText={"End Time"} className="text-sm"></SectionHeader>
          <Select
            defaultValue={formik?.values?.endTime?.toString()}
            value={formik?.values?.endTime?.toString()}
            onValueChange={(e) =>
              handleEnd2(formik.values.startDate, formik.values.endDate, formik.values.startTime, e)
            }
          >
            <SelectTrigger
              showChevrolate={false}
              className="w-full  font-normal  backdrop-blur-none "
            >
              <SelectValue placeholder="Select Time" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {endTimeOptions &&
                  endTimeOptions.length > 0 &&
                  endTimeOptions.map((data) => (
                    <SelectItem value={data.value} key={data.value}>
                      {data.label}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      <Button variant={"primary"} onClick={() => formik.handleSubmit()} className="mx-auto mt-l">
        Create Event
      </Button>
    </div>
  );
};

interface ExistingEventViewProps {
  onBack: () => void;
  calendarId?: string;
  closePopup?: () => void;
}

const ExistingEventView: React.FC<ExistingEventViewProps> = ({ onBack, calendarId }) => {
  if (!calendarId) return <>Lonading</>;
  const { isOpen, onClose, type, data } = useModal();
  const queryClient = useQueryClient();

  const Schema = Yup.object().shape({
    url: Yup.string().required("URL is required").url("It must be a valid URL")
  });

  const formik = useFormik({
    initialValues: { url: "" },
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      const { url } = values;
      const slug = url.split("/").pop();
      await fetchProxyEventData(slug || "", "");
    }
  });

  const [eventEpocs, setEventEpocs] = useState<any>({
    startEpoc: dayjs(new Date()).startOf("day").unix(),
    endEpoc: dayjs(new Date()).startOf("day").unix()
  });
  const {
   
    refetch: refectchEvents
  } = useCalendarEvents({
    calendarId: calendarId,
    tag: "",
    current_epoc: eventEpocs.startEpoc
  });

  const submitToCalendar = async (event: any, calendarId: string | undefined) => {
    try {
      const payload = {
        name: event.name,
        start_epoc: event.start_epoc,
        end_epoc: event.end_epoc,
        time_zone: event.time_zone,
        host: {},
        type: "internal",
        event_id: event.id,
        image_map: {
          "500*250": "https://dev-images.bprnt.com/event_banners/design1.jpeg"
        }
      };
      const response = await eventClientApi
        .post(`api/v2/calendar/${calendarId}/calendar_events`, { json: { ...payload } })
        .json<any>();
      toast.success("Event Added to Calendar!");
      onClose();
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("CALENDAR_EVENTS"), { calendarId }]
        // exact: true // Optional based on whether you want to strictly match this key
      });
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("CALENDAR_EVENTS_LOCATION"), { calendarId }]
        // exact: true // Optional based on whether you want to strictly match this key
      });
      refectchEvents();
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const fetchProxyEventData = async (alias: string, passcode: string) => {
    try {
      const { data } = await eventClientApi
        .get(prepareGetProxyAliasURL(alias, passcode))
        .json<any>();
      if (data?.passcode_required) {
        // handle passcode required case
      } else {
        const { event } = data || {};
        if (event.id) {
          submitToCalendar(event, calendarId);
        }
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleHttps = () => {
    if (formik.values.url && !formik.values.url.includes("https://")) {
      const url = `https://${formik.values.url}`;
      formik.setFieldValue("url", url);
    }
  };

  return (
    <div className="">
      <SectionHeader
        titleText={<Text variant={"subtitle_two_strong"}>Add Existing Event</Text>}
      ></SectionHeader>
      <div className="mb-4">
        <div className="flex items-center">
          <Input
            value={formik.values.url}
            placeholder="Enter Existing Event URL"
            name="url"
            className="text-sm"
            fullWidth
            onChange={formik.handleChange}
            onBlur={handleHttps}
            error={Boolean(formik.errors.url && formik.touched.url)}
            errorOutline={Boolean(formik.errors.url && formik.touched.url)}
            errorMessage={formik.errors.url}
          ></Input>
        </div>
      </div>
      <Button
        variant={"primary"}
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting}
        onClick={() => formik.handleSubmit()}
        className="mx-auto"
      >
        Submit Event
      </Button>
    </div>
  );
};

interface ExternalEventViewProps {
  onBack: () => void;
  calendarId?: string;
  closePopup?: any;
}

const ExternalEventView: React.FC<ExternalEventViewProps> = ({
  onBack,
  calendarId,
  closePopup
}) => {
  if (!calendarId) return <>Lonading</>;
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    start_epoc: Yup.number().required("Start epoch is required"),
    end_epoc: Yup.number().required("End epoch is required"),
    event_url: Yup.string().required("Event URL is required").url("It must be a valid URL"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    image_map: Yup.object({
      "500*250": Yup.string().url("Must be a valid URL").required("Image URL is required")
    }).required("Image map is required"),
    location: Yup.object().shape({
      sublocality: Yup.string().nullable(),
      locality: Yup.string().nullable(),
      administrative_area_level_3: Yup.string().nullable(),
      administrative_area_level_2: Yup.string().nullable(),
      administrative_area_level_1: Yup.string().nullable(),
      country: Yup.string().nullable(),
      latLng: Yup.object().shape({
        lat: Yup.number().nullable(),
        lng: Yup.number().nullable()
      }),
      placeId: Yup.string().nullable(),
      description: Yup.string().nullable(),
      meetingLink: Yup.string().nullable()
    })
  });

  const [sDateModal, setSDateModal] = useState(false);
  const [eDateModal, setEDateModal] = useState(false);
  const { parseTimezone } = useTimezoneSelect({});
  const [startTimeOptions, setStartTimeOptions] = useState(
    generateTimeOptions(0 * 60, 24 * 60 - 15, 30)
  );
  const [endTimeOptions, setEndTimeOptions] = useState(
    generateTimeOptions(0 * 60, 24 * 60 - 15, 30)
  );

  const queryClient = useQueryClient();

  const [eventEpocs, setEventEpocs] = useState<any>({
    startEpoc: dayjs(new Date()).startOf("day").unix(),
    endEpoc: dayjs(new Date()).startOf("day").unix()
  });
  const {
   
    refetch: refectchEvents
  } = useCalendarEvents({
    calendarId: calendarId,
    tag: "",
    current_epoc: eventEpocs.startEpoc
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      start_epoc: 1622854230,
      end_epoc: 1622854230,
      startDate: new Date(),
      endDate: new Date(),
      time_zone: "Asia/Calcutta",
      host: {},
      type: "internal",
      event_id: "bb32d9c1-8e69-47fe-a736-fdf14e8052ff",
      event_url: "",
      image_map: {
        "500*250": "https://dev-images.bprnt.com/event_banners/design1.jpeg"
      },
      location: {
        sublocality: "",
        locality: "",
        administrative_area_level_3: "",
        administrative_area_level_2: "",
        administrative_area_level_1: "",
        country: "",
        latLng: {
          lat: 0,
          lng: 0
        },
        placeId: "",
        description: ""
      },
      attend_type_details: {
        virtual_address: "",
        in_person_address: ""
      },
      startTime: "",
      endTime: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      let payload = {
        name: values.name,
        type: "external",
        event_url: values.event_url,
        start_epoc: values.start_epoc,
        end_epoc: values.end_epoc,
        time_zone: parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone).value,
        image_map: {
          "500*250": "https://dev-images.bprnt.com/event_banners/design1.jpeg"
        },
        location: {
          sublocality: values.location.sublocality,
          locality: values.location.locality,
          administrative_area_level_3: values.location.administrative_area_level_3,
          administrative_area_level_2: values.location.administrative_area_level_2,
          administrative_area_level_1: values.location.administrative_area_level_1,
          country: values.location.country,
          latLng: {
            lat: values.location.latLng.lat,
            lng: values.location.latLng.lng
          },
          placeId: values.location.placeId
        }
      };
      try {
        const response = await eventClientApi
          .post(`api/v2/calendar/${calendarId}/calendar_events`, { json: { ...payload } })
          .json<any>();
        closePopup();
        queryClient.invalidateQueries({
          queryKey: [getQueryKey("CALENDAR_EVENTS"), { calendarId }]
        });
        queryClient.invalidateQueries({
          queryKey: [getQueryKey("CALENDAR_EVENTS_LOCATION"), { calendarId }]
        });
        refectchEvents();
        toast.success("Event Added to Calendar!");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  });

  const handleLocation = (location: any, description: any) => {
    formik.setFieldValue("location", { ...location, description: description });
  };

  const handleEnd = (startDate: any, endDate: any, startTime: any) => {
    let endTimeMinutes = parseInt(startTime) + 60;
    // logic for handling edge case of 11 and 11.30 PM time
    if (endTimeMinutes >= 24 * 60) {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + 1);
      formik.setFieldValue("endDate", newEndDate);
      // endTimeMinutes -= 24 * 60; // This adjusts the endTime to wrap around to the next day
      const { endTime, endTimeOptions } = getendTimeOpions(
        startDate,
        newEndDate,
        startTime,
        formik.values.endTime,
        30
      );
      setEndTimeOptions(endTimeOptions);
      formik.setFieldValue("endTime", endTime);
      // const { endDate } = formik.values;
      const midnightEnd = dayjs(newEndDate).startOf("day");
      const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
      const unixTimestampEnd = targetTimeEnd.unix();
      formik.setFieldValue("end_epoc", unixTimestampEnd);
      return;
    }
    const { endTime, endTimeOptions } = getendTimeOpions(
      startDate,
      endDate,
      startTime,
      formik.values.endTime,
      30
    );
    setEndTimeOptions(endTimeOptions);
    formik.setFieldValue("endTime", endTime);
    // const { endDate } = formik.values;
    const midnightEnd = dayjs(endDate).startOf("day");
    const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
    const unixTimestampEnd = targetTimeEnd.unix();
    formik.setFieldValue("end_epoc", unixTimestampEnd);
  };

  const handleEnd1 = (startDate: any, endDate: any, startTime: any, eTimeValue: any) => {
    let endTimeMinutes = parseInt(startTime) + 60;
    // logic for handling edge case of 11 and 11.30 PM time
    if (endTimeMinutes >= 24 * 60) {
      const newEndDate = new Date(formik.values.startDate);
      newEndDate.setDate(formik.values.startDate.getDate() + 1);
      formik.setFieldValue("endDate", newEndDate);
      // endTimeMinutes -= 24 * 60; // This adjusts the endTime to wrap around to the next day
      const { endTime, endTimeOptions } = getendTimeOpions(
        startDate,
        endDate,
        startTime,
        formik.values.endTime,
        30
      );
      setEndTimeOptions(endTimeOptions);
      formik.setFieldValue("endTime", eTimeValue);
      // const { endDate } = formik.values;
      const midnightEnd = dayjs(newEndDate).startOf("day");
      const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
      const unixTimestampEnd = targetTimeEnd.unix();
      formik.setFieldValue("end_epoc", unixTimestampEnd);
      return;
    }
    const { endTime, endTimeOptions } = getendTimeOpions(
      startDate,
      endDate,
      startTime,
      eTimeValue,
      30
    );
    setEndTimeOptions(endTimeOptions);
    formik.setFieldValue("endTime", endTime);
    // const { endDate } = formik.values;
    const midnightEnd = dayjs(endDate).startOf("day");
    const targetTimeEnd = midnightEnd.add(Number(endTime), "minute");
    const unixTimestampEnd = targetTimeEnd.unix();
    formik.setFieldValue("end_epoc", unixTimestampEnd);
  };

  const handleEnd2 = (startDate: any, endDate: any, startTime: any, eTimeValue: any) => {
    let endTimeMinutes = parseInt(startTime) + 60;
    // logic for handling edge case of 11 and 11.30 PM time
    const { endTime, endTimeOptions } = getendTimeOpions(
      startDate,
      endDate,
      startTime,
      eTimeValue,
      30
    );
    setEndTimeOptions(endTimeOptions);
    formik.setFieldValue("endTime", eTimeValue);
    // const { endDate } = formik.values;
    const midnightEnd = dayjs(endDate).startOf("day");
    const targetTimeEnd = midnightEnd.add(Number(eTimeValue), "minute");
    const unixTimestampEnd = targetTimeEnd.unix();
    formik.setFieldValue("end_epoc", unixTimestampEnd);
  };

  const handleStart = (startDate: any, sTimeValue: any) => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      startDate,
      30,
      sTimeValue ? sTimeValue : formik.values.startTime
    );
    setStartTimeOptions(startTimeOptions);
    formik.setFieldValue("startTime", startTime);
    // formik.setFieldValue("startTime", startTime);
    // const { startDate } = formik.values;
    const midnightStart = dayjs(startDate).startOf("day");
    const targetTimeStart = midnightStart.add(Number(startTime), "minute");
    const unixTimestampStart = targetTimeStart.unix();
    formik.setFieldValue("start_epoc", unixTimestampStart);
  };
  const handleStart1 = (startDate: any, sTimeValue: any) => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      startDate,
      30,
      sTimeValue ? sTimeValue : formik.values.startTime
    );
    setStartTimeOptions(startTimeOptions);
    formik.setFieldValue("startTime", sTimeValue);
    // const { startDate } = formik.values;
    const midnightStart = dayjs(startDate).startOf("day");
    const targetTimeStart = midnightStart.add(Number(sTimeValue), "minute");
    const unixTimestampStart = targetTimeStart.unix();
    formik.setFieldValue("start_epoc", unixTimestampStart);
    handleEnd1(formik.values.startDate, formik.values.endDate, sTimeValue, formik.values.endTime);
  };

  useEffect(() => {
    const { startTime, startTimeOptions } = getStartTimeOpions(
      formik.values.startDate,
      30,
      formik.values.startTime
    );

    const { endTime, endTimeOptions } = getendTimeOpions(
      formik.values.startDate,
      formik.values.endDate,
      formik.values.startTime,
      formik.values.endTime,
      30
    );

    handleStart(new Date(), "");
    handleEnd(new Date(), new Date(), startTime);
  }, []);

  return (
    <div className="rounded-lg">
      <SectionHeader className="text-sm" titleText="Event Name" />
      <Input
        name="name"
        placeholder="Enter Event name"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={Boolean(formik.errors.name && formik.touched.name)}
        errorOutline={Boolean(formik.errors.name && formik.touched.name)}
        errorMessage={formik.errors.name}
      />
      <SectionHeader className="text-sm" titleText={"Event URL"}></SectionHeader>
      <Input
        name="event_url"
        placeholder="Enter Event URL"
        className="text-sm"
        onChange={formik.handleChange}
        value={formik.values.event_url}
        error={Boolean(formik.errors.event_url && formik.touched.event_url)}
        errorOutline={Boolean(formik.errors.event_url && formik.touched.event_url)}
        errorMessage={formik.errors.event_url}
      ></Input>
      <SectionHeader className="text-sm" titleText={"Event Location"}></SectionHeader>
      <PlaceSearchBoxForModal
        onSelectPlace={handleLocation}
        description={formik.values.location?.description}
        selectedLocation={formik.values.location}
      />
      <div className="flex justify-between gap-3 w-full">
        <div className="w-full">
          <SectionHeader className="text-sm" titleText={"Start Date"}></SectionHeader>
          <Popover open={sDateModal} onOpenChange={setSDateModal}>
            <PopoverTrigger className="w-full">
              <Input
                fullWidth
                className="text-sm  "
                value={dayjs(formik.values.startDate).format("ddd, MMM DD, YYYY")}
              ></Input>
            </PopoverTrigger>
            <PopoverContent className="w-fit">
              <Calendar
                mode="single"
                name="startDate"
                selected={formik.values.startDate}
                onSelect={(e: string) => {
                  if (!e) return;
                  formik.setFieldValue("startDate", e);
                  // if (dayjs(formik.values.endDate).isBefore(dayjs(e))) {
                  //   formik.setFieldValue("endDate", e);
                  // }
                  if (dayjs(formik.values.endDate).isBefore(dayjs(e))) {
                    formik.setFieldValue("endDate", e);
                    handleStart(e, formik.values.startTime);
                    handleEnd(e, e, formik.values.startTime);
                  } else {
                    handleStart(e, formik.values.startTime);
                    handleEnd(e, formik.values.endDate, formik.values.startTime);
                  }
                  setSDateModal(false);
                }}
                disableDay={new Date()}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full">
          <SectionHeader className="text-sm" titleText={"Start Time"}></SectionHeader>
          <Select
            value={formik?.values?.startTime?.toString()}
            onValueChange={(e) => {
              handleStart1(formik.values.startDate, e);
              // formik.setFieldValue("startTime", e);
              // formik.setFieldValue("start_epoc", dayjs(e).unix());
            }}
          >
            <SelectTrigger
              showChevrolate={false}
              className="w-full font-normal  backdrop-blur-none"
            >
              <SelectValue placeholder="Select Time" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {startTimeOptions &&
                  startTimeOptions.length > 0 &&
                  startTimeOptions.map((data) => (
                    <SelectItem value={data.value} key={data.value}>
                      {data.label}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="flex justify-between gap-3 w-full">
        <div className="w-full">
          <SectionHeader className="text-sm" titleText={"End Date"}></SectionHeader>
          <Popover open={eDateModal} onOpenChange={setEDateModal}>
            <PopoverTrigger className="w-full">
              <Input
                fullWidth
                value={dayjs(formik.values.endDate).format("ddd, MMM DD, YYYY")}
                className="text-sm"
              ></Input>
            </PopoverTrigger>
            <PopoverContent className="w-fit">
              <Calendar
                mode="single"
                selected={formik.values.endDate}
                onSelect={(e: string) => {
                  if (!e) return;
                  // formik.setFieldValue("endDate", e);
                  // formik.setFieldValue("end_epoc", dayjs(e).unix());
                  formik.setFieldValue("endDate", e);
                  handleEnd(formik.values.startDate, e, formik.values.startTime);
                  setEDateModal(false);
                }}
                disableDay={formik.values.startDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full">
          <SectionHeader className="text-sm" titleText={"End Time"}></SectionHeader>
          <Select
            defaultValue={formik?.values?.endTime?.toString()}
            value={formik?.values?.endTime?.toString()}
            onValueChange={(e) =>
              handleEnd2(formik.values.startDate, formik.values.endDate, formik.values.startTime, e)
            }
          >
            <SelectTrigger
              showChevrolate={false}
              className="w-full font-normal  backdrop-blur-none"
            >
              <SelectValue placeholder="Select time" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {endTimeOptions &&
                  endTimeOptions.length > 0 &&
                  endTimeOptions.map((data) => (
                    <SelectItem value={data.value} key={data.value}>
                      {data.label}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <Button
        variant="primary"
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting}
        onClick={() => formik.handleSubmit()}
        className="mx-auto mt-l"
      >
        Submit Event
      </Button>
    </div>
  );
};

interface SubmitEventProps {
  calendarId?: string;
  closePopup?: any;
  alias?: string;
  calendarDetails?: any;
}

const SubmitEvent: React.FC<SubmitEventProps> = ({
  calendarId,
  closePopup,
  alias,
  calendarDetails
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
  };

  const [activeTab, setActiveTab] = useState<string>("existing_event");

  const tabs = useMemo(
    () => [
      { label: "Existing Event", key: "existing_event" },
      { label: "New Event", key: "new_event" },
      { label: "External Event", key: "external_event" }
    ],
    []
  );

  return (
    <div className="rounded-sm">
      {/* <Tabs color="#0e297c" value={activeTab} onValueChange={(e) => setActiveTab(e)}>
        <TabsList>
          {tabs.map((data) => (
            <TabsTrigger value={data.key}>{data.label}</TabsTrigger>
          ))}
        </TabsList>
      </Tabs> */}
      <div>
        <Select
          value={activeTab}
          onValueChange={(e) => {
            setActiveTab(e);
          }}
        >
          <SelectTrigger size={"large"} className="text-sm font-normal  backdrop-blur-none">
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="shadow-subtle-sm">
            <SelectGroup>
              {tabs.map((option) => (
                <SelectItem value={option.key} key={option.key}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {activeTab === "new_event" && (
        <NewEventView
          onBack={() => setSelectedOption(null)}
          closePopup={closePopup}
          calendarId={calendarId}
          alias={alias}
          calendarDetails={calendarDetails}
        />
      )}
      {activeTab === "existing_event" && (
        <ExistingEventView
          onBack={() => setSelectedOption(null)}
          calendarId={calendarId}
          closePopup={closePopup}
        />
      )}
      {activeTab === "external_event" && (
        <ExternalEventView
          onBack={() => setSelectedOption(null)}
          calendarId={calendarId}
          closePopup={closePopup}
        />
      )}
    </div>
  );
};

export default SubmitEvent;
