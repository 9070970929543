import { Dispatch, Fragment, SetStateAction, useMemo, useRef, useState } from "react";
import Select, {
  ActionMeta,
  InputActionMeta,
  MultiValueGenericProps,
  OnChangeValue,
  SelectInstance,
  ValueContainerProps,
  components
} from "react-select";
import { Badge } from "./ui/badge";
import { cn } from "@/lib/utils";
import { isValidEmail } from "@key.ai/constants";
import { extractEmail } from "extract-email-address";

interface EmailOption {
  value: string;
  label: string;
}

const regex = /[\s,]$/;

export default function EmailDropdown({
  onChange,
  selected,
  search,
  setSearch
}: {
  selected: string[];
  onChange: (values: string[]) => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}) {
  const [prevSearch, setPrevSearch] = useState("");

  const ref = useRef<SelectInstance<EmailOption, true> | null>(null);

  const handleValueSelect = (val: string) => {
    const emails = extractEmail(val);
    const _emails = [...emails.map((e) => e.email), ...selected];
    const set = new Set(_emails);
    onChange([...Array.from(set)]);
    setSearch("");
    setPrevSearch("");
  };

  // const handleValueRemove = (val: string) => onChange(selected.filter((v) => v !== val));

  const onInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === "input-change") {
      if (regex.test(value)) {
        handleValueSelect(meta.prevInputValue);
      } else {
        setSearch(value);
      }

      setPrevSearch(meta.prevInputValue);
    }
  };

  const options = useMemo(() => {
    return selected.map((email) => ({ value: email, label: email }));
  }, [selected]);

  const value = useMemo(() => {
    return selected.map((email) => ({ value: email, label: email }));
  }, [selected]);

  const onSelectChange = (
    values: OnChangeValue<EmailOption, true>,
    meta: ActionMeta<EmailOption>
  ) => {
    onChange(values.map((v) => v.value));
  };

  return (
    <Fragment>
      <Select
        isMulti
        components={{
          DropdownIndicator: () => null,
          Option: () => null,
          NoOptionsMessage: () => null,
          Menu: () => null,
          MultiValueContainer,
          ValueContainer
        }}
        isClearable={false}
        unstyled
        // value={value}
        value={[]}
        onChange={onSelectChange}
        placeholder="Enter email address"
        options={options}
        inputValue={search}
        onInputChange={onInputChange}
        ref={ref}
        backspaceRemovesValue={false}
        onKeyDown={(event) => {
          if (event.key === "Tab" && search) {
            event.preventDefault();
            handleValueSelect(search);
          }

          if (event.key === "Space" && prevSearch) {
            event.preventDefault();
            handleValueSelect(prevSearch);
          }

          // if (event.key === "Backspace" && search.length === 0) {
          //   event.preventDefault();
          //   handleValueRemove(selected[selected.length - 1]);
          // }

          if (event.key === "Enter") {
            event.preventDefault();
            if (search) handleValueSelect(search);
          }
        }}
      />
    </Fragment>
  );
}

const ValueContainer = ({ children, ...props }: ValueContainerProps<EmailOption>) => {
  return (
    <components.ValueContainer {...props} className="gap-1">
      {children}
    </components.ValueContainer>
  );
};

const MultiValueContainer = ({ children, ...props }: MultiValueGenericProps<EmailOption>) => {
  return (
    <components.MultiValueContainer {...props}>
      <Badge className={cn("", !isValidEmail(props.data.value) && "bg-red-400")}>{children}</Badge>
    </components.MultiValueContainer>
  );
};
