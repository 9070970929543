"use client";

import { useModal } from "@/stores/use-modal-store";
import dynamic from "next/dynamic";
import { Fragment } from "react";
import EditorLoader from "@/components/editor-loader";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const MessageEditor = dynamic(() => import("@/components/editor/message-editor"), {
  ssr: false,
  loading: EditorLoader
});

export const EditThreadModal = () => {
  const { isOpen, onClose, type, data } = useModal();

  const { thread } = data;

  const isModalOpen = isOpen && type === "editThread";

  const handleClose = () => onClose();

  if (!thread) return <Fragment />;

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[750px] p-4 overflow-visible">
        <DialogHeader>
          <DialogTitle>Edit Thread</DialogTitle>
        </DialogHeader>
        <MessageEditor
          msg={thread}
          type="thread"
          query={{
            conversationId: thread!.conversationId,
            messageId: thread!.messageId,
            serverId: thread!.serverId
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
