"use client";

import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import { ArrowDown2, ArrowUp2, CloseCircle, Send2, Ticket } from "iconsax-react";
import { useParams } from "next/navigation";
import { Fragment, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useModal } from "@/stores/use-modal-store";
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import EmailInvite from "@/components/app/events/manage/email-invite";
import Box from "@/components/ui/box";
import { CollapsibleComponent } from "@/components/ui/collapse";
import Multiline from "@/components/ui/multiline";
import { Checkbox } from "@/components/ui/checkbox";
import Avatar from "@/components/ui/avatar";
import Divider from "@/components/ui/divider";
import GoogleContacts from "@/components/app/events/manage/google-contacts";
import PastEventGuest from "@/components/app/events/manage/past-invite-guest";
import Quill from "quill";
import _ from "lodash";
import { inviteEmail } from "@/types/cusom-types";
import eventClientApi from "@/api-client/event-client";
import dynamic from "next/dynamic";
import { Input } from "@/components/ui/input";
import { useParticipants } from "@/hooks/use-participants";
import { toast } from "sonner";
import { getRoleDisplayName } from "@/lib/utils";
import Group from "@/components/app/events/manage/group";
import { usePaticipantsInvite } from "@/stores/use-guest-invite-store";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";
import Cookies from "js-cookie";
import { clientServerIdKey, getQueryKey } from "@key.ai/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useEventStore } from "@/stores/use-event-store";
import SideDrawer from "@/components/ui/sidebar-drawer";
import CSVUpload from "@/components/app/events/manage/csv-upload";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { pluralize } from "@/lib/label";
import EventIcon from "@/public/static/images/icons/Events-selected.svg";
import Image from "next/image";
import { useSession } from "next-auth/react";
import { ScrollArea } from "@/components/ui/scroll-area";

const MailEditor = dynamic(() => import("@/components/editor/mail-editor"), {
  ssr: false
});

export const GuestInviteModal = ({ setOpenGuestInvite }: any) => {
  const [emails, setEmails] = useState<any>([]);

  const { showPaticipantsBar, setShowPaticipantsBar } = usePaticipantsInvite();

  const [quill, setQuill] = useState<Quill | null>(null);

  const [inviteLoading, setInviteLoading] = useState<boolean>(false);

  const [view, setView] = useState<string>("email");

  const [emailView, setEmailView] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { eventData } = useEventStore();

  const { data: userSession } = useSession();

  const { isOpen, onClose, type, data } = useModal();
  const { role }: any = data;

  const [subject, setSubject] = useState<string>(
    `Hi, ${"We"} invite you to join ${eventData?.name}.`
  );

  const community_id = Cookies.get(clientServerIdKey);

  const isModalOpen = isOpen && type === "inviteGuest";

  const handleTabChange = (value: string) => {
    setView(value);
    setShowPaticipantsBar(true);
  };

  useEffect(() => {
    if (eventData) {
      if (role === "guest") {
        setSubject(
          `You've been invited to attend ${eventData.name} by ${userSession?.user.firstName}`
        );
      } else {
        setSubject(
          `${userSession?.user.firstName} has invited you to be a ${getRoleDisplayName(role)} at ${eventData.name}!`
        );
      }
    }
    if (quill) {
      if (role === "guest") {
        quill.setText(
          `Great news! ${userSession?.user.firstName} has invited you to join ${eventData.name} 🥳`
        );
      } else {
        quill.setText(
          `Exciting news! ${userSession?.user.firstName} has invited you to be a ${getRoleDisplayName(role)} at ${eventData.name}! 🎉`
        );
      }
    }
  }, [eventData, quill, userSession, role]);

  const handleToggleEmailSelection = (emailToToggle: any) => {
    setEmails(
      emails.map((email: any) =>
        email.email === emailToToggle.email ? { ...email, selected: !email.selected } : email
      )
    );
  };

  const handleRemoveEmail = (emailToRemove: any) => {
    setEmails(emails.filter((email: any) => email.email !== emailToRemove.email));
  };

  const countSelectedEmails = () => {
    return emails.filter((email: any) => email.selected).length;
  };

  const handleRemoveAll = () => {
    setEmails([]);
  };

  const handleSendInvite = async () => {
    if (!quill) return;
    const description_html = quill.getSemanticHTML();
    setInviteLoading(true);
    try {
      const data = {
        community_id: community_id,
        email: {
          subject: subject,
          body: btoa(encodeURIComponent(description_html))
        },
        invitees: [
          {
            role: role,
            emails: emails
              .filter(({ selected }: { selected: boolean }) => selected)
              .map((email: any) => email.email)
          }
        ]
      };
      const response = await eventClientApi
        .post(`api/v2/events/${eventData?.id}/event-participations`, {
          json: { ...data }
        })
        .json<{ data: []; error_data: [] }>();

      await queryClient.refetchQueries({
        queryKey: [getQueryKey("EVENT_GUEST", { eventId: eventData?.id })]
      });

      handleClose();

      const successInvitationMessage = response.data?.length
        ? `${response?.data?.length / 2} ${pluralize("user", response?.data?.length / 2)} invited successfully.`
        : "Invitation processed successfully. No new invitation has been sent.";

      handleClose();
      toast.success(`${successInvitationMessage}`);
    } catch (error: any) {
      console.log(error);
    } finally {
      setInviteLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (emailView && quill && emails?.length) {
      return handleSendInvite();
    }
    setEmailView(true);
  };

  const handleCancel = async () => {
    if (!emailView) {
      return handleClose();
    }
    setEmailView(false);
  };

  const handleClose = async () => {
    setEmails([]);
    setEmailView(false);
    onClose();
    setShowPaticipantsBar(true);
  };

  const tabs = [

    {
      key: "email",
      title: "Email"
    },
    {
      key: "contact",
      title: "Contact"
    },
    {
      key: "pastEvents",
      title: "Past Events"
    },
    {
      key: "group",
      title: "Group"
    },
    {
      key: "csv",
      title: "CSV"
    }
  ];

  if (!isModalOpen) return;

  return (
    <SideDrawer
      isOpen={isModalOpen}
      onClose={() => onClose()}
      className="md:w-[60%] w-full lg:w-[40%]"
    >
      <div className="relative w-full h-full">
        <div className="flex flex-row mb-3">
          {!emailView
            ? tabs.map((section, index) => (
                <Button
                  key={index}
                  variant="text"
                  radius="none"
                  layerClassName="hover:bg-transparent"
                  onClick={() => handleTabChange(section.key)}
                  className={`h-fit ${view === section.key ? "border-b-1 border-black" : ""}`}
                >
                  {section.title}
                </Button>
              ))
            : null}
        </div>
        {emailView ? (
          <Fragment>
            <SectionHeader
              titleText={
                <Text variant={"caption_one"} className="text-color-secondary">
                  Subject
                </Text>
              }
            />
            <Input
              variant="medium-s"
              className="rounded-xs w-full px-xl"
              inputClassName="w-full "
              placeholder="Subject"
              value={subject}
              onChange={(e: any) => setSubject(e.target.value)}
            />
            <SectionHeader
              titleText={
                <Text variant={"caption_one"} className="text-color-secondary">
                  About Event
                </Text>
              }
            />
            <Box>
              <div className="">
                <MailEditor quill={quill} setQuill={setQuill} />
              </div>
            </Box>

            <Multiline
              className="bg-transparent !px-0 !mx-0 mt-xl"
              icon={<Image src={EventIcon} alt="events" className="w-[60px]" />}
              titleText={"We will send (guest/featured/host) an invite link"}
              text1={
                "Hosts & featured guest will automatically be added to the guest list upon accepting the invite. Guests will still have to register for the event."
              }
              titleTextSize={"medium"}
              text1Size={"small"}
            />
          </Fragment>
        ) : // : view === "contact" ? (
        //   <GoogleContacts emails={emails} setEmails={setEmails} role={role} />
        // )
        view === "email" ? (
          <EmailInvite emails={emails} setEmails={setEmails} role={role} />
        ) : view === "pastEvents" ? (
          <PastEventGuest emails={emails} setEmails={setEmails} role={role} />
        ) : view === "group" ? (
          <Group emails={emails} setEmails={setEmails} role={role} />
        ) : view === "csv" ? (
          <CSVUpload emails={emails} setEmails={setEmails} role={role} />
        ) : null}

        {showPaticipantsBar ? (
          <Box className="w-full absolute bg-white bottom-0 left-0 right-0 m-auto shadow-none">
            <Accordion
              defaultValue="1"
              type="single"
              className="w-full flex flex-col gap-s-nudge h-full shadow-none"
              collapsible
            >
              <AccordionItem value="1" className="shadow-none">
                <AccordionTrigger className="py-[16px] px-[12px] justify-start">
                  <SectionHeader
                    containerClassName="px-0"
                    titleText={
                      <Text variant={"body_one"} className="text-color-secondary">
                        {countSelectedEmails()
                          ? `Inviting ${countSelectedEmails()} ${getRoleDisplayName(role)}`
                          : `No ${getRoleDisplayName(role)} Selected`}
                      </Text>
                    }
                  />
                </AccordionTrigger>
                <AccordionContent>
                  <ScrollArea
                    className="max-h-[300px] h-full  flex flex-col"
                    viewportClassName="max-h-[300px]"
                    type="always"
                  >
                    <div className="px-m overflow-y-auto">
                      {emails?.map((email: any, index: number) => {
                        return (
                          <Fragment>
                            <Multiline
                              className="bg-transparent !px-0 !mx-0"
                              icon={
                                <div className="flex gap-m items-center">
                                  <Checkbox
                                    size="small"
                                    variant="rounded"
                                    checked={email.selected}
                                    onCheckedChange={() => handleToggleEmailSelection(email)}
                                  />
                                </div>
                              }
                              titleText={""}
                              text1={email.email}
                              titleTextSize={"medium"}
                              text1Size={"small"}
                            />
                            <Divider />
                          </Fragment>
                        );
                      })}
                      {emails?.length ? (
                        <div className="w-full text-center mb-m">
                          <Text
                            className="text-danger-500 cursor-pointer"
                            variant={"body_two"}
                            onClick={handleRemoveAll}
                          >
                            Remove All
                          </Text>
                        </div>
                      ) : null}
                    </div>
                  </ScrollArea>
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            <div className="w-full flex gap-m justify-center mt-s">
              <Button className="w-[180px]" variant="secondary" onClick={handleCancel}>
                <Text variant={"body_two"}>{emailView ? "Back" : "Cancel"}</Text>
              </Button>
              <Button
                className="w-[180px]"
                onClick={handleSubmit}
                disabled={!emails?.length || inviteLoading}
                loading={inviteLoading}
                loaderProps={{ size: "xs" }}
              >
                {!emailView ? (
                  <Text variant={"body_two"}>Invite</Text>
                ) : (
                  <Text variant={"body_two"} className=" items-center flex gap-m">
                    <Send2 variant="Bold" />
                    Send Invite
                  </Text>
                )}
              </Button>
            </div>
          </Box>
        ) : null}
      </div>
    </SideDrawer>
  );
};
