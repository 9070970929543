import { useModal } from "@/stores/use-modal-store";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { Text } from "@/components/ui/text";
import Divider from "@/components/ui/divider";
import { Button } from "@/components/ui/button";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement, ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { IPaymentIntent, MonetaryTypeDetails } from "@/types/cusom-types";
import eventClientApi from "@/api-client/event-client";
import {
  checkIfTBD,
  createDirectPaymentIntentURL,
  getCurrencyInfo,
  updateDirectPaymentIntentURL
} from "@/lib/utils";
import { useEffect, useState } from "react";
import { EVENT_JOIN_STATUS, PAYMENT_SUCCESS_STATUS } from "@key.ai/constants";
import { useSession } from "next-auth/react";
import { Loader } from "@/components/ui/loader";
import dayjs from "dayjs";
import { getTicketPrice } from "@/components/app/events/event-join-view";
import { toast } from "sonner";
import { useRouter } from "next/navigation";
import { env } from "@/env";

const STRIPE_PUBLIC_KEY = env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;

const StripePaymentModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const [paymentIntent, setPaymentIntent] = useState<IPaymentIntent | any>();
  const [loader, setLoader] = useState<boolean>(true);
  const [showPaymentElement, setShowPaymentElement] = useState<boolean>(false);

  const { data: user } = useSession();

  const eventData: any = data?.eventData?.event;
  const selectedTicket: any = data?.selectedTicket;
  const platformFee: any = data?.eventData?.platformFee;
  const participationId: string | undefined = data?.participationId;
  const callback: any = data.callback;
  const refetch: any = data?.refetch;

  let userId = user?.user.id as string;

  const isModalOpen = isOpen && type === "stripePayment";

  const amount = `${getTicketPrice(selectedTicket, platformFee || "5") || ""} ${
    getCurrencyInfo(selectedTicket?.currency)?.label || selectedTicket?.currency
  }`;

  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
    stripeAccount: eventData?.organizer?.stripe_account_id
  });

  const fetchPaymentIntentStatus = async () => {
    setLoader(true);
    try {
      const stripe = await loadStripe(STRIPE_PUBLIC_KEY, {
        stripeAccount: eventData?.organizer?.stripe_account_id
      });
      if (!stripe) {
        return;
      }

      stripe.retrievePaymentIntent(paymentIntent?.client_secret).then(async ({ paymentIntent }) => {
        switch (paymentIntent?.status) {
          case "succeeded":
            await eventClientApi.put(
              updateDirectPaymentIntentURL(userId, eventData.id, paymentIntent?.id),
              {
                json: { status: "succeeded" }
              }
            );
            callback({ paymentIntent: paymentIntent?.id, participationId: participationId });
            onClose();
            // router.refresh()
            toast.success("Success! Payment received.");
            // handlePaymentStatus(true);
            break;

          case "processing":
            toast.success("Payment processing. We'll update you when payment is received.");
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            toast.error("Payment failed. Please try another payment method.");
            break;

          default:
            refetch();
            toast.error("Something went wrong.");
            break;
        }
      });
    } catch (err: any) {
      refetch();
      console.error("fetchPaymentIntentStatus", err);
    } finally {
      onClose();
      setLoader(false);
      setPaymentIntent(null);
      // setPaymentLoading(false);
    }
  };

  const handleCreatePaymentIntent = async (_participationId?: string) => {
    const intentPayload: any = {
      ticket_id: selectedTicket?.id
    };

    if (_participationId) {
      intentPayload.paymentIntent = _participationId;
    }

    try {
      const response = await eventClientApi
        .post(createDirectPaymentIntentURL(userId, eventData.id || ""), {
          json: { ...intentPayload, participation_id: _participationId }
        })
        .json<any>();

      if (response.data?.client_secret) {
        setPaymentIntent(response.data);
        if (response.data?.status === PAYMENT_SUCCESS_STATUS) {
          callback(response.data?.id);
          onClose();
        } else {
          setShowPaymentElement(true);
        }
      }
    } catch (err: any) {
      // handleApiErrors(err, router, signOut, false, true);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isModalOpen && participationId) {
      handleCreatePaymentIntent(participationId);
    }
  }, [isModalOpen, participationId]);

  const handleCancel = async () => {
    setPaymentIntent(null);
    if (data && data?.manageStatus) data?.manageStatus(EVENT_JOIN_STATUS.PAYMENT_PENDING);
    onClose();
  };

  const handleSubmit = async (event: any, stripe: any, elements: any) => {
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setLoader(true);
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href
        },
        redirect: "if_required"
      });
      if (result.error) {
        setLoader(false);
        toast.error(result.error?.message || "Failed to procedd the payment.");
      } else {
        fetchPaymentIntentStatus();
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getRefundableText = () => {
    if (checkIfTBD(eventData?.start_epoc, eventData?.end_epoc)) {
      return "";
    }
    const start_epoc = Number(eventData.start_epoc);
    let dayjsDate = dayjs.unix(start_epoc);
    if (selectedTicket?.refund_policy?.[0]?.valid_before) {
      dayjsDate = dayjsDate.subtract(selectedTicket.refund_policy[0].valid_before, "second");
    }
    if (dayjsDate.isAfter(dayjs())) {
      return `After ${dayjsDate.format("MMMM DD YYYY, hh:mm A, ")} cancellations will not qualify for a refund.`;
    }
    return "Cancellations will no longer qualify for a refund.";
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleCancel}>
      <DialogContent className="max-w-full w-[600px] max-h-[600px]">
        <DialogDescription className="text-zinc-600 text-m font-normal flex justify-between items-center">
          <Text variant={"subtitle_one_strong"}>Payment</Text>
        </DialogDescription>
        <Divider />
        <div>
          {showPaymentElement && paymentIntent?.client_secret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntent?.client_secret
              }}
            >
              <ElementsConsumer>
                {({ stripe, elements }) => {
                  return (
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e, stripe, elements);
                      }}
                      id="payment-form"
                    >
                      <PaymentElement />
                      {selectedTicket.type === "paid" && (
                        <div className="mt-3 mb-2 text-xs text-zinc-500 font-normal">
                          {selectedTicket.is_refundable
                            ? getRefundableText()
                            : `This is a non-refundable event, cancellations will not qualify for a refund.`}
                        </div>
                      )}
                      <Button
                        className="w-full bg-primary text-white font-medium mb-3 px-5 py-3 rounded-lg"
                        disabled={!stripe}
                        style={{ opacity: stripe ? 1 : 0.4 }}
                        loading={loader}
                        loaderProps={{ size: "xs" }}
                      >
                        {`Pay ${amount}`}
                      </Button>
                      <div
                        className="flex justify-center underline mb-5 cursor-pointer"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </div>
                    </form>
                  );
                }}
              </ElementsConsumer>
            </Elements>
          ) : (
            <Loader />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StripePaymentModal;
