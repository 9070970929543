import { useSession } from "next-auth/react";
import * as Yup from "yup";
import React, { Fragment, useEffect, useState } from "react";
import eventClientApi from "@/api-client/event-client";
import Avatar from "@/components/ui/avatar";
import Box from "@/components/ui/box";
import { Checkbox } from "@/components/ui/checkbox";
import { CollapsibleComponent } from "@/components/ui/collapse";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import { Button } from "@/components/ui/button";
import {
  Add,
  ArrowDown,
  ArrowDown2,
  CloseCircle,
  DocumentCode,
  DocumentUpload,
  Edit
} from "iconsax-react";
import { FileButton } from "@/components/ui/file-button";
import { uploadFile } from "@/lib/uploadthing";
import { EndPoints } from "@/types/uploadthing";
import {
  checkEventImageSize,
  getGuestAvatarStatus,
  getGuestPresenceStatus,
  getRoleDisplayName,
  getUserInitials
} from "@/lib/utils";
import { toast } from "sonner";
import { useFormik } from "formik";
import { usePaticipantsInvite } from "@/stores/use-guest-invite-store";
import IconContainer from "@/components/ui/icon-container";
import Multiline from "@/components/ui/multiline";
import { useCSVReader } from "react-papaparse";
import { EMAIL_REGEX, isValidEmail } from "@key.ai/constants";
import Divider from "@/components/ui/divider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";

const removeDuplicatesByEmail = (arr: any) => {
  const seen = new Set();
  return arr.filter((item: any) => {
    const duplicate = seen.has(item?.email);
    seen.add(item?.email);
    return !duplicate;
  });
};

const countSelectedEmails = (emails: any) => {
  return emails.filter((email: any) => email.selected).length;
};

const tabs = [
  {
    key: "email",
    title: "Email"
  }
  // {
  //   key: "pastEvents",
  //   title: "Past Events"
  // }
];

const CreateGroup = ({
  setCreateMode,
  selectedGroup,
  setShowPaticipantsBar,
  getAllGroups
}: any) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { data: session }: any = useSession();

  const [initalValues, setInitalValues] = useState<any>(selectedGroup);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Group name is required"),
    description: Yup.string().required("Group description is required"),
    image: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: initalValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const payload = {
          ...values,
          user_id: session?.user?.id
        };
        const response = await eventClientApi
          .post(`api/v2/guestlist/group`, {
            json: payload
          })
          .json<any>();

        if (response.status === "success") {
          setCreateMode(false);
          setShowPaticipantsBar(true);
          getAllGroups();
          toast.success("Group created successfully");
        }
      } catch (err) {
        toast.success(err.message);
      } finally {
        setLoading(false);
      }
    }
  });

  const handleLogo = async (file: File | null) => {
    if (!file) return;
    setImageLoading(true);
    try {
      const { url } = await uploadFile(file as any, EndPoints.eventBanner);
      await formik.setFieldValue("image", url);
    } catch (err) {
      toast.error("Something went wrong while uploading image.");
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <div>
      <SectionHeader
        containerClassName="mt-s px-s"
        titleText={
          <Text variant={"caption_one_strong"} className="text-color-secondary">
            Create
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"caption_one"}></Text>
          </div>
        }
      />

      <Box className="relative w-full h-48 flex justify-center">
        {formik.values.image ? (
          <img
            src={formik.values.image}
            className=" rounded-4 
                h-42
                 object-cover"
          />
        ) : (
          <Text variant={"body_two"} className="m-auto text-color-tertiary">
            Add group profile
          </Text>
        )}

        <div className="absolute right-4 top-4 ">
          <FileButton onChange={handleLogo} accept="image/png,image/jpeg">
            {(props) => (
              <Button
                variant="secondary"
                {...props}
                radius={"sm"}
                className="rounded-sm "
                layerClassName="px-s-nudge"
              >
                {!imageLoading ? <DocumentUpload size={"24px"} /> : <Loader size="small" />}
              </Button>
            )}
          </FileButton>
        </div>
      </Box>

      <SectionHeader
        containerClassName="mt-s px-s"
        titleText={
          <Text variant={"caption_one_strong"} className="text-color-secondary">
            Group Name
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"caption_one"}></Text>
          </div>
        }
      />
      <Input
        variant="medium-s"
        className="rounded-4 w-full px-xl"
        inputClassName="w-full "
        placeholder="Name"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        errorMessage={formik.errors.name}
        error={!!formik.errors.name}
      />
      <SectionHeader
        containerClassName="mt-s px-s"
        titleText={
          <Text variant={"caption_one_strong"} className="text-color-secondary">
            Group Description
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"caption_one"}></Text>
          </div>
        }
      />
      <Input
        variant="medium-s"
        className="rounded-4 w-full px-xl"
        inputClassName="w-full "
        placeholder="Description"
        name="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        errorMessage={formik.errors.description}
        error={!!formik.errors.description}
      />

      <div className="flex justify-between gap-m absolute left-0 bottom-0 right-0">
        <Button
          className="w-full"
          variant="secondary"
          onClick={() => {
            setCreateMode(false);
            setShowPaticipantsBar(true);
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full"
          variant="primary"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          {selectedGroup?.id ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

const AddMember = ({ selectedGroup, setAddMember, setShowPaticipantsBar, getAllGroups }: any) => {
  const [guestList, setGuestList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalidCSV, setIsInvalidCSV] = useState<boolean>(false);
  const [guestsToDelete, setGuestToDelete] = useState<any>([]);
  const [openFooter, setOpenFooter] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<any>("");
  const [view, setView] = useState<string>("email");

  const { CSVReader } = useCSVReader();

  const handleAddGuest = async () => {
    try {
      setLoading(true);
      const guestToBeAdd = guestList?.map((guest: any) => ({
        email: guest.email,
        id: guest?.id
      }));
      const group = {
        groupId: selectedGroup?.id,
        guestsToDelete: guestsToDelete,
        guests: removeDuplicatesByEmail(guestToBeAdd)
      };
      const response = await eventClientApi.post(`api/v2/guestlist/group/guestlist`, {
        json: group
      });
      if (response.status === 200) {
        getAllGroups();
        setShowPaticipantsBar(true);
        setAddMember(false);
      }
      toast.success("Guest added successfully");
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveEmail = (email: string) => {
    setGuestToDelete((prevState: any) => [...prevState, email]);
    setGuestList((prevState: any) => {
      const removeEmail = prevState?.filter((v: any) => v.email !== email);
      return removeEmail;
    });
  };

  const handleReadData = ({ data }: any) => {
    setIsInvalidCSV(false);
    if ((data[0][0] || "").toLowerCase()?.includes("email")) {
      const emails = data
        .map((col: string[]) => col[0])
        .filter((email: string) => EMAIL_REGEX.test(email.trim()))
        .map((email: string) => {
          return { email: email.trim(), selected: true };
        });
      if (emails?.length) {
        setGuestList((prevState: any) => removeDuplicatesByEmail([...emails, ...prevState]));
      } else {
        toast.error("Please upload a file containing valid email addresses.");
      }
    } else {
      toast.error("Please upload a valid file, refer to the sample file for guidance.");
    }
    setIsInvalidCSV(true);
  };

  const handleToggleEmailSelection = (emailToToggle: any) => {
    setGuestList(
      guestList.map((email: any) =>
        email.email === emailToToggle.email ? { ...email, selected: !email.selected } : email
      )
    );
  };

  const handleRemoveAll = () => {
    setGuestList([]);
  };

  const handleCancel = () => {
    setAddMember(false);
    setShowPaticipantsBar(true);
  };

  const handleEmailInputChange = (e: any) => {
    setEmailInput(e.target.value);
  };

  const addEmails = (event: any) => {
    if (event.key !== "Enter") return;
    const newEmails = emailInput.split(",").map((email: any) => email.trim());
    const invalidEmails = newEmails.filter((email: any) => !isValidEmail(email));

    if (invalidEmails.length > 0) {
      toast.error("Given emails are not valid.");
    } else {
      const uniqueEmails = [...new Set(newEmails)];
      const existingEmails = guestList.map((email: any) => email.email.toLowerCase());
      const emailsToAdd = uniqueEmails
        .filter((email: any) => !existingEmails.includes(email.toLowerCase()))
        .map((email: any) => ({ email, selected: true }));
      setGuestList([...guestList, ...emailsToAdd]);
      setEmailInput("");
    }
  };

  const handleTabChange = (value: string) => {
    setView(value);
  };

  return (
    <div className="h-full">
      <SectionHeader
        containerClassName="mt-l px-s"
        titleText={
          <Text variant={"caption_one_strong"} className="text-color-secondary">
            Add Group Member
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"caption_one"}>{selectedGroup?.name}</Text>
          </div>
        }
      />
      <Box className=" rounded-4">
        <CSVReader
          onUploadAccepted={(results: any) => {
            handleReadData(results);
          }}
        >
          {({ getRootProps, acceptedFile }: any) => (
            <div {...getRootProps()}>
              <Multiline
                icon={<IconContainer size="default" as="div" icon={<DocumentCode />} />}
                titleText={<Text variant={"body_two"}>Import CSV File</Text>}
                text1={"Drop file or click here to choose"}
                titleTextSize={"medium"}
                text1Size={"small"}
                className=" rounded-4 px-m"
              />
            </div>
          )}
        </CSVReader>
      </Box>
      <div className="mt-l">
        {/* <div className="flex gap-x-m rounded-4">
          {tabs.map((section, index) => (
            <Button
              key={index}
              variant={view === section.key ? "primary" : "secondary"}
              radius="none"
              layerClassName="hover:bg-transparent"
              onClick={() => handleTabChange(section.key)}
            >
              {section.title}
            </Button>
          ))}
        </div> */}

        {view === "email" ? (
          <div>
            <SectionHeader
              containerClassName="mt-l px-s"
              titleText={
                <Text variant={"caption_one_strong"} className="text-color-secondary">
                  Enter Emails
                </Text>
              }
            />
            <div className="w-full">
              <Input
                variant="medium-s"
                className="rounded-xs w-full px-xl"
                inputClassName="w-full "
                placeholder="Enter Emails"
                value={emailInput}
                onChange={handleEmailInputChange}
                onKeyDown={addEmails}
              />
            </div>
          </div>
        ) : null}
      </div>
      <Box className="w-full absolute bottom-0 left-0 right-0 m-auto shadow-none">
        <CollapsibleComponent
          isOpen={openFooter}
          onToggle={() => {
            setOpenFooter(!openFooter);
          }}
          direction="up"
          contentClasses="!px-0 !mx-0 !my-0 !shadow-none rounded-sm backdrop-blur-none !backdrop-filter-none !border-none "
          selected={openFooter}
          triggerClasses="bg-transparent"
          triggerText={
            <SectionHeader
              containerClassName="px-0"
              titleText={
                <Text variant={"caption_one"} className="text-color-secondary">
                  {countSelectedEmails(guestList)
                    ? `Adding ${countSelectedEmails(guestList)}`
                    : `No user Selected`}
                </Text>
              }
              action={
                <div className="flex gap-m items-center">
                  <Text variant={"caption_one"} className="text-brand-500">
                    {openFooter ? "Hide" : "Show"}
                  </Text>
                  <ArrowDown2 className="text-color-tertiary" size={"24px"} />
                </div>
              }
            />
          }
        >
          <div className=" max-h-[300px] overflow-y-auto">
            {guestList?.map((email: any, index: number) => {
              return (
                <>
                  <Multiline
                    className="bg-transparent !px-0 !mx-0"
                    icon={
                      <div className="flex gap-m items-center">
                        <Checkbox
                          size="small"
                          variant="rounded"
                          checked={email.selected}
                          onCheckedChange={() => handleToggleEmailSelection(email)}
                        />
                        <Avatar
                          fallbackText="KG"
                          size={56}
                          activeRing={false}
                          variant="standard"
                          present={false}
                          presence={false}
                          presenceState="blocked"
                        />
                      </div>
                    }
                    titleText={""}
                    text1={email.email}
                    titleTextSize={"medium"}
                    text1Size={"small"}
                    action={
                      <div
                        className="flex flex-col justify-center items-center cursor-pointer"
                        onClick={() => handleRemoveEmail(email)}
                      >
                        <CloseCircle className="text-red-500" />
                      </div>
                    }
                  />
                  <Divider />
                </>
              );
            })}
            {guestList?.length ? (
              <div className="w-full text-center">
                <Text
                  className="text-danger-500 cursor-pointer"
                  variant={"body_two"}
                  onClick={handleRemoveAll}
                >
                  Remove All
                </Text>
              </div>
            ) : null}
          </div>
        </CollapsibleComponent>
        <div className="w-full flex gap-m justify-center mt-s">
          <Button className="w-[180px]" variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="w-[180px]"
            onClick={handleAddGuest}
            disabled={!guestList?.length && loading}
            loading={loading}
          >
            Invite
          </Button>
        </div>
      </Box>
    </div>
  );
};

const Group = ({ emails, setEmails, role }: any) => {
  const [groups, setGroups] = useState<any>([]);

  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [addMember, setAddMember] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<any>(false);
  const [opened, setOpened] = useState<string>("");
  const { data: session }: any = useSession();
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [addGroupLoading, setAddGroupLoading] = useState<{ id: string; loading: boolean }>({
    id: "",
    loading: true
  });
  const [selectedGroup, setSelectedGroup] = useState<any>({
    name: "",
    description: "",
    image: ""
  });

  const { setShowPaticipantsBar } = usePaticipantsInvite();

  const getAllGroups = async () => {
    try {
      setOpened("");
      const { data } = await eventClientApi
        .get(`api/v2/guestlist/group`, {
          searchParams: {
            user_id: session?.user?.id
          }
        })
        .json<any>();

      setGroups(data);
      setResults(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  const handleCreate = () => {
    setCreateMode(true);
    setShowPaticipantsBar(false);
    setSelectedGroup({});
  };

  const handleEdit = (group: any) => {
    setSelectedGroup(group);
    setAddMember(true);
    setShowPaticipantsBar(false);
  };

  const handleAddGuest = async (id: string, toggle?: boolean, bulkAction = false) => {
    // setLoading(true);
    if (!toggle) {
      if (bulkAction) {
        setUsers((prevState: any) =>
          prevState.map((state: any) => ({ ...state, selected: false }))
        );
        setEmails([]);
      } else {
        setUsers((prevState: any) =>
          prevState.map((state: any) => ({
            ...state,
            selected: state.groupId === id ? false : state.selected
          }))
        );
        setEmails((prevState: any) => prevState.filter((email: any) => email.groupId !== id));
      }
    } else {
      try {
        const { data } = await eventClientApi
          .get(`api/v2/guestlist/all/${id}`, {
            searchParams: { user_id: session?.user?.id }
          })
          .json<any>();

        const _data = data.map((d: any) => ({ ...d, selected: true }));
        const invites = _data.map((d: any) => ({
          email: d.email,
          role: role,
          selected: true,
          groupId: id
        }));

        setEmails((prevState: any) => [...prevState, ...invites]);
        setUsers((prevState: any) => prevState.map((state: any) => ({ ...state, selected: true })));
      } catch (error) {
        console.log(error);
      } finally {
        setAddGroupLoading({ id: "", loading: false });
        // setLoading(false);
      }
    }
  };

  const handleSelectAll = async (e: boolean) => {
    const toggle = e;
    setSelectAll(toggle);
    const groupIds = groups.map((group: any) => group.id);
    for (let id of groupIds) {
      await handleAddGuest(id, toggle, true);
    }
  };

  const handleFetchGroupGuest = async (id: string, select?: boolean) => {
    if (opened === id) {
      return setOpened("");
    }
    try {
      const { data } = await eventClientApi
        .get(`api/v2/guestlist/all/${id}`, {
          searchParams: {
            user_id: session?.user?.id
          }
        })
        .json<any>();
      const _data = data?.map((d: any) => ({ ...d, selected: false }));
      setUsers(_data);
      setOpened(id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = (group: any) => {
    setAddMember(true);
    setSelectedGroup(group);
    setShowPaticipantsBar(false);
  };

  const handleToggleEmailSelection = (emailToToggle: any) => {
    setUsers(
      users.map((email: any) =>
        email.email === emailToToggle.email ? { ...email, selected: !email.selected } : email
      )
    );
    setEmails(
      emails.map((email: any) =>
        email.email === emailToToggle.email ? { ...email, selected: !email.selected } : email
      )
    );
  };

  const handleSearch = (event: any) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    if (searchQuery) {
      const filteredData = groups.filter((item: any) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setResults(filteredData);
    } else {
      setResults(groups);
    }
  };

  return (
    <div className="relative h-[90%]">
      <div className="">
        {createMode ? (
          <CreateGroup
            setCreateMode={setCreateMode}
            selectedGroup={selectedGroup}
            setShowPaticipantsBar={setShowPaticipantsBar}
            getAllGroups={getAllGroups}
          />
        ) : addMember ? (
          <AddMember
            selectedGroup={selectedGroup}
            setAddMember={setAddMember}
            getAllGroups={getAllGroups}
            setShowPaticipantsBar={setShowPaticipantsBar}
          />
        ) : (
          <Fragment>
            <Box className=" mt-xl">
              <div className="w-full">
                <Input
                  variant="medium-s"
                  className="rounded-xs w-full px-xl"
                  inputClassName="w-full"
                  placeholder="Search Groups"
                  value={query}
                  onChange={handleSearch}
                />
              </div>
              <SectionHeader
                containerClassName="mt-s px-s"
                titleText={
                  <Text variant={"caption_one_strong"} className="text-color-secondary">
                    Groups
                  </Text>
                }
                action={
                  <div className="flex gap-m items-center">
                    <Text variant="caption_one">Select All</Text>
                    <Checkbox
                      className="text-color-tertiary"
                      size="small"
                      checked={selectAll}
                      onCheckedChange={handleSelectAll}
                    />
                  </div>
                }
              />
              <div className="max-h-[42vh] overflow-y-auto">
                {loading ? (
                  <div className="mx-auto">
                    <Loader />
                  </div>
                ) : results?.length ? (
                  results?.map((group: any, index: number) => {
                    return (
                      <CollapsibleComponent
                        key={index}
                        isOpen={opened === group?.id}
                        direction="up"
                        contentClasses="!mx-0 !my-0 !shadow-none rounded-sm backdrop-blur-none !backdrop-filter-none !border-none bg-temp-container"
                        selected={true}
                        triggerClasses="bg-transparent"
                        triggerText={
                          <Multiline
                            className="bg-transparent !px-0 !mx-0"
                            icon={
                              <div className="flex gap-m items-center">
                                {addGroupLoading.loading && addGroupLoading.id === group.id ? (
                                  <Loader size="small" />
                                ) : (
                                  <Checkbox
                                    variant="rounded"
                                    disabled={
                                      addGroupLoading.loading && addGroupLoading.id === group.id
                                    }
                                    onCheckedChange={(e: boolean) => {
                                      setAddGroupLoading({ id: group.id, loading: true });
                                      handleAddGuest(group?.id, e);
                                    }}
                                    checked={
                                      !!emails?.find((e: any) => e.groupId === group?.id)?.groupId
                                    }
                                  />
                                )}

                                <Avatar
                                  imgSrc={group?.image}
                                  size={56}
                                  activeRing={false}
                                  variant="image"
                                  present={false}
                                  presence={false}
                                  presenceState="blocked"
                                />
                              </div>
                            }
                            titleText={group?.name}
                            text1={`${group?.numberOfGuests} People`}
                            titleTextSize={"medium"}
                            text1Size={"small"}
                            action={
                              <div className="flex justify-center items-center gap-m">
                                <Edit onClick={() => handleEdit(group)} />
                                <ArrowDown2
                                  className="cursor-pointer"
                                  onClick={() => handleFetchGroupGuest(group?.id)}
                                />
                              </div>
                            }
                          />
                        }
                      >
                        {users?.map((user: any, index: number) => {
                          const initals = getUserInitials(user);
                          return (
                            <Multiline
                              key={index}
                              icon={
                                <div className="flex gap-m items-center">
                                  <Avatar
                                    text={initals}
                                    imgSrc={user?.profile_photo}
                                    size={56}
                                    activeRing={true}
                                    variant={user?.profile_photo ? "image" : "initials"}
                                    present={false}
                                    presence={false}
                                  />
                                </div>
                              }
                              text1={user?.email}
                              titleTextSize={"medium"}
                              text1Size={"small"}
                            />
                          );
                        })}
                        <div className="w-full text-center">
                          <Text
                            className="text-brand-500 cursor-pointer mx-auto mb-m"
                            variant={"body_two"}
                            onClick={() => handleAddMember(group)}
                          >
                            Add Member
                          </Text>
                        </div>
                      </CollapsibleComponent>
                    );
                  })
                ) : (
                  <Fragment>
                    <Box className="bg-transparent shadow-none text-center">
                      <Text>No groups found.</Text>
                    </Box>
                  </Fragment>
                )}
              </div>
              <Button variant="primary" className=" mt-xl" onClick={handleCreate}>
                <Add className="mr-m" /> Create Group
              </Button>
            </Box>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Group;
