import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { collection, onSnapshot, query, where, limitToLast, orderBy } from "firebase/firestore";
import { collections } from "@key.ai/firestore";
import { db } from "@/firebase/db";
import { PublishActionType } from "@key.ai/enum";
import { getQueryKey, getSocketKey } from "@key.ai/constants";
import { useSocket } from "@/providers/socket-provider";
import { queryUpdateUserStatus } from "@/lib/user";
import { queryRemoveMemberFromConversation, queryUpdateConversationMembers } from "@/lib/channel";

type UserSocketProps = {
  userId: string;
};

export const useUserSocket = ({ userId }: UserSocketProps) => {
  const queryClient = useQueryClient();
  const { watcher } = useSocket();

  useEffect(() => {
    // const key = getSocketKey("UPDATE_MEMBER", { serverId: server.slug });
    // watcher?.(key, (payload: { serverId: string; memberId: string }) =>
    //   queryUpdateMember(queryClient, payload)
    // );
    const key = getSocketKey("UPDATE_USER_STATUS");
    watcher?.(key, (payload: { isOnline: boolean; userId: string }) =>
      queryUpdateUserStatus(queryClient, payload)
    );
  }, [queryClient, watcher]);

  useEffect(() => {
    const q = query(
      collection(db, collections.members),
      where("userId", "==", userId),
      orderBy("createdAt", "asc"),
      limitToLast(1)
    );

    // LISTEN FOR REAL-TIME UPDATES
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      for (const change of snapshot.docChanges()) {
        if (change.type === "added") {
          const doc = change.doc.data();

          // if (doc.type === ConversationType.CHANNEL) return;

          switch (doc.actionType) {
            case PublishActionType.PRIVATE_ADD_MEMBER:
              await queryClient.refetchQueries({
                queryKey: [getQueryKey("PERSONAL_CONVERSATIONS")]
              });
              await queryClient.refetchQueries({
                queryKey: [getQueryKey("UNREADS", { userId })]
              });
              break;

            case PublishActionType.PRIVATE_REMOVE_MEMBER:
              queryRemoveMemberFromConversation(queryClient, doc.conversationId);
              break;

            case PublishActionType.PRIVATE_UPDATE_MEMBER:
              queryUpdateConversationMembers(queryClient, doc.conversationId);
              break;
          }
        }
      }
    });

    // CLEANUP SUBSCRIPTION ON UNMOUNT
    return () => unsubscribe();
  }, [queryClient, userId]);
};
