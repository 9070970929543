"use client";

import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

export const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      // className="toaster group"
      // toastOptions={{
      //   classNames: {
      //     toast:
      //       "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
      //     description: "group-[.toast]:text-muted-foreground",
      //     actionButton:
      //       "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
      //     cancelButton:
      //       "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
      //   },
      // }}
      closeButton
      toastOptions={{
        classNames: {
          closeButton: "bg-white"
        }
      }}
      position="top-center"
      {...props}
    />
  );
};
