import { useQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { getQueryKey } from "@key.ai/constants";
import { compact } from "lodash";
import { cleanObject } from "@/lib/helper";

interface UnreadsObject {
  [key: string]: number;
}

interface Data {
  conversations: UnreadsObject;
  channels: UnreadsObject;
}

export const useUnreadsQuery = ({ serverId, userId }: { serverId?: string; userId: string }) => {
  const fetchUnreads = async () => {
    const { data } = await clientApi
      .get(`api/v1/conversations/unreads-chats`, {
        searchParams: cleanObject({ serverId })
      })
      .json<ApiResponse<Data>>();

    return data;
  };

  return useQuery<Data>({
    queryKey: compact([getQueryKey("UNREADS", { userId })]),
    queryFn: fetchUnreads,
    retry: false
  });
};
