/* eslint-disable no-unused-vars */
/* eslint-disable turbo/no-undeclared-env-vars */
"use client";

import { Fragment, createContext, useContext, useEffect, useMemo, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useListState } from "@mantine/hooks";
import findIndex from "lodash/findIndex";
import { env } from "@/env";
import { signOut, useSession } from "next-auth/react";
import { useParams } from "next/navigation";
import { useSessionStorage } from "@mantine/hooks";
import { clientIdKey, getSocketKey } from "@key.ai/constants";
import { v4 as uuidv4 } from "uuid";
import { UnreadsSocketComponent, UserSocketComponent } from "@/components/socket";
import { useFingerprint } from "./fingerprint-provider";
import { ignoreDomains } from "@/lib/url";

type CallbackFunction<T extends any[]> = (...args: T) => void;

interface SocketList<T extends any[] = any[]> {
  ev: string;
  fn: (...args: T) => void;
}

type SocketContextType = {
  socket: Socket | null;
  isConnected: boolean;
  remove_listener?: (ev: string) => void;
  watcher?: <T extends any[]>(ev: string, fn: (...args: T) => void) => void;
};

const SocketContext = createContext<SocketContextType>({
  socket: null,
  isConnected: false
});

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const params = useParams<{ domain?: string }>();
  const { fingerprint } = useFingerprint();

  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const { data: session } = useSession();

  const [sockets, handlers] = useListState<SocketList>([]);

  const token = useMemo(() => session?.accessToken, [session]);
  const userId = useMemo(() => session?.user.id, [session]);
  const serverId = useMemo(() => {
    return params?.domain && ignoreDomains.includes(params?.domain) ? undefined : params?.domain;
  }, [params]);

  const [sessionId] = useSessionStorage({
    key: clientIdKey,
    defaultValue: uuidv4(),
    getInitialValueInEffect: false
  });

  useEffect(() => {
    if (!token || !userId || !sessionId) return;

    const socketInstance: Socket = io(env.NEXT_PUBLIC_API_BASE_URL, {
      path: "/socket.io",
      transports: ["websocket", "polling"],
      query: { token, serverId, sessionId, userId }
    });

    socketInstance.on("connect", () => setIsConnected(true));
    socketInstance.on("disconnect", () => setIsConnected(false));

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, [token, serverId, userId, sessionId]);

  useEffect(() => {
    if (socket) {
      sockets.forEach(({ ev, fn }) => socket.on(ev, fn));
    }

    return () => {
      sockets.forEach(({ ev }) => socket?.off(ev));
    };
  }, [socket, sockets]);

  // SOCKET FOR HANDLE LOGOUT USER
  useEffect(() => {
    if (!socket || !fingerprint) return;
    const key = getSocketKey("LOGOUT_USER", { deviceId: fingerprint });
    socket.on(key, () => signOut());

    return () => {
      socket.off(key);
    };
  }, [socket, fingerprint]);

  // SOCKET FOR HANDLE DELETE USER
  useEffect(() => {
    if (!socket || !userId) return;
    const key = getSocketKey("ACCOUNT_DELETE", { userId });
    socket.on(key, () => signOut());

    return () => {
      socket.off(key);
    };
  }, [socket, userId]);

  // useEffect(() => {
  //   if (socket) {
  //     sockets.forEach(({ ev, fn }) => {
  //       socket.emit("joinRoom", { room: ev });
  //       socket.on(ev, fn);
  //     });
  //   }

  //   return () => {
  //     sockets.forEach(({ ev }) => socket?.emit("leaveRoom", { room: ev }));
  //   };
  // }, [socket, sockets]);

  const watcher = <T extends any[]>(ev: string, fn: CallbackFunction<T>) => {
    const index = findIndex(sockets, { ev });
    if (index === -1) {
      handlers.append({ ev, fn: fn as CallbackFunction<any[]> });
    }
  };

  const remove_listener = (ev: string) => {
    const index = findIndex(sockets, { ev });
    if (index >= 0) handlers.remove(index);
  };

  return (
    <SocketContext.Provider value={{ socket, isConnected, watcher, remove_listener }}>
      {userId ? (
        <Fragment>
          <UserSocketComponent userId={userId} />
          <UnreadsSocketComponent serverId={serverId} userId={userId!} />
        </Fragment>
      ) : null}
      {children}
    </SocketContext.Provider>
  );
};
