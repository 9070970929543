"use client";

import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Loader } from "@/components/ui/loader";
import { useGlobalLoaderModal } from "@/stores/use-global-loader-store";

export const LoaderModal = () => {
  const { isOpen } = useGlobalLoaderModal();

  return (
    <Dialog open={isOpen}>
      <DialogContent isShowCloseBtn={false}>
        <DialogTitle className="hidden"></DialogTitle>
        <Loader />
      </DialogContent>
    </Dialog>
  );
};
