"use client";

import * as React from "react";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
// import { isUndefined } from "lodash";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

type PaaginationProps = {
  nextClassName?: string | undefined;
  prevClassName?: string | undefined;
  className?: string | undefined;
  paginateClassName?: string | undefined;

  total?: number;
  current?: number;

  onNextClick?: () => void;
  onPreviousClick?: () => void;
};

export const SliderPagination = React.forwardRef<HTMLDivElement, PaaginationProps>(
  (
    {
      className,
      nextClassName,
      prevClassName,
      paginateClassName,

      onNextClick,
      onPreviousClick,

      total,
      current
    },
    ref
  ) => {
    return (
      <div className={cn("flex items-center justify-center gap-1", className)} ref={ref}>
        <button className="me-2">
          <ArrowLeft2 onClick={() => onPreviousClick?.()} />
        </button>
        <Badge
          className={cn(
            "bg-gray-700 text-white inline-block w-[8px] h-[8px] p-0 hover:bg-gray-700",
            prevClassName
            // !isUndefined(onPreviousClick) && "cursor-pointer"
          )}
          // onClick={() => onPreviousClick?.()}
        ></Badge>
        <Badge
          className={cn("bg-gray-700 text-white inline-block hover:bg-gray-700", paginateClassName)}
        >
          {current}/{total}
        </Badge>
        <Badge
          className={cn(
            "bg-gray-700 text-white inline-block w-[8px] h-[8px] p-0 hover:bg-gray-700",
            nextClassName
            // !isUndefined(onNextClick) && "cursor-pointer"
          )}
          // onClick={() => onNextClick?.()}
        ></Badge>

        <button className="ms-2">
          <ArrowRight2 onClick={() => onNextClick?.()} />
        </button>
      </div>
    );
  }
);
SliderPagination.displayName = "SliderPagination";
