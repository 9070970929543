import React, { HTMLAttributes, forwardRef } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const boxVariants = cva("", {
  variants: {
    shadow: {
      sm: "shadow-subtle-sm",
      "subtle-sm": "shadow-blur-subtle-sm",
      md: "shadow-md",
      lg: "shadow-lg",
      none: "shadow-none"
    },
    padding: {
      sm: "p-2",
      md: "p-4",
      lg: "p-6"
    },
    radius: {
      sm: "rounded-sm",
      md: "rounded-medium",
      lg: "rounded-lg",
      "3xl": "rounded-3xl"
    },
    withBorder: {
      true: "border border-solid border-container-border",
      false: ""
    },
    variant: {
      default: "bg-container",
      unstyled: ""
    }
  },
  defaultVariants: {
    shadow: "none",
    padding: "md",
    radius: "lg",
    withBorder: false,
    variant: "default"
  }
});

interface BoxProps extends VariantProps<typeof boxVariants>, HTMLAttributes<HTMLDivElement> {}

const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    { children, className, shadow, padding, radius, withBorder, variant, style = {}, ...props },
    ref
  ) => {
    return (
      <div
        className={cn(boxVariants({ shadow, padding, radius, withBorder, variant }), className)}
        style={style}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

Box.displayName = "Box";

export default Box;
