import { useParams } from "next/navigation";
import { useInviteCodeQuery } from "@/hooks/use-invite-code-query";
import { useClipboard } from "@mantine/hooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { uploadPrivateFile } from "@/lib/uploadthing";
import { EndPoints } from "@/types/uploadthing";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { toast } from "sonner";
import { Fragment, useCallback } from "react";
import { Text } from "@/components/ui/text";
import { Button } from "@/components/ui/button";
import IconContainer from "@/components/ui/icon-container";
import { Loader } from "@/components/ui/loader";
import { IconX } from "@tabler/icons-react";
import FileIconComponent from "@/components/file-icon";
import { Dropzone } from "@/components/ui/dropzone";
import { MIME_TYPES } from "@/types/mime-types";
import Box from "@/components/ui/box";
import { DocumentCode, DocumentDownload, DocumentUpload } from "iconsax-react";
import { env } from "@/env";

export const BulkUpload = () => {
  const { domain } = useParams<{ domain: string }>();
  const { data: inviteCode } = useInviteCodeQuery();

  const { copied, copy } = useClipboard({ timeout: 500 });

  const formik = useFormik<{
    csv: File | null;
    csvUrl: string | "";
  }>({
    initialValues: {
      csv: null,
      csvUrl: ""
    },
    validationSchema: yup.object({
      csv: yup.mixed().required("Please upload csv file.")
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const file = await uploadPrivateFile(values.csv!, EndPoints.PrivateCSV);
        const { message } = await clientApi
          .post(`api/v1/${domain}/invite-code/bulk`, {
            json: { file: file.fileKey }
          })
          .json<ApiResponse>();

        toast.success(message);
        resetForm();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const sampleFile = useCallback(() => {
    // Sample data
    const data = [["Email"], ["user1@example.com"], ["user2@example.com"]];

    // Create CSV content
    const csvContent = data.map((row) => row.join(",")).join("\n");

    // Create a blob and a link to trigger the download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "bulk_user_invite_sample.csv";
    link.style.display = "none";

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
  }, []);

  return (
    <Fragment>
      <div className="w-full">
        <div className="mb-3 flex items-center">
          <Text variant="body_one" as="span">
            Upload CSV
          </Text>
        </div>

        {formik.values.csv ? (
          <Fragment>
            <div className="align-top inline-block p-3 bg-white rounded-sm">
              <div className="relative">
                <IconContainer
                  className="absolute -top-2 -right-2 shadow-none w-[15px] h-[15px]"
                  disabled={formik.isSubmitting}
                  size="default"
                  onClick={() => formik.setFieldValue("csv", null)}
                >
                  {formik.isSubmitting ? <Loader size="xs" /> : <IconX size={15} stroke={1.5} />}
                </IconContainer>

                <button className={`block relative`}>
                  <div className="flex items-center">
                    <div className="w-[26px]">
                      <FileIconComponent type={formik.values.csv.type || ""} />
                    </div>
                    <Text className="ms-3 w-[100px] truncate">{formik.values.csv.name}</Text>
                  </div>
                </button>
              </div>
            </div>

            <div className="mt-3">
              <Button
                type="button"
                variant="secondary"
                className="w-auto"
                layerClassName="py-[4px] h-auto"
                onClick={() => formik.handleSubmit()}
                disabled={formik.isSubmitting}
                loading={formik.isSubmitting}
                loaderProps={{ size: "xs" }}
              >
                Send Invitation
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Dropzone
              accept={{ [MIME_TYPES.csv]: [] }}
              onDrop={(files) => formik.setFieldValue("csv", files[0])}
              multiple={false}
            >
              <Box className="py-3 px-4 flex justify-between items-center gap-4 field-border">
                <Button
                  type="button"
                  variant="unstyled"
                  className="w-full justify-start h-auto p-0"
                  layerClassName="flex items-center justify-start gap-4"
                >
                  <IconContainer icon={<DocumentCode size={18} />} as="span" size={40} />
                  <div className="flex flex-col items-start">
                    <Text variant="body_one_strong">Upload CSV</Text>
                    <Text variant="caption_two">Drop file or click here to choose</Text>
                  </div>
                </Button>

                <Button
                  type="button"
                  variant="primary"
                  className="ms-auto w-auto h-auto !rounded-4"
                  layerClassName="py-[4px] h-auto"
                  size="sm"
                >
                  <Text as="span" variant="caption_one" className="font-semibold">
                    Upload
                  </Text>
                </Button>
              </Box>
            </Dropzone>

            {formik.errors.csv && formik.touched.csv ? (
              <Text className="text-red-500 text-xs mt-2 mx-l">{formik.errors.csv}</Text>
            ) : null}
          </Fragment>
        )}

        <Button
          variant="text"
          className="w-full bg-brand-50 rounded-[10px] mt-m-nudge"
          layerClassName="py-[4px] h-auto"
          onClick={sampleFile}
        >
          <Text variant="body_one" className="text-brand-600 font-semibold">
            Download Sample CSV
          </Text>
        </Button>
      </div>

      {/* <div
        className="mb-3 mt-2 flex items-center"
        onClick={() =>
          copy(
            `${window.location.protocol}//${env.NEXT_PUBLIC_APP_DOMAIN}/invite/${inviteCode?.inviteCode}`
          )
        }
        role="button"
      >
        <Text variant="body_one" className="mx-l">
          Share Invite Link
        </Text>

        <Button
          variant="text"
          as="span"
          className="ms-auto w-auto text-brand-500"
          layerClassName="py-[4px] h-auto"
        >
          <Text variant="caption_one" className="text-brand-600 font-semibold">
            {copied ? "Copied" : "Click Here"}
          </Text>
        </Button>
      </div> */}
    </Fragment>
  );
};
