import { useUnsplashQuery } from "@/hooks/use-unsplash-query";
import { UnsplashType } from "@key.ai/constants";
import { Input } from "../ui/input";
import { ScrollArea } from "../ui/scroll-area";
import useDebounce from "@/hooks/use-debounce";
import { useEffect, useState } from "react";
import useInView from "@/hooks/use-in-view";
import { Loader } from "../ui/loader";
import { v4 } from "uuid";
import { useImageCropModal } from "@/stores/use-image-crop-store";

interface ImageData {
  id?: string;
  description?: string | null;
  urls?: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
  user?: {
    name: string;
    username: string;
    links?: {
      html: string;
    };
  };
}

interface ImageOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

interface ImageDetailsProps {
  image: ImageData;
  callback?: (file: File) => void;
}

const downloadAndCreateFile = async (imageUrl?: string): Promise<File | null> => {
  try {
    const response = await fetch(`${imageUrl}`);
    const blob = await response.blob();
    if (!blob) return null;

    return new File([blob], v4(), { type: "image/png" });
  } catch (error) {
    console.error("Error downloading image:", error);
    return null;
  }
};

const Unsplash = ({
  callback,
  onClose
}: {
  callback?: (file: File) => void;
  onClose: () => void;
}) => {
  const [search, setSearch] = useState("Event");
  const debouncedSearchTerm: string = useDebounce<string>(search, 500);

  const [uploading, setUploading] = useState(false);

  const { onOpen, onClose: onCropperClose } = useImageCropModal();

  const { data, fetchNextPage, isLoading, isFetchingNextPage } = useUnsplashQuery({
    query: debouncedSearchTerm,
    type: UnsplashType.EVENT
  });

  const handleUpload = async (image: ImageData | null) => {
    try {
      setUploading(true);
      const file = await downloadAndCreateFile(image?.urls?.small);
      onOpen({
        img: file as File,
        for: "eventBanner",
        callback: async (f) => {
          if (file && callback) {
            onClose();
            onCropperClose();
            await callback?.(f);
          }
        }
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  const [ref, inView] = useInView({
    threshold: 0.1
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const imageList: ImageData[] =
    data?.pages?.reduce((acc, current: any) => {
      return acc.concat(current?.data);
    }, []) ||
    [].filter((image) => image) ||
    [];

  return (
    <div className="px-l pb-l pt-s">
      <div>
        <Input
          variant="small"
          className="w-full"
          placeholder="Search Unsplash"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <ScrollArea
        className="h-full max-h-[50vh] sm:max-h-[50vh] flex flex-col mt-m overflow-auto"
        viewportClassName="h-full overflow-auto"
        type="always"
      >
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {imageList?.map((image) => (
            <div
              key={image.id}
              className="relative group cursor-pointer overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
            >
              <div className="relative pb-[75%]" onClick={() => handleUpload(image)}>
                <img
                  src={image?.urls?.small}
                  alt={image.description || "Landscape photo"}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
        <div ref={ref} className="h-8 w-full" />
        {isFetchingNextPage || isLoading ? (
          <div className="p-1 h-20 content-center">
            <Loader />
          </div>
        ) : null}
      </ScrollArea>
    </div>
  );
};

export default Unsplash;
