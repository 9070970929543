"use client";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import * as yup from "yup";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Setting2 } from "iconsax-react";
import { TextareaAutosize } from "@/components/ui/textarea-autosize";
import { useFormik } from "formik";
import { toast } from "sonner";
import { Tabs } from "@/components/ui/tabs";
import { TabsList, TabsTrigger } from "@/components/ui/tabs-variant1.";
import clientApi from "@/api-client/client";
import Label from "@/components/ui/label";
import { ApiResponse } from "@/types/api";
import { X } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";

const formSchema = yup.object({
  prompt: yup.string().required("Prompt is required.")
});

const CONTENT_STYLE_TYPES = [{ label: "Playful" }, { label: "Casual" }, { label: "Formal" }];

const CONTENT_LENGTH_TYPES = [{ label: "Short" }, { label: "Medium" }, { label: "Long" }];

export const GenerateWithAiModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { callback } = data;
  const [showAdvanced, setShowAdvanced] = useState(false);

  const isModalOpen = isOpen && type === "generateWithAi";

  const formik = useFormik({
    initialValues: {
      prompt: "",
      contentStyle: "Playful",
      contentLength: "Short",
      description: ""
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting, setFieldValue }) => {
      try {
        const { data } = await clientApi
          .post(`api/v1/servers/generate-description-by-ai`, {
            json: {
              community_prompt: values.prompt,
              content_length: values.contentLength,
              content_style: values.contentStyle
            }
          })
          .json<ApiResponse<{ generated_output: string }>>();

        setFieldValue("description", data?.generated_output?.trim());
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const acceptDescription = () => {
    formik.resetForm();
    callback?.(formik.values);
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-full-50px w-[550px] overflow-hidden p-0 bg-none border-none backdrop-blur-none shadow-none"
        style={{
          height: "calc(100dvh - 90px)",
          background: "transparent"
        }}
        isShowCloseBtn={false}
      >
        <div className="w-full max-h-full flex flex-col overflow-hidden">
          <div className="gap-4 rounded-medium border-2 border-border bg-modal overflow-hidden py-4 shadow-lg backdrop-blur-20 flex flex-col my-auto">
            <div className="flex-shrink-0 px-4">
              <DialogHeader className="flex-shrink-0">
                <div className="flex gap-2">
                  <DialogTitle>Write with AI</DialogTitle>
                  <DialogClose className="ms-auto">
                    <X className="h-4 w-4" />
                  </DialogClose>
                </div>
              </DialogHeader>
            </div>
            <div className="flex-shrink-1 overflow-hidden">
              <ScrollArea className="h-full px-4">
                <div className="w-full flex-shrink-1 flex flex-col gap-3">
                  <TextareaAutosize
                    placeholder="Write a detailed prompt for our AI to help it generate description that best matches with your intent."
                    value={formik.values.prompt}
                    name="prompt"
                    onChange={formik.handleChange}
                    minRows={2}
                  />
                  {formik.values.description ? (
                    <TextareaAutosize
                      value={formik.values.description}
                      name="description"
                      onChange={formik.handleChange}
                    />
                  ) : null}

                  {showAdvanced ? (
                    <div>
                      <Label className="mx-l mb-s font-semibold">Style</Label>
                      <Tabs
                        color="#0e297c"
                        value={formik.values.contentStyle}
                        onValueChange={(value) => formik.setFieldValue("contentStyle", value)}
                      >
                        <TabsList>
                          {CONTENT_STYLE_TYPES.map((data, index) => (
                            <TabsTrigger value={data.label} key={index}>
                              {data.label}
                            </TabsTrigger>
                          ))}
                        </TabsList>
                      </Tabs>

                      <Label className="mx-l mb-s font-semibold mt-2 block">Length</Label>
                      <Tabs
                        color="#0e297c"
                        value={formik.values.contentLength}
                        onValueChange={(value) => formik.setFieldValue("contentLength", value)}
                      >
                        <TabsList>
                          {CONTENT_LENGTH_TYPES.map((data, index) => (
                            <TabsTrigger value={data.label} key={index}>
                              {data.label}
                            </TabsTrigger>
                          ))}
                        </TabsList>
                      </Tabs>
                    </div>
                  ) : null}
                </div>
              </ScrollArea>
            </div>
            <div className="flex-shrink-0 px-4">
              <div className="flex items-center justify-center gap-2 flex-shrink-0 relative">
                <Button
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  loaderProps={{ size: "xs" }}
                  onClick={() => formik.handleSubmit()}
                  variant="secondary"
                  type="button"
                >
                  {formik.values.description ? "Retry" : "Generate"}
                </Button>
                {formik.values.description ? (
                  <Button disabled={formik.isSubmitting} onClick={acceptDescription} type="button">
                    Accept
                  </Button>
                ) : null}

                <Button
                  variant="unstyled"
                  type="button"
                  className="absolute right-0 bottom-0"
                  onClick={() => setShowAdvanced((v) => !v)}
                >
                  <Setting2 variant="Bold" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
