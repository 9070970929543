import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse, ApiResponsePagination } from "@/types/api";
import { Member } from "@/types/entities";
import compact from "lodash/compact";
import { getQueryKey } from "@key.ai/constants";
import { cleanObject } from "@/lib/helper";

interface MemberQueryProps {
  search?: string;
  channelId?: string;
  conversationId?: string;
  serverId: string;
  informationOf?: string;
}

interface MemberInterface extends Member {
  inConversation?: boolean;
}

export const useMembersQuery = ({
  search,
  channelId,
  conversationId,
  serverId,
  informationOf
}: MemberQueryProps) => {
  const fetchMembers = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data } = await clientApi
      .get(`api/v1/${serverId}/members`, {
        searchParams: cleanObject({
          cursor: pageParam as string,
          search: search?.replace("@", "") ?? search,
          channelId,
          conversationId,
          informationOf: informationOf
        })
      })
      .json<ApiResponse<ApiResponsePagination<MemberInterface[]>>>();

    return data;
  };

  const queryKey = getQueryKey("SERVER_MEMBERS", { serverId });

  return useInfiniteQuery<ApiResponsePagination<MemberInterface[]>>({
    queryKey: compact([queryKey, search, channelId, conversationId, informationOf]),
    queryFn: fetchMembers,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    placeholderData: keepPreviousData
  });
};
