"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useParams } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import clientApi from "@/api-client/client";
import { useFormik } from "formik";
import { getQueryKey } from "@key.ai/constants";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

const formSchema = yup.object({
  name: yup
    .string()
    .required("Category name is required.")
    .max(50, "Category name cannot exceed 50 characters.")
    .test(
      "is-not-only-spaces",
      "Category name cannot be only spaces.",
      (value) => value.trim().length > 0
    )
});

export const CreateChannelCategoryModal = () => {
  const { isOpen, onClose, type } = useModal();
  const queryClient = useQueryClient();
  const params = useParams<{ domain: string }>();

  const isModalOpen = isOpen && type === "createChannelCategory";

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await clientApi
          .post(`api/v1/${params.domain}/categories`, {
            json: values
          })
          .json();

        resetForm();
        const queryKey = getQueryKey("CATEGORIES", { serverId: params.domain });
        queryClient.refetchQueries({ queryKey: [queryKey] });
        onClose();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4">
        <DialogHeader>
          <DialogTitle>Create Category</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <Input
            placeholder="Category name"
            error={(formik.touched.name && formik.errors.name) as boolean}
            errorMessage={formik.errors.name}
            errorOutline={(formik.touched.name && formik.errors.name) as boolean}
            label="Name"
            variant="medium"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
          />

          <Button
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            type="submit"
            loaderProps={{ size: "xs" }}
          >
            Create
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
